import ReelsBg from '../assets/reelsContainer.png';
import slotBackground from '../assets/game-field/bg.png';
import slotBackgroundFS from '../assets/game-field/bgFS.png';
import Empty from '../assets/game-field/empty.png';
import betBackground from '../assets/bottom-bar/betBG.svg';
import infoBackground from '../assets/bottom-bar/infoBG.svg';
import leftBonus from '../assets/modal/left-bonus.png';
import rightBonus from '../assets/modal/right-bonus.png';
import confirmBuyBonusDefault from '../assets/modal/confirm-buy-button-modal.png';
import confirmBuyBonusHover from '../assets/modal/confirm-buy-button-hover-modal.png';
import confirmBuyBonusDisabled from '../assets/modal/confirm-buy-button-disabled-modal.png';
import confirmBuyBonusDefaultPortrait from '../assets/modal/confirm-buy-button-modal-portrait.png';
import confirmBuyBonusHoverPortrait from '../assets/modal/confirm-buy-button-hover-modal-portrait.png';
import confirmBuyBonusDisabledPortrait from '../assets/modal/confirm-buy-button-disabled-modal-portrait.png';
import buyBonusBackground from '../assets/buttons/btn-bonus-background.svg';
import infoBarMobileBG from '../assets/bottom-bar/BottomBarBG.png';
import reelsBorder from '../assets/reelsBorder.png';

import { Application, Loader } from 'pixi.js';
import { modalLoader } from './modalLoader';
import { spineLoaders } from './spineLoader';
import { buttonsLoader } from './buttonsLoader';
import { multipliersLoader } from './numbersLoader';
import ReelMaskImg from '../assets/reelsMask.png';
import WildMask from '../assets/wildMask.png';

export const AssetsLoader = async (
	app: Application,
	onLoaded: Loader.OnCompleteSignal
) => {
	modalLoader(app);
	spineLoaders(app);
	buttonsLoader(app);
	multipliersLoader(app);

	app.loader
		.add('reelsContainer', ReelsBg)
		.add('reelsMask', ReelMaskImg)
		.add('wildMask', WildMask)
		.add('slotBackground', slotBackground)
		.add('slotBackgroundFS', slotBackgroundFS)
		.add('Empty', Empty)
		.add('infoBackground', infoBackground)
		.add('betBackground', betBackground)
		.add('buyBonusBackground', buyBonusBackground)
		.add('leftBonus', leftBonus)
		.add('rightBonus', rightBonus)
		.add('confirmBuyBonusDefault', confirmBuyBonusDefault)
		.add('confirmBuyBonusHover', confirmBuyBonusHover)
		.add('confirmBuyBonusDisabled', confirmBuyBonusDisabled)
		.add('confirmBuyBonusDefaultPortrait', confirmBuyBonusDefaultPortrait)
		.add('confirmBuyBonusHoverPortrait', confirmBuyBonusHoverPortrait)
		.add('confirmBuyBonusDisabledPortrait', confirmBuyBonusDisabledPortrait)
		.add('infoBarMobileBG', infoBarMobileBG)
		.add('reelsBorder', reelsBorder)

	app.loader.onComplete.add(onLoaded);

    await app.loader.load();
};
