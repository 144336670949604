import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { api } from '../../../../App';
import {
	doubleBonusLabelStyles,
	doubleBonusLabelHoverStyles,
} from '../../../../styles/pixiStyles';
import { PixiGame } from '../../index';

class DoubleBonusChanceButton {
	app: Application;
	game: PixiGame;
	button: PIXI.Container;
	buttonBackground: PIXI.Sprite;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	buttonDefault: PIXI.Sprite;
	buttonHover: PIXI.Sprite;
	buttonDisabled: PIXI.Sprite;
	buttonLabel: PIXI.Text;
	buttonLabelHover: PIXI.Text;
  isDBCActive?: boolean;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.buttonBackground = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBackground'].texture!
		);
		this.buttonLabel = new PIXI.Text(
			'DOUBLE\nBONUS\nCHANCE',
			doubleBonusLabelStyles
		);
		this.buttonLabelHover = new PIXI.Text(
			'DOUBLE\nBONUS\nCHANCE',
			doubleBonusLabelHoverStyles
		);
		this.buttonDefault = new PIXI.Sprite(
			this.app.loader.resources['buyBonusButton'].texture!
		);
		this.buttonHover = new PIXI.Sprite(
			this.app.loader.resources['buyBonusButtonHover'].texture!
		);
		this.buttonDisabled = new PIXI.Sprite(
			this.app.loader.resources['buyBonusButtonHover'].texture!
		);
		this.button = new PIXI.Container();
	}

	initialize = () => {
		this.isDBCActive = false;
		this.button.x = 112 + 100;
		this.button.y = 368 + 320;
		this.button.width = 220;
		this.button.height = 220;

		this.buttonBackground.x = 0;
		this.buttonBackground.y = 0;
		this.buttonBackground.width = 220;
		this.buttonBackground.height = 220;

		this.button.interactive = true;
		this.button.buttonMode = true;

		this.buttonHover.alpha = 0;
		this.buttonDisabled.alpha = 0;

		this.buttonLabel.anchor.set(0.5);
		this.buttonLabelHover.anchor.set(0.5);
		this.buttonBackground.anchor.set(0.5);
		this.buttonDefault.anchor.set(0.5);
		this.buttonHover.anchor.set(0.5);
		this.buttonDisabled.anchor.set(0.5);

		this.button.addChild(this.buttonBackground!);
		this.buttonBackground.addChild(this.buttonDefault!);
		this.buttonBackground.addChild(this.buttonHover!);
		this.buttonBackground.addChild(this.buttonDisabled!);
		this.buttonDefault.addChild(this.buttonLabel!);
		this.buttonHover.addChild(this.buttonLabelHover!);

		this.button
			.on('pointerup', e => this.leftClickPointerUp(e))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchend', () => this.doTouchPointerUp(this.app))
			.on('click', e => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));

		this.game.gameContainer.addChild(this.button);
	};

	leftClick = (e: any) => {
		if (e.data.button === 0) {
			this.isDBCActive = !this.isDBCActive
			this.doPointerDown(this.app);
		}
	};

	leftClickPointerUp = (e: any) => {
		if (e.data.button === 0) {
			this.doPointerUp(this.app);
		}
	};

	doTouchPointerUp = async (app: Application) => {
		let wiggleTween = gsap.timeline();
		this.isDBCActive = !this.isDBCActive;
			if(this.isDBCActive === true){
				this.game.type = 'doubleBonusGame';
				this.game.slotMachine?.changeBetValue((this.game.slotMachine!.betValue * 1.25));
				this.game.assetsController?.betInput?.changeValue(this.game.slotMachine!.betValue);
				wiggleTween.to(
				this.buttonHover,
				1,
				{ alpha: 1 }
			);
			this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(false);
			// this.game.assetsController!.buyBonusButton!.doButtonDisabled(app);
			this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
			} else {
				this.game.type = 'default';
				this.game.slotMachine?.changeBetValue((this.game.slotMachine!.betValue / 1.25));
				this.game.assetsController?.betInput?.changeValue(this.game.slotMachine!.betValue);
				wiggleTween.to(
				this.buttonHover,
				1,
				{ alpha: 0 }
			);
			this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(true);
			// this.game.assetsController!.buyBonusButton!.doButtonEnabled(app);
			this.game.assetsController!.buyBonusButton!.button.alpha = 1;
			}
		this.game.assetsController?.buyBonusModal?.changeMaxCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyBonusModal?.changeMinCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyMinBonusModal?.changeMinCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(this.game.slotMachine!.betValue)
	}

	changeButtonState(state: boolean){
		this.button.interactive = state;
		this.button.buttonMode = state;
		this.button.alpha= state ? 1 : 0.5;
	}

	doPointerUp = async (app: Application) => {
		if (this.pointerIsOver) {
			let wiggleTween = gsap.timeline();
			if(this.isDBCActive === true){
				this.game.type = 'doubleBonusGame';
				this.game.slotMachine?.changeBetValue((this.game.slotMachine!.betValue * 1.25));
				this.game.assetsController?.betInput?.changeValue(this.game.slotMachine!.betValue);
				wiggleTween.to(
				this.buttonHover,
				1,
				{ alpha: 1 }
			);
			this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(false);
			this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
			} else {
				this.game.type = 'default';
				this.game.slotMachine?.changeBetValue((this.game.slotMachine!.betValue / 1.25));
				this.game.assetsController?.betInput?.changeValue(this.game.slotMachine!.betValue);
				wiggleTween.to(
				this.buttonHover,
				1,
				{ alpha: 0 }
			);
			this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(true);
			this.game.assetsController!.buyBonusButton!.button.alpha = 1;
			}
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		}
		this.game.assetsController?.buyBonusModal?.changeMaxCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyBonusModal?.changeMinCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyMinBonusModal?.changeMinCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(this.game.slotMachine!.betValue)
		this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(this.game.slotMachine!.betValue)
		this.pointerIsDown = false;
	}

	doPointerUpOutside(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		let wiggleTween = gsap.timeline();
		this.game.soundController.btn_ui?.play()
		wiggleTween.fromTo(
			this.buttonHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = true;
	}

	doPointerOver(app: Application) {
	
		this.game.soundController.bonus_hover?.play()
		let wiggleTween = gsap.timeline();
		if(this.isDBCActive === false){
		wiggleTween.fromTo(
			this.buttonHover,
			0.3,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		} else {
			wiggleTween.fromTo(
				this.buttonHover,
				0.3,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		};
		this.pointerIsOver = true;
	
	}

	doPointerOut(app: Application) {
		let wiggleTween = gsap.timeline();
		if(this.isDBCActive === false){
			wiggleTween.fromTo(
				this.buttonHover,
				0.3,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		} else {
			wiggleTween.fromTo(
				this.buttonHover,
				0.3,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
		}
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}

	doDefaultVisible(app: Application) {
		if(this.isDBCActive === false){
			let wiggleTween = gsap.timeline();
			wiggleTween.to(
				this.buttonHover,
				0,
				{ alpha: 0 },
			);
			wiggleTween.to(
				this.buttonDefault,
				0,
				{ alpha: 1 },
			);
		}
	}
}

export default DoubleBonusChanceButton;
