import * as PIXI from 'pixi.js';
import { Application, Loader } from 'pixi.js';

import {AssetsLoader} from './assetLoader/AssetsLoader';
import {AssetsController} from './classes/AssetsController';
import {SlotMachine} from './classes/SlotMachine';
import {NewGameResponse} from '../../api/types';
import {api} from '../../App';
import {SoundController} from "./classes/SoundController";
import {AdaptiveComponent} from './classes/Adaptive';
import {InitialData} from "../../helpers/InitialData";
import { EventType, getEvent } from '../../GameEventBus';
import TokenStorage from "../../helpers/TokenStorage";
import { UI } from '../../ui';
import { LocalStorage } from '../../utils/localStorage';
import { store } from '../../store';
import { AuthActionCreators } from '../../store/reducers/auth/action-creator';
import { IInfoBar } from '../../api/types/auth';
import {Balance} from "./classes/Balance";
import soundControl from './ui-handlers/sound-control';
import featureConfig from './ui-handlers/feature-config';

export let ratio = window.innerWidth / window.innerHeight;
let ratio1 = 1920 / 1080;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR

export interface GameInitTypes {
    ref: any
    setAuth: any
    setInfoBar: any
    setTotalBet: any
}

export class PixiGame {
    isAssetsLoaded = false;
    app: Application;
    gameContainer: PIXI.Container;
    ref: any;
    isInitialized: boolean;
    assetsController?: AssetsController;
    adaptive?: AdaptiveComponent;
    slotMachine?: SlotMachine;
    initialData?: InitialData;
    gameInfo?: NewGameResponse;
    soundController: SoundController;
    balance: Balance
    type?: 'default' | 'debug' | 'bonusGame' | 'doubleBonusGame';

    constructor(type: 'default' | 'debug',) {
        this.isAssetsLoaded = false;
        this.app = new PIXI.Application({
            width: 1920,
            height: 1080,
            backgroundColor: 0x00,
            // antialias: true,
            resolution: window.innerWidth < 900 ? window.devicePixelRatio : 1,
            resizeTo: document.body
        });
        this.gameContainer = new PIXI.Container();
        this.ref = null;
        this.isInitialized = false;
        this.assetsController = undefined;
        this.adaptive = new AdaptiveComponent(this, this.app);
        this.slotMachine = undefined;
        this.soundController = new SoundController(this)
        this.initialData = undefined;
        this.type = type;
        this.balance = new Balance(this)
    }

    initialize = async ({setAuth, ref, setInfoBar, setTotalBet}: GameInitTypes) => {
        const search = window.location.search
        const queryParams = new URLSearchParams(search)
        const token = queryParams.get('token')
        const backUrl = queryParams.get('backUrl')
        const language = queryParams.get('lang')

        console.log(`params`, token, backUrl, language)

        if(!token) {
            console.log(`TOKEN NOT FOUND`)
            return
        }

        TokenStorage.setToken(token)

        const gameInfo = await api.auth.newGame(token)

        const body = {
            spinResult: 0,
            betValue: 1,
            balanceValue: gameInfo.response.account.value,
            totalBetValue: 0,
        };

        setInfoBar(body);
        const betArray = gameInfo.gameSettings.find((setting) => setting.name === 'TOTAL_BET')!.value || [1, 2, 3, 4, 5]
        setTotalBet(betArray);
        setAuth(true);
        this.balance.changeBalanceValue(body.balanceValue);
        this.balance.setTotalBetList(betArray);

        UI.config.setBetSteps(betArray, betArray[0])
        UI.config.setBalance(gameInfo.response.account.value)
        UI.config.setWin(0)
        UI.config.setCurrency(gameInfo.response.account.precision)
        UI.config.setLocale(language || 'en' as any)
        featureConfig(gameInfo);

        this.app.stage.sortableChildren = true
        if (this.isInitialized) return;
        this.ref = ref;

        this.isInitialized = true;

        this.gameContainer.width = 1920;
        this.gameContainer.height = 1080;
        this.gameContainer.sortableChildren = true;

        this.gameInfo = gameInfo.response;

        this.initialData = new InitialData();
        this.assetsController = new AssetsController(this, this.app);
        this.slotMachine = new SlotMachine(this);
        await this.soundController.initialize()

        await AssetsLoader(this.app, this.onAssetsLoaded);
        ref.current.appendChild(this.app.view);

        this.app.start();
    };

    onAssetsLoaded: Loader.OnCompleteSignal = async () => {
        if (this.isAssetsLoaded) return;
        await this.initializeScene()
        this.initEvents();

        this.isAssetsLoaded = true;
            getEvent(EventType.GAME_ASSETS_LOADED).send(true)
    };

    initializeScene = async () => {
        await this?.assetsController?.setupAllComponents();
        this.app.stage.addChild(this.gameContainer);

        function resize(game: PixiGame) {
            if (window.innerWidth / window.innerHeight >= ratio) {
                var w = window.innerHeight * ratio1;
                var h = window.innerHeight;
            } else {
                var w = window.innerWidth;
                var h = window.innerWidth / ratio1;
            }

            game.app.view.width = window.innerWidth;
            game.app.view.height = window.innerHeight;

            game.gameContainer.scale.x = window.innerWidth / 1920;
            game.gameContainer.scale.y = window.innerWidth / 1920;

            if (window.innerHeight < 1080 * game.gameContainer.scale.y) {
                game.gameContainer.scale.x = window.innerHeight / 1080;
                game.gameContainer.scale.y = window.innerHeight / 1080;
                game.gameContainer.x =
                    (window.innerWidth - game.gameContainer.width) / 2;
            }

            game.assetsController?.changeVisibility(
                game.assetsController.infoBarMobile,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController?.multiplierBarMobile,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController.settingsButton?.button,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController.betButton?.button,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController.infoBar,
                false
            );
            game.assetsController?.changeVisibility(
                game.assetsController.infoBarMobilePortret,
                false
            );

            game.app.view.style.width = window.innerWidth + 'px';
            game.app.view.style.height = window.innerHeight + 'px';

            game?.assetsController!.changeBackgroundFSSize();
            game?.assetsController!.changeBackgroundSize();

            if (!game.assetsController) return;
            let adaptiveRatio = window.innerWidth / window.innerHeight;
            game.adaptive?.resizeModal();
            UI.config.setPlatform("desktop");
            if (adaptiveRatio < 1) {
                UI.config.setPlatform("mobile");
                game.adaptive?.resizeToPortrait()
            } else if (adaptiveRatio > 1.78 && window.innerWidth >= 1280) {
                game.adaptive?.resizeToDesktop1();
            } else if (adaptiveRatio < 1.78 && window.innerWidth < 1280) {
                game.adaptive?.resizeToLandscapeMobile();
            } else if (adaptiveRatio > 1.78 && window.innerWidth < 1280) {
                game.adaptive?.resizeToLandscapeMobile();
            } else {
                game.adaptive?.resizeToDesktop2();
            }

            game.adaptive?.fixGameContainer();
            game.app.resize()
            return
        }



        resize(this);
        this.app.resize()

        window.addEventListener('resize', () => resize(this));

        window.addEventListener('orientationchange',() => resize(this));

        await this.slotMachine?.initialize();
        await this.assetsController?.winLine?.init()
        await UI.register("Square Gummy Bear");

    };

    initEvents = () => {
        const isSoundOn = LocalStorage.getItem("WrapperSoundsSettings")
        if (!isSoundOn) {
            this.soundController.backgroundSound?.mute(true);
            this.soundController.backgroundFSSound?.mute(true);
        }

        UI.events.subscribe("click:spin", () => {
            this.slotMachine?.onSpin({});
            this.soundController.btn_ui?.play();
        });

        UI.events.subscribe("click:auto", () => {
            UI.events.show("auto");
            this.soundController.btn_ui?.play();
        });

        UI.events.subscribe("change:auto", (args: any) => {});
        
        UI.events.subscribe("click:auto-spin", (args: any) => {
            this.assetsController?.autoPlayModal?.startAutoPlay(args);
        });

        UI.events.subscribe("change:turbo", (turbo: any) => {
            LocalStorage.setItem('isTurboEnabled', turbo.enabled);
            this.soundController.btn_ui?.play();
        });

        UI.events.subscribe("change:bet", (bet: any) => {
            this.balance.changeBetValue(bet.value);
            this.soundController.btn_ui?.play();

            this.assetsController?.buyBonusModal?.changeMinCost();
            this.assetsController?.buyBonusModal?.changeMaxCost();
            this.assetsController?.buyMinBonusModal?.changeMinCost();
            this.assetsController?.buyMaxBonusModal?.changeMaxCost();
        });

        UI.events.subscribe("click:settings", () => {
            UI.events.show("settings")
        });

        // TODO: need more backend data
        if (this.gameInfo?.gamePromotion) {
            UI.events.show("free-spins", {
                offers: [{
                    type: 'float',
                    cost: this.gameInfo?.gamePromotion.settings.free_spins_settings.free_spin_details[0].spin_cost,
                    amount: 10,
                    options: [this.gameInfo?.gamePromotion.settings.free_spins_settings.free_spin_details[0].rounds_available],
                    expired: Number(this.gameInfo?.gamePromotion.valid_until),
                    offerID: this.gameInfo?.gamePromotion.settings.free_spins_settings.promo_id,
                }]
            })
        }

        UI.events.subscribe("change:music", (soundsSettings: any) => {
            soundControl(soundsSettings, this.soundController);
            this.soundController.btn_ui?.play();
        });
        UI.events.subscribe("change:sound", (soundsSettings: any) => {
            soundControl(soundsSettings, this.soundController);
        });

        UI.events.subscribe("change:left-hand-mode", (isHandedMode: any) => {
            this.adaptive?.enableLeftHandMode(isHandedMode.enabled)
        });

        UI.events.subscribe("click:popup-exit-btn", () => {
            store.dispatch(AuthActionCreators.setInfoBar({} as IInfoBar));
            TokenStorage.removeToken();
            store.dispatch(AuthActionCreators.setAuth(false));
            window.location.replace(window.location.href.split('?')[0]);
        });
    }
}
