import {PixiGame} from "../index";


export class Balance {
  game: PixiGame;
  win: number = 0;
  balanceValue: number = 0;
  betIndex: number = 0;
  totalBetList: number[] = [];
  betValue: number = 1; 

  constructor(game: PixiGame) {
    this.game = game;
  }

  setWin(value: number) {
    this.win = value;
  }

  changeBalanceValue(value: number) {
    this.balanceValue = value;
  }

  changeBetValue(value: number) {
    const index = this.totalBetList.indexOf(value);

    if (index === -1) return;

    this.betIndex = index;
    this.betValue = value;
  }

  setTotalBetList(list: number[]) {
    this.totalBetList = list;
		this.betIndex = 0;
    this.betValue = this.totalBetList[this.betIndex];
		this.game.initialData?.setBetValueIndex(this.betIndex);
		this.game.initialData?.setBetValue(this.betValue);
		this.game.slotMachine?.changeBetValue(this.betValue);
	}
}
