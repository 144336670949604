import * as PIXI from "pixi.js";
import { Application } from "pixi.js";
import { EventType, getEvent } from "../../../../GameEventBus";
import {
  autoPlayModalTitle,
  switchButtonLabel,
  totalBetValueLabel,
} from "../../../../styles/pixiStyles";
import { PixiGame } from "../../index";
import CommonButton from "./CommonButton";

class BetModal {
  app: Application;
  game: PixiGame;
  buttons?: any[];
  modal: PIXI.Container;
  background: PIXI.Graphics;
  secondaryBackground: PIXI.Sprite;
  betDisplay: PIXI.Sprite;
  betValue: PIXI.Text;
  title: PIXI.Text;
  subTitle: PIXI.Text;
  minBetLabel: PIXI.Text;
  maxBetLabel: PIXI.Text;
  closeButton: PIXI.Sprite;
  numberOfBet075?: CommonButton;
  numberOfBet050?: CommonButton;
  numberOfBet030?: CommonButton;
  numberOfBet020?: CommonButton;
  numberOfBet010?: CommonButton;

  constructor(game: PixiGame, app: Application) {
    this.game = game;
    this.app = app;
    this.buttons = [];
    this.modal = new PIXI.Container();
    this.background = new PIXI.Graphics();
    this.secondaryBackground = new PIXI.Sprite(
      this.app.loader.resources["autoPlayModalSecondaryBackground"].texture!
    );
    this.title = new PIXI.Text("TOTAL BET", autoPlayModalTitle);
    this.betValue = new PIXI.Text(
      this.game.assetsController?.betInput?.value,
      totalBetValueLabel
    );
    this.subTitle = new PIXI.Text("Choose you bet", switchButtonLabel);
    this.minBetLabel = new PIXI.Text("Min Bet: 0.05 USD", switchButtonLabel);
    this.maxBetLabel = new PIXI.Text("Max Bet: 5.00 USD", switchButtonLabel);
    this.betDisplay = new PIXI.Sprite(
      this.app.loader.resources["betModalLogo"].texture!
    );
    this.closeButton = new PIXI.Sprite(
      this.app.loader.resources["autoPlayCloseButton"].texture!
    );
  }

  initialize = async () => {
    await this.setupButtons();
    this.modal.zIndex = 1000;
    this.modal.x = 150;
    this.modal.y = 50;
    this.modal.width = 304;
    this.modal.height = 266;
    this.modal.visible = false;

    this.background.beginFill(0x151515);
    this.background.drawRoundedRect(0, 0, 304, 266, 15);
    this.background.endFill();

    this.betDisplay.x = 304 - this.betDisplay.width - 9;
    this.betDisplay.y = 9.5;
    this.betDisplay.width = 69;
    this.betDisplay.height = 32;

    this.betValue.x = this.betDisplay.width / 2 - this.betValue.width / 2;
    this.betValue.y = 10;

    this.title.x = this.closeButton.x + this.closeButton.width + 49;
    this.title.y = 8;

    this.subTitle.x = this.closeButton.x + this.closeButton.width + 57;
    this.subTitle.y = 32;

    this.minBetLabel.x = 6;
    this.minBetLabel.y = 9.5;

    this.maxBetLabel.x = 287 - this.maxBetLabel.width - 6;
    this.maxBetLabel.y = 9.5;

    this.closeButton.x = 9.5;
    this.closeButton.y = 12;
    this.closeButton.width = 33;
    this.closeButton.height = 27;
    this.closeButton.interactive = true;
    this.closeButton.buttonMode = true;

    this.secondaryBackground.x = 8;
    this.secondaryBackground.y = 51;
    this.secondaryBackground.width = 287;
    this.secondaryBackground.height = 30;

    this.game.gameContainer.addChild(this.modal!);
    this.modal.addChild(this.background!);
    this.background.addChild(this.betDisplay!);
    this.betDisplay.addChild(this.betValue!);
    this.background.addChild(this.title!);
    this.background.addChild(this.subTitle!);
    this.background.addChild(this.closeButton!);
    this.secondaryBackground.addChild(this.minBetLabel!);
    this.secondaryBackground.addChild(this.maxBetLabel!);
    this.background.addChild(this.secondaryBackground!);

    this.closeButton.on("touchstart", () => this.showModal(false));
    this.closeButton.on("click", (e) => this.leftClickShowModal(e));
  };

  leftClickShowModal = (e:any) => {
    if(e.data.button === 0){
      this.showModal(false)
    };
  }

  changeBetValue = (value: number) => {
    this.betValue.text = `${value.toFixed(2)} USD`;
    this.game.assetsController?.betInput?.changeValue(value);
    this.game.assetsController?.betInputPortret?.changeValue(value);
    this.game.assetsController?.betInputLandscape?.changeValueLandscape(value);
    getEvent(EventType.GAME_BET_VALUE).send(value);
  };

  showModal = (isActive: boolean) => {
    if (isActive) {
      this.modal.visible = true;
    } else {
      this.modal.visible = false;
      this.game.assetsController?.betButton?.closeModal(false);
    }
  };

  setupButtons = async () => {
    this.numberOfBet075 = new CommonButton(this.game, this.app, "0.75 USD");
    this.numberOfBet050 = new CommonButton(this.game, this.app, "0.50 USD");
    this.numberOfBet030 = new CommonButton(this.game, this.app, "0.30 USD");
    this.numberOfBet020 = new CommonButton(this.game, this.app, "0.20 USD");
    this.numberOfBet010 = new CommonButton(this.game, this.app, "0.10 USD");

    this.numberOfBet075.button.on("touchstart", () =>
      this.changeBetValue(0.75)
    );
    this.numberOfBet050.button.on("touchstart", () => this.changeBetValue(0.5));
    this.numberOfBet030.button.on("touchstart", () => this.changeBetValue(0.3));
    this.numberOfBet020.button.on("touchstart", () => this.changeBetValue(0.2));
    this.numberOfBet010.button.on("touchstart", () => this.changeBetValue(0.1));

    this.numberOfBet075.button.on("click", (e) => this.leftClick(e, 0.75));
    this.numberOfBet050.button.on("click", (e) => this.leftClick(e, 0.5));
    this.numberOfBet030.button.on("click", (e) => this.leftClick(e, 0.3));
    this.numberOfBet020.button.on("click", (e) => this.leftClick(e, 0.2));
    this.numberOfBet010.button.on("click", (e) => this.leftClick(e, 0.1));

    await this.numberOfBet075.initialize();
    await this.numberOfBet050.initialize();
    await this.numberOfBet030.initialize();
    await this.numberOfBet020.initialize();
    await this.numberOfBet010.initialize();

    this.buttons?.push(this.numberOfBet075.button);
    this.buttons?.push(this.numberOfBet050.button);
    this.buttons?.push(this.numberOfBet030.button);
    this.buttons?.push(this.numberOfBet020.button);
    this.buttons?.push(this.numberOfBet010.button);

    this.numberOfBet075.button.x = 101.5;
    this.numberOfBet075.button.y = 89;
    this.numberOfBet050.button.x = 101.5;
    this.numberOfBet050.button.y = 124;
    this.numberOfBet030.button.x = 101.5;
    this.numberOfBet030.button.y = 159;
    this.numberOfBet020.button.x = 101.5;
    this.numberOfBet020.button.y = 194;
    this.numberOfBet010.button.x = 101.5;
    this.numberOfBet010.button.y = 229;

    this.buttons?.forEach((btn) => this.background.addChild(btn));
  };

  leftClick = (e:any, val:number) => {
    if(e.data.button === 0){
      this.changeBetValue(val)
    };
  }

  changeBetModalScale(scalingX: number, scalingY: number) {
    if (!this.modal) return;
    this.modal.scale.x = scalingX;
    this.modal.scale.y = scalingY;
  }

  changeBetModalPosition(x: number, y: number) {
    if (!this.modal) return;
    this.modal.x = x;
    this.modal.y = y;
  }

  changeBetModalSize(x: number, y: number) {
    if (!this.modal) return;
    this.modal.width = x;
    this.modal.height = y;
  }
}

export default BetModal;
