import { IUser, IInfoBar } from '../../../api/types/auth';
import { AuthAction, AuthActionEnum, AuthState } from './types';

const initialState: AuthState = {
	isAuth: false,
	bet: 0,
	user: {} as IUser,
	info: {} as IInfoBar,
	totalBet: [] as number[],
	isLoading: true,
	error: '',
};

export default function authReducer(
	state = initialState,
	action: AuthAction
): AuthState {
	switch (action.type) {
		case AuthActionEnum.SET_AUTH:
			return { ...state, isAuth: action.payload, isLoading: false };
		case AuthActionEnum.SET_BET:
			return { ...state, bet: action.payload };
		case AuthActionEnum.SET_USER:
			return { ...state, user: action.payload };
		case AuthActionEnum.SET_INFO_BAR:
			return { ...state, info: action.payload };
		case AuthActionEnum.SET_TOTAL_BET:
			return { ...state, totalBet: action.payload };
		case AuthActionEnum.SET_IS_LOADING:
			return { ...state, isLoading: action.payload };
		case AuthActionEnum.SET_ERROR:
			return { ...state, error: action.payload, isLoading: false };
		default:
			return state;
	}
}
