import {FC, useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import {useActions} from '../../hooks/useActions';
import {Group, Popover} from '@mantine/core';
import Settings from '../Popovers/Settings/Settings';
import {respondFrom} from '../../styles/mixins';
import {Howl, Howler} from 'howler';
import MenuModal, {MenuList} from '../Modals/MenuModal/MenuModal';
import {PixiGame} from '../../features/Game';

import iconExit from '../../assets/img/header/icon-exit.svg';
import iconRules from '../../assets/img/header/icon-rules.svg';
import iconSettings from '../../assets/img/header/icon-settings.svg';
import iconSoundOn from '../../assets/img/header/icon-sound-on.svg';
import iconSoundOff from '../../assets/img/header/icon-sound-off.svg';
import iconExitHover from '../../assets/img/header/icon-exit-hover.svg';
import iconRulesHover from '../../assets/img/header/icon-rules-hover.svg';
import iconSettingsHover from '../../assets/img/header/icon-settings-hover.svg';
import iconSoundOnHover from '../../assets/img/header/icon-sound-on-hover.svg';
import iconSoundOffHover from '../../assets/img/header/icon-sound-off-hover.svg';
import {EventType, useEventReceiver} from "../../GameEventBus";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import closeIcon from '../../assets/img/header/header-btn-close.svg';

export interface INav {
    game: PixiGame;
}

const params = new URLSearchParams(window.location.search)
const backUrl: string = params.get('backUrl') || ''

const NavBar: FC<INav> = ({game}) => {
    const {logout} = useActions();
    const [activeModal, setActiveModal] = useState(MenuList.Paytable);
    const [isSoundOn, setIsSoundOn] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clickUiSound, setClickUiSound] = useState<Howl | undefined>()
    const {setSettingModalIsOpen} = useActions();
    const {settingModalIsOpened} = useTypedSelector(state => state.settingModalReducer);


    useEffect(() => {
        if (window.localStorage.getItem('IsSoundOn') === null) {
            window.localStorage.setItem('IsSoundOn', 'true')
            setIsSoundOn(true)
        } else {
            setIsSoundOn(window.localStorage.getItem('IsSoundOn') === 'true')
        }
    }, [window.localStorage.getItem('IsSoundOn')]);

    useEventReceiver<Howl>(EventType.GAME_BTN_UI_SOUND, value => {
        if (value) {
            setClickUiSound(value)
        }
    });

    useEffect(() => {
        if (isSoundOn) {
            Howler.mute(false)
        } else {
            Howler.mute(true)
        }
    }, [isSoundOn])

    const logOut = () => {
        clickUiSound?.play()
        logout();
        window.location.reload()
    };

    const changeModalState = () => {
        setIsModalOpen(!isModalOpen)
        game.assetsController?.changeMenuState(!isModalOpen)
        window.addEventListener('keydown', function (e) {
            if (e.key == " " ||
                e.code == "Space" ||
                e.keyCode == 32) {
                e.preventDefault();
                return false;
            }
        });
    }

    const showMenuModal = () => {
        changeModalState();
        setActiveModal(MenuList.Paytable);
    };

    const showPaytableModal = () => {
        changeModalState();
        setActiveModal(MenuList.Paytable);
    };

    const showRulesModal = () => {
        clickUiSound?.play()
        changeModalState();
        setActiveModal(MenuList.Rules);
    };

    game.assetsController?.settingsButton?.button.on('click', () =>
        showMenuModal()
    );
    game.assetsController?.settingsButton?.button.on('touchstart', () =>
        showMenuModal()
    );
    game.assetsController?.infoButton?.button.on('click', e =>
        leftClickInfoButton(e)
    );
    game.assetsController?.infoButton?.button.on('touchstart', () =>
        showPaytableModal()
    );

    const leftClickInfoButton = (e: any) => {
        if (e.data.button === 0) {
            showPaytableModal()
        }
        ;
    }

    const switchSound = useCallback(() => {
        if (!isSoundOn) {
            Howler.mute(false);
            setIsSoundOn(true);
            window.localStorage.setItem('IsSoundOn', 'true')
        } else {
            Howler.mute(true);
            setIsSoundOn(false);
            window.localStorage.setItem('IsSoundOn', 'false')
        }
    }, [setIsSoundOn, isSoundOn]);

    return (
        <>
            <GroupWrapper>
                <DesktopView>
                    <Popover opened={settingModalIsOpened} onChange={() => clickUiSound?.play()} width={304} trapFocus
                             position='bottom' withArrow>
                        <Popover.Target>
                            <NavButton onClick={() => setSettingModalIsOpen(!settingModalIsOpened)}>
                                <img src={settingModalIsOpened ? closeIcon : iconSettings} alt='Settings'
                                     draggable={false}/>
                                <img src={settingModalIsOpened ? closeIcon : iconSettingsHover} alt='Settings'
                                     draggable={false}/>
                            </NavButton>
                        </Popover.Target>
                        <Settings
                            clickUiSound={clickUiSound!}
                            isSoundOn={isSoundOn}
                            setIsSoundOn={setIsSoundOn}/>
                    </Popover>

                    <NavButton>
                        <img
                            src={isSoundOn ? iconSoundOn : iconSoundOff}
                            alt='Sound'
                            draggable={false}
                            onClick={switchSound}
                        />
                        <img
                            src={isSoundOn ? iconSoundOnHover : iconSoundOffHover}
                            alt='Sound'
                            draggable={false}
                            onClick={switchSound}
                        />
                    </NavButton>

                    <NavButton onClick={showRulesModal}>
                        <img src={iconRules} alt='Rules' draggable={false}/>
                        <img src={iconRulesHover} alt='Rules' draggable={false}/>
                    </NavButton>

                    {backUrl &&
                        <a href={backUrl}>
                            <NavButton>
                                <img src={iconExit} alt='Exit' draggable={false}/>
                                <img src={iconExitHover} alt='Exit' draggable={false}/>
                            </NavButton>
                        </a>
                    }
                </DesktopView>
            </GroupWrapper>

            <MenuModal
                game={game}
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                isModalOpen={isModalOpen}
                showMenuModal={showMenuModal}
                clickUiSound={clickUiSound!}
            />
        </>
    );
};

export default NavBar;

const GroupWrapper = styled(Group)``;

const DesktopView = styled(GroupWrapper)`
  display: flex;
  align-items: center;
  padding-right: 48px;
  ${respondFrom.lg`
		display: none;
	`}
`;

const NavButton = styled.button`
  color: #e2f4f2;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;

  img {
    display: inline-block;
  }

  img:last-child {
    display: none;
  }

  &:hover {
    background-color: #151515;
    transition: all ease 0.5s;

    img:first-child {
      display: none;
    }

    img:last-child {
      display: inline-block;
    }
  }
`;
