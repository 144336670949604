import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import {
	commonButtonLabel,
	commonButtonLabelHover,
} from '../../../../styles/pixiStyles';
import { PixiGame } from '../../index';

class CommonButton {
	app: Application;
	game: PixiGame;
	button: PIXI.Container;
	buttonLabel: PIXI.Text;
	buttonLabelHover: PIXI.Text;
	buttonPlay: PIXI.Sprite;
	buttonHover: PIXI.Sprite;
	pointerIsDown: boolean;
	pointerIsOver: boolean;

	constructor(game: PixiGame, app: Application, label: string) {
		this.game = game;
		this.app = app;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.button = new PIXI.Container();
		this.buttonLabel = new PIXI.Text(label, commonButtonLabel);
		this.buttonLabelHover = new PIXI.Text(label, commonButtonLabelHover);
		this.buttonPlay = new PIXI.Sprite(
			this.app.loader.resources['betModalButton'].texture!
		);
		this.buttonHover = new PIXI.Sprite(
			this.app.loader.resources['betModalButtonHover'].texture!
		);
	}

	initialize = () => {
		this.button.width = 101;
		this.button.height = 27;

		this.buttonPlay.x = 0;
		this.buttonPlay.y = 0;
		this.buttonPlay.width = 101;
		this.buttonPlay.height = 27;

		this.buttonHover.x = 0;
		this.buttonHover.y = 0;
		this.buttonHover.width = 101;
		this.buttonHover.height = 27;
		this.buttonHover.alpha = 0;

		this.buttonLabel.x = 101 / 2 - this.buttonLabel.width / 2;
		this.buttonLabel.y = 27 / 2 - this.buttonLabel.height / 2;
		this.buttonLabelHover.x = 101 / 2 - this.buttonLabelHover.width / 2;
		this.buttonLabelHover.y = 27 / 2 - this.buttonLabelHover.height / 2;

		this.buttonLabelHover.alpha = 0;

		this.button.addChild(this.buttonPlay!);
		this.button.addChild(this.buttonPlay!);
		this.button.addChild(this.buttonHover!);
		this.buttonPlay.addChild(this.buttonLabel!);
		this.buttonHover.addChild(this.buttonLabelHover!);

		this.button.interactive = true;
		this.button.buttonMode = true;

		this.button
			.on('pointerup', () => this.doPointerUp(this.app))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', (e) => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));
	};

	leftClick = (e:any) => {
    if(e.data.button === 0){
      this.doPointerDown(this.app)
    };
  }

	doPointerUp(app: Application) {
		if (this.pointerIsOver) {
		} else {
		}
		this.pointerIsDown = false;
	}

	doPointerUpOutside(app: Application) {
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		this.pointerIsDown = true;
	}

	doPointerOver(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonHover,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonLabelHover,
			0,
			{ alpha: 1 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsOver = true;
	}

	doPointerOut(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonPlay,
			0,
			{ alpha: 1 },
			{ alpha: 1, repeat: 0 }
		);
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}
}

export default CommonButton;
