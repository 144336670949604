import H1Img from '../../../assets/img/menu-modal/H1.png';
import H2Img from '../../../assets/img/menu-modal/H2.png';
import H3Img from '../../../assets/img/menu-modal/H3.png';
import H4Img from '../../../assets/img/menu-modal/H4.png';
import low1Img from '../../../assets/img/menu-modal/low1.png';
import low2Img from '../../../assets/img/menu-modal/low2.png';
import low3Img from '../../../assets/img/menu-modal/low3.png';
import low4Img from '../../../assets/img/menu-modal/low4.png';

import paytableItem1 from '../../../assets/img/menu-modal/paytable_candy1.png';
import paytableItem2 from '../../../assets/img/menu-modal/paytable_candy2.png';
import paytableItem3 from '../../../assets/img/menu-modal/paytable_candy3.png';
import paytableItem4 from '../../../assets/img/menu-modal/paytable_candy4.png';

export const reelsList = [
	{
		img: H1Img,
		text: `5x = 40
            <br />
            4x = 25
            <br />
            3x = 15
            <br />
            2x = 5`,
	},
	{
		img: H2Img,
		text: `5x = 20
            <br />
            4x = 10
            <br />
            3x = 5`,
	},
	{
		img: H3Img,
		text: `5x = 20
            <br />
            4x = 10
            <br />
            3x = 5`,
	},
	{
		img: H4Img,
		text: `5x = 20
            <br />
            4x = 10
            <br />
            3x = 5`,
	},
	{
		img: low1Img,
		text: `5x = 12
            <br />
            4x = 6
            <br />
            3x = 3`,
	},
	{
		img: low2Img,
		text: `5x = 12
            <br />
            4x = 6
            <br />
            3x = 3`,
	},
	{
		img: low3Img,
		text: `5x = 12
            <br />
            4x = 6
            <br />
            3x = 3`,
	},
	{
		img: low4Img,
		text: `5x = 12
            <br />
            4x = 6
            <br />
            3x = 3`,
	},
];

export const slidesList = [
	{
		img: paytableItem1,
	},
	{
		img: paytableItem2,
	},
	{
		img: paytableItem3,
	},
	{
		img: paytableItem4,
	},
];
