import { IInfoBar, IUser } from '../../../api/types/auth';
import {
	AuthActionEnum,
	SetAuthAction,
	SetErrorAction,
	SetIsLoadingAction,
	SetInfoBarAction,
	SetUserAction,
	SetBetAction,
	SetTotalBetAction,
} from './types';
import { AppDispatch } from '../../index';
import TokenStorage from '../../../helpers/TokenStorage';

export const AuthActionCreators = {
	setUser: (user: IUser): SetUserAction => ({
		type: AuthActionEnum.SET_USER,
		payload: user,
	}),
	setInfoBar: (info: IInfoBar): SetInfoBarAction => ({
		type: AuthActionEnum.SET_INFO_BAR,
		payload: info,
	}),
	setTotalBet: (totalBet: number[]): SetTotalBetAction => ({
		type: AuthActionEnum.SET_TOTAL_BET,
		payload: totalBet,
	}),
	setBetValue: (bet: number): SetBetAction => ({
		type: AuthActionEnum.SET_BET,
		payload: bet,
	}),
	setAuth: (auth: boolean): SetAuthAction => ({
		type: AuthActionEnum.SET_AUTH,
		payload: auth,
	}),
	setIsLoading: (payload: boolean): SetIsLoadingAction => ({
		type: AuthActionEnum.SET_IS_LOADING,
		payload,
	}),
	setError: (payload: string): SetErrorAction => ({
		type: AuthActionEnum.SET_ERROR,
		payload,
	}),

	logout: () => async (dispatch: AppDispatch) => {
		dispatch(AuthActionCreators.setInfoBar({} as IInfoBar));
		TokenStorage.removeToken();
		dispatch(AuthActionCreators.setAuth(false));
	},
};
