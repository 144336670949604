import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../..';
import { EventType, getEvent } from '../../../../GameEventBus';
import {
	infoLabelStyles,
	infoLabelStylesLandscape,
	infoLabelStylesPortret,
	infoValueStyles,
	infoValueStylesLandscape,
	infoValueStylesPortret,
} from '../../../../styles/pixiStyles';

export class InfoText {
	app: Application;
	game: PixiGame;
	label: string;
	width: number;
	value?: string | number;
	type?: 'default' | 'bet' | 'landscape' | 'portret';
	input?: PIXI.Container;
	title: PIXI.Text;
	text: PIXI.Text;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	plusButton: PIXI.Container;
	minusButton: PIXI.Container;
	backgroundInput?: PIXI.Sprite;
	texture?: PIXI.Texture;
	plusButtonDefault: PIXI.Sprite;
	plusButtonHover: PIXI.Sprite;
	plusButtonDisable: PIXI.Sprite;
	minusButtonDefault: PIXI.Sprite;
	minusButtonHover: PIXI.Sprite;
	minusButtonDisable: PIXI.Sprite;
	btnMinusInterval: number;
	btnPlusInterval: number;
	totalBetList: number[];
	selectedBetIndex: number;
	currentBetValue: number;
	betValue: number;
	betValueIndex?: number;

	constructor(
		label: string,
		value: string | number,
		type: 'default' | 'bet' | 'landscape' | 'portret',
		width: number,
		app: Application,
		game: PixiGame,
		betValueIndex?: number
	) {
		this.betValue = Number(value);
		this.totalBetList = [];
		this.selectedBetIndex = 0;
		this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.app = app;
		this.game = game;
		this.plusButton = new PIXI.Container();
		this.minusButton = new PIXI.Container();
		this.label = label;
		this.width = width;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.btnMinusInterval = 0;
		this.btnPlusInterval = 0;
		this.plusButtonDefault = new PIXI.Sprite(
			this.app.loader.resources['plusButtonDefault'].texture!
		);
		this.plusButtonHover = new PIXI.Sprite(
			this.app.loader.resources['plusButtonHover'].texture!
		);
		this.plusButtonDisable = new PIXI.Sprite(
			this.app.loader.resources['plusButtonHiden'].texture!
		);
		this.minusButtonDefault = new PIXI.Sprite(
			this.app.loader.resources['minusButtonDefault'].texture!
		);
		this.minusButtonHover = new PIXI.Sprite(
			this.app.loader.resources['minusButtonHover'].texture!
		);
		this.minusButtonDisable = new PIXI.Sprite(
			this.app.loader.resources['minusButtonHiden'].texture!
		);
		if (type === 'default') {
			this.value = value === 0 ? value : `${value} USD`;
			this.texture = this.app.loader.resources['infoBackground'].texture!;
			this.type = type;
			this.title = new PIXI.Text(`${this.label}`, infoLabelStyles);
			this.text = new PIXI.Text(this.value, infoValueStyles);
		} else if (type === 'bet') {
			this.betValueIndex = betValueIndex;
			this.value = this.betValueIndex! === 0 ? this.totalBetList[this.betValueIndex!] : `${this.totalBetList[this.betValueIndex!]} USD`;
			this.texture = this.app.loader.resources['betBackground'].texture!;
			this.type = type;
			this.title = new PIXI.Text(`${this.label}`, infoLabelStyles);
			this.text = new PIXI.Text(this.value, infoValueStyles);
		} else if (type === 'landscape') {
			this.value = value === 0 ? value : `${value}`;
			this.texture = this.app.loader.resources['infoBarMobileBG'].texture!;
			this.type = type;
			this.title = new PIXI.Text(`${this.label}`, infoLabelStylesLandscape);
			this.text = new PIXI.Text(this.value, infoValueStylesLandscape);
		} else if (type === 'portret') {
			this.value = value === 0 ? value : `${value} USD`;
			this.texture = this.app.loader.resources['infoBarMobileBG'].texture!;
			this.type = type;
			this.title = new PIXI.Text(`${this.label}`, infoLabelStylesPortret);
			this.text = new PIXI.Text(this.value, infoValueStylesPortret);
		} else {
			this.title = new PIXI.Text(`${this.label}`, infoLabelStyles);
			this.text = new PIXI.Text(this.value, infoValueStyles);
		}
	}

	initialize = () => {
		this.title.resolution = 2;
		this.text.resolution = 2;
		this.title.width = this.title.width * 1.2;
		this.backgroundInput = new PIXI.Sprite(this.texture);
		this.backgroundInput.width = this.width;

		this.backgroundInput.height = 60;

		this.input = new PIXI.Container();
		this.input.width = this.width;
		this.input.height = this.backgroundInput.height;
		this.input.addChild(this.backgroundInput, this.title, this.text);

		this.title.anchor.set(0.5, 0.5);
		this.text.anchor.set(0.5, 0.5);

		if (this.label === 'BALANCE') {
			const posX = this.input.width / 2 - this.title.width / 2;
		}

		this.title.x = this.width / 2;
		this.text.x = this.width / 2;

		this.title.y = 18;
		this.text.y = 38;

		if (this.type === 'bet') {
			this.initializeMinusButton();
			this.initializePlusButton();
		}
	};

	initializeMobile = () => {
		this.backgroundInput = new PIXI.Sprite(this.texture);
		this.backgroundInput.height = 0;

		this.input = new PIXI.Container();
		this.input.width = this.width;
		this.backgroundInput.width = this.input.width;
		this.input.height = this.backgroundInput.height;
		this.input.addChild(this.backgroundInput, this.title, this.text);
		if (this.type === 'landscape') {
			if (this.title.text === 'CREDIT') {
				this.title.anchor.set(1, 0.5)
				this.text.anchor.set(0, 0.5)
				this.title.x = this.text.width + 10;
			}
			this.title.anchor.set(1, 0.5)
			this.text.anchor.set(0, 0.5)
			this.title.x = 0;
			this.text.x = this.title.x + 10;
		} else if (this.type === 'portret') {
			this.title.anchor.set(0.5, 0.5);
			this.text.anchor.set(0.5, 0.5);
			if (this.title.text === 'CREDIT') {
				this.text.anchor.set(1, 0.5);
				this.title.anchor.set(1, 0.5);
			} else if (this.title.text === 'WIN') {
				this.text.x = 0;
			} else if (this.title.text === 'TOTAL BET') {
				this.text.anchor.set(0, 0.5);
				this.title.anchor.set(0, 0.5);
			};

			this.title.x = 0;
			if(this.type === 'portret') {
				this.title.width = this.width * 0.9;
			} else {
				this.title.width = this.width;
			}
			this.title.height = 8;
			this.text.height = 10;

			this.title.y = 18;
			this.text.y = 28;
		} else {
			this.title.x = this.width / 2;
			this.text.x = this.width / 2;

			this.title.y = 18;
			this.text.y = 38;
		}
	};

	initializeMinusButton = () => {
		this.minusButton
			.on('pointerup', () => this.doMinusPointerUp(this.app))
			.on('pointerupoutside', () => this.doMinusPointerUpOutside(this.app))
			.on('touchstart', () => this.doMinusPointerDown(this.app))
			.on('click', (e) => this.leftClickMinus(e))
			.on('pointerover', () => this.doMinusPointerOver(this.app))
			.on('pointerout', () => this.doMinusPointerOut(this.app))
			// .on('mousedown', () => this.doMinusPointerDown(this.app));

		this.minusButton.interactive = true;
		this.minusButton.buttonMode = true;

		this.minusButton.width = 33;
		this.minusButton.height = 52;

		this.minusButtonHover.alpha = 0;
		this.minusButtonDisable.alpha = 0;

		this.backgroundInput?.addChild(this.minusButton);
		this.minusButton.addChild(this.minusButtonDefault!);
		this.minusButton.addChild(this.minusButtonHover!);
		this.minusButton.addChild(this.minusButtonDisable!);

		this.minusButton.x = 4;
		this.minusButton.y = 4;
	};

	leftClickMinus = (e:any) => {
		if(this.game.slotMachine!.isRunning === false){
			if(e.data.button === 0){
				this.doMinusPointerDown(this.app)
			};
		}
	}

	initializePlusButton = () => {
		this.plusButton
			.on('pointerup', () => this.doPlusPointerUp(this.app))
			.on('pointerupoutside', () => this.doPlusPointerUpOutside(this.app))
			.on('touchstart', () => this.doPlusPointerDown(this.app))
			.on('click', (e) => this.leftClickPlus(e))
			.on('pointerover', () => this.doPlusPointerOver(this.app))
			.on('pointerout', () => this.doPlusPointerOut(this.app))
			// .on('mousedown', () => this.doPlusPointerDown(this.app));


		this.plusButton.interactive = true;
		this.plusButton.buttonMode = true;

		this.plusButton.width = 33;
		this.plusButton.height = 52;

		this.plusButtonHover.alpha = 0;
		this.plusButtonDisable.alpha = 0;

		this.backgroundInput?.addChild(this.plusButton);
		this.plusButton.addChild(this.plusButtonDefault!);
		this.plusButton.addChild(this.plusButtonHover!);
		this.plusButton.addChild(this.plusButtonDisable!);

		this.plusButton.x =
			250 - this.plusButton.width - this.plusButton.width * 0.11;
		this.plusButton.y = 4;
	};

	leftClickPlus = (e:any) => {
		if(e.data.button === 0){
			this.doPlusPointerDown(this.app)
		};
	}

	setTotalBetList(list: number[]) {
		this.totalBetList = list;
		this.selectedBetIndex = this.game.initialData?.getBetValueIndex() || this.totalBetList.findIndex(item => item === Math.trunc(this.betValue));
		this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.betValue = this.currentBetValue;
		getEvent(EventType.CURRENT_BET_INDEX).send(this.selectedBetIndex);
		this.game.initialData?.setBetValueIndex(this.selectedBetIndex);
		this.game.initialData?.setBetValue(this.currentBetValue);
		this.game.slotMachine?.changeBetValue(this.currentBetValue);
		this.text.text = `${this.betValue.toFixed(2)} USD`;
	}

	changeValue = (value: number) => {
		this.text.text = `${value.toFixed(2)} USD`;
	};

	changeValueLandscape = (value: number) => {
		this.text.text = `${value.toFixed(2)}`;
	};

	doMinusPointerUp(app: Application) {
		clearInterval(this.btnMinusInterval)
		if (this.pointerIsOver) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.minusButtonHover,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.minusButtonDefault,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.minusButtonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		}
		if(this.selectedBetIndex === 0){
			this.minusButton.buttonMode = false;
			// this.minusButton.interactive = false;
			this.minusButton.alpha = 0.5;
			let wiggleTween = gsap.timeline();
			wiggleTween.to(
				this.minusButtonHover,
				0,
				{ alpha: 0 },
			);
			wiggleTween.to(
				this.minusButtonDefault,
				0,
				{ alpha: 1 },
			);
		}
		this.pointerIsDown = false;
	}

	doPlusPointerUp(app: Application) {
		clearInterval(this.btnPlusInterval);
		if (this.pointerIsOver) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.plusButtonHover,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.plusButtonDefault,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.plusButtonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		}
		if(this.selectedBetIndex === this.totalBetList.length - 1){
			this.plusButton.buttonMode = false;
			// this.plusButton.interactive = false;
			this.plusButton.alpha = 0.5;
			let wiggleTween = gsap.timeline();
			wiggleTween.to(
				this.plusButtonHover,
				0,
				{ alpha: 0 },
			);
			wiggleTween.to(
				this.plusButtonDefault,
				0,
				{ alpha: 1 },
			);
		}
		this.pointerIsDown = false;
	}

	doMinusPointerUpOutside(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.minusButtonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPlusPointerUpOutside(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.plusButtonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doMinusPointerDown(app: Application) {
		if(this.pointerIsDown) return
		if (this.selectedBetIndex !== 0) {
		this.game.soundController.btn_ui?.play()
		}
		if(this.selectedBetIndex > 0) {
			this.selectedBetIndex--;
		} else if (this.selectedBetIndex === 0) {
			this.selectedBetIndex = 0
		}
		this.game.initialData?.setBetValueIndex(this.selectedBetIndex);
		this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.btnMinusInterval = window.setInterval(() => {
			const value = Number(this.text.text.split(' ')[0]);
			if (this.currentBetValue <= 0){
				for (let i = 1; i < this.btnMinusInterval; i++) {
					clearInterval(i);
				}
				return;
			}
			if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === false){
				this.text.text = `${(this.currentBetValue).toFixed(2)} USD`;
				this.game.assetsController?.betModal?.changeBetValue(this.currentBetValue)
				this.game.slotMachine?.changeBetValue(this.currentBetValue);
				this.game.assetsController?.buyBonusModal?.changeMaxCost(this.currentBetValue)
				this.game.assetsController?.buyBonusModal?.changeMinCost(this.currentBetValue)
				this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(this.currentBetValue)
				this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(this.currentBetValue)
				this.game.assetsController?.buyMinBonusModal?.changeMinCost(this.currentBetValue)
				this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(this.currentBetValue)
				this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(this.currentBetValue)
				this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(this.currentBetValue)
			} else {
				this.text.text = `${((this.currentBetValue) / 1.25).toFixed(2)} USD`;
				this.game.assetsController?.betModal?.changeBetValue((this.currentBetValue) / 1.25)
				this.game.slotMachine?.changeBetValue((this.currentBetValue) / 1.25);
				this.game.assetsController?.buyBonusModal?.changeMaxCost((this.currentBetValue) / 1.25)
				this.game.assetsController?.buyBonusModal?.changeMinCost((this.currentBetValue) / 1.25)
				this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost((this.currentBetValue) / 1.25)
				this.game.assetsController?.buyBonusModalPortrait?.changeMinCost((this.currentBetValue) / 1.25)
				this.game.assetsController?.buyMinBonusModal?.changeMinCost((this.currentBetValue) / 1.25)
				this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost((this.currentBetValue) / 1.25)
				this.game.assetsController?.buyMaxBonusModal?.changeMaxCost((this.currentBetValue) / 1.25)
				this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost((this.currentBetValue) / 1.25)
			}
			getEvent(EventType.GAME_BET_VALUE).send(this.game.slotMachine?.betValue)
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.minusButtonHover,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.minusButtonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		}, 500);
		const value = Number(this.text.text.split(' ')[0]);
		if (this.currentBetValue <= 0) return;
		if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === false){
			this.text.text = `${(this.currentBetValue).toFixed(2)} USD`;
			this.game.assetsController?.betModal?.changeBetValue(this.currentBetValue)
			this.game.slotMachine?.changeBetValue(this.currentBetValue);
			this.game.assetsController?.buyBonusModal?.changeMaxCost(this.currentBetValue)
			this.game.assetsController?.buyBonusModal?.changeMinCost(this.currentBetValue)
			this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(this.currentBetValue)
			this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(this.currentBetValue)
			this.game.assetsController?.buyMinBonusModal?.changeMinCost(this.currentBetValue)
			this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(this.currentBetValue)
			this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(this.currentBetValue)
			this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(this.currentBetValue)
		} else {
			this.text.text = `${((this.currentBetValue) * 1.25).toFixed(2)} USD`;
			this.game.assetsController?.betModal?.changeBetValue((this.currentBetValue) * 1.25)
			this.game.slotMachine?.changeBetValue((this.currentBetValue) * 1.25);
			this.game.assetsController?.buyBonusModal?.changeMaxCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyBonusModal?.changeMinCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyBonusModalPortrait?.changeMinCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyMinBonusModal?.changeMinCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyMaxBonusModal?.changeMaxCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost((this.currentBetValue) * 1.25)
		}
		getEvent(EventType.GAME_BET_VALUE).send(this.game.slotMachine?.betValue)
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.minusButtonHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.minusButtonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = true;
	}

	doPlusPointerDown(app: Application) {
		if(this.pointerIsDown) return
		if(this.selectedBetIndex !== this.totalBetList.length - 1){
		this.game.soundController.btn_ui?.play()
		}
		if(this.selectedBetIndex < this.totalBetList.length - 1 && this.selectedBetIndex !== this.totalBetList.length - 1) {
			this.selectedBetIndex++;
		}
		this.game.initialData?.setBetValueIndex(this.selectedBetIndex);
		this.currentBetValue = this.totalBetList[this.selectedBetIndex];

		this.btnPlusInterval = window.setInterval(() => {
		const value = Number(this.text.text.split(' ')[0]);
		if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === false){
			this.text.text = `${(this.currentBetValue).toFixed(2)} USD`;
			this.game.assetsController?.betModal?.changeBetValue(this.currentBetValue)
			this.game.slotMachine?.changeBetValue(this.currentBetValue);
			this.game.assetsController?.buyBonusModal?.changeMaxCost(this.currentBetValue)
			this.game.assetsController?.buyBonusModal?.changeMinCost(this.currentBetValue)
			this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(this.currentBetValue)
			this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(this.currentBetValue)
			this.game.assetsController?.buyMinBonusModal?.changeMinCost(this.currentBetValue)
			this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(this.currentBetValue)
			this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(this.currentBetValue)
			this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(this.currentBetValue)
		} else {
			this.text.text = `${((this.currentBetValue) * 1.25).toFixed(2)} USD`;
			this.game.assetsController?.betModal?.changeBetValue((this.currentBetValue) * 1.25)
			this.game.slotMachine?.changeBetValue((this.currentBetValue) * 1.25);
			this.game.assetsController?.buyBonusModal?.changeMaxCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyBonusModal?.changeMinCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyBonusModalPortrait?.changeMinCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyMinBonusModal?.changeMinCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyMaxBonusModal?.changeMaxCost((this.currentBetValue) * 1.25)
			this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost((this.currentBetValue) * 1.25)
		}
		getEvent(EventType.GAME_BET_VALUE).send(this.game.slotMachine?.betValue)
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.plusButtonHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.plusButtonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		}, 500);
			const value = Number(this.text.text.split(' ')[0]);
			if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === false){
				this.text.text = `${(this.currentBetValue).toFixed(2)} USD`;
				this.game.assetsController?.betModal?.changeBetValue(this.currentBetValue)
				this.game.slotMachine?.changeBetValue(this.currentBetValue);
				this.game.assetsController?.buyBonusModal?.changeMaxCost(this.currentBetValue)
				this.game.assetsController?.buyBonusModal?.changeMinCost(this.currentBetValue)
				this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(this.currentBetValue)
				this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(this.currentBetValue)
				this.game.assetsController?.buyMinBonusModal?.changeMinCost(this.currentBetValue)
				this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(this.currentBetValue)
				this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(this.currentBetValue)
				this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(this.currentBetValue)
			} else {
				this.text.text = `${((this.currentBetValue) * 1.25).toFixed(2)} USD`;
				this.game.assetsController?.betModal?.changeBetValue((this.currentBetValue) * 1.25)
				this.game.slotMachine?.changeBetValue((this.currentBetValue) * 1.25);
				this.game.assetsController?.buyBonusModal?.changeMaxCost((this.currentBetValue) * 1.25)
				this.game.assetsController?.buyBonusModal?.changeMinCost((this.currentBetValue) * 1.25)
				this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost((this.currentBetValue) * 1.25)
				this.game.assetsController?.buyBonusModalPortrait?.changeMinCost((this.currentBetValue) * 1.25)
				this.game.assetsController?.buyMinBonusModal?.changeMinCost((this.currentBetValue) * 1.25)
				this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost((this.currentBetValue) * 1.25)
				this.game.assetsController?.buyMaxBonusModal?.changeMaxCost((this.currentBetValue) * 1.25)
				this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost((this.currentBetValue) * 1.25)
			}
			getEvent(EventType.GAME_BET_VALUE).send(this.game.slotMachine?.betValue)
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.plusButtonHover,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.plusButtonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		this.pointerIsDown = true;
	}

	doMinusPointerOver(app: Application) {
		let wiggleTween = gsap.timeline();
		if(this.selectedBetIndex !== 0){
		wiggleTween.fromTo(
			this.minusButtonHover,
			0.3,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		}
		this.pointerIsOver = true;
	}

	doPlusPointerOver(app: Application) {
		let wiggleTween = gsap.timeline();
		if(this.selectedBetIndex !== this.totalBetList.length - 1){
		wiggleTween.fromTo(
			this.plusButtonHover,
			0.3,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		}
		this.pointerIsOver = true;
	}

	doMinusPointerOut(app: Application) {
		clearInterval(this.btnMinusInterval);
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.minusButtonHover,
			0.3,
			{ alpha: 0 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.minusButtonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}

	doPlusPointerOut(app: Application) {
		clearInterval(this.btnPlusInterval);
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.plusButtonHover,
			0.3,
			{ alpha: 0 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.plusButtonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}

	doDefaultVisible(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.to(
			this.plusButtonHover,
			0,
			{ alpha: 0 },
		);
		wiggleTween.to(
			this.plusButtonDefault,
			0,
			{ alpha: 1 },
		);
		wiggleTween.to(
			this.minusButtonHover,
			0,
			{ alpha: 0 },
		);
		wiggleTween.to(
			this.minusButtonDefault,
			0,
			{ alpha: 1 },
		);
	}

	changeButtonsState(state: boolean) {
		this.plusButton.interactive = state;
		this.plusButton.buttonMode = state;
		this.plusButton.alpha= (state && this.selectedBetIndex !== this.totalBetList.length - 1) ? 1 : 0.5;

		this.minusButton.interactive = state;
		this.minusButton.buttonMode = state;
		this.minusButton.alpha= (state && this.selectedBetIndex !== 0) ? 1 : 0.5;
	}
}
