import {Spine} from "pixi-spine";

export class Antisipator {
    item: Spine
    spineData: any
    constructor(spineData: any) {
        this.spineData = spineData
        this.item = new Spine(spineData)
        this.item.state.setAnimation(0, 'animation', true)
        this.item.width = 266
        this.item.height = 266
    }

    copy () {
      return new Antisipator(this.spineData)
    }
}
