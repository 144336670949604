import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { switchButtonLabel } from '../../../../styles/pixiStyles';
import { PixiGame } from '../../index';

class SwitchButton {
	app: Application;
	game: PixiGame;
	container: PIXI.Container;
	label: PIXI.Text;
	buttonSwitch: PIXI.Container;
	buttonOn: PIXI.Sprite;
	buttonOnBullet: PIXI.Sprite;
	buttonOff: PIXI.Sprite;
	buttonOffBullet: PIXI.Sprite;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	isActive: boolean;

	constructor(game: PixiGame, app: Application, label: string) {
		this.game = game;
		this.app = app;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.isActive = false;
		this.container = new PIXI.Container();
		this.buttonSwitch = new PIXI.Container();
		this.label = new PIXI.Text(label, switchButtonLabel);
		this.buttonOn = new PIXI.Sprite(
			this.app.loader.resources['settingsModalSwitchBackgroundOn'].texture!
		);
		this.buttonOnBullet = new PIXI.Sprite(
			this.app.loader.resources['settingsModalSwitchBulletOn'].texture!
		);
		this.buttonOff = new PIXI.Sprite(
			this.app.loader.resources['settingsModalSwitchBackgroundOff'].texture!
		);
		this.buttonOffBullet = new PIXI.Sprite(
			this.app.loader.resources['settingsModalSwitchBulletOff'].texture!
		);
	}

	initialize = () => {
		this.container.width = 288;
		this.container.height = 29;
		this.container.interactive = true;
		this.container.buttonMode = true;

		this.label.x = 0;
		this.label.y = 29 / 2 - this.label.height / 2;

		this.buttonSwitch.x = 235;
		this.buttonSwitch.y = 0;
		this.buttonSwitch.width = 53;
		this.buttonSwitch.height = 29;

		this.buttonOn.x = 0;
		this.buttonOn.y = 0;
		this.buttonOn.width = 53;
		this.buttonOn.height = 29;
		this.buttonOn.alpha = 0;

		this.buttonOff.x = 0;
		this.buttonOff.y = 0;
		this.buttonOff.width = 53;
		this.buttonOff.height = 29;

		this.buttonOnBullet.x = 53 - 19 - 5;
		this.buttonOnBullet.y = 5;
		this.buttonOnBullet.width = 19;
		this.buttonOnBullet.height = 19;
		this.buttonOnBullet.alpha = 1;

		this.buttonOffBullet.x = 5;
		this.buttonOffBullet.y = 5;
		this.buttonOffBullet.width = 19;
		this.buttonOffBullet.height = 19;

		this.container.addChild(this.label!);
		this.container.addChild(this.buttonSwitch!);
		this.buttonSwitch.addChild(this.buttonOff!);
		this.buttonOff.addChild(this.buttonOffBullet!);
		this.buttonSwitch.addChild(this.buttonOn!);
		this.buttonOn.addChild(this.buttonOnBullet!);

		this.container
			.on('pointerup', () => this.doPointerUp(this.app))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', (e) => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));
	};

	leftClick = (e:any) => {
    if(e.data.button === 0){
      this.doPointerDown(this.app)
    };
  }

	doPointerUp(app: Application) {
		if (this.pointerIsOver) {
		} else {
		}
		this.pointerIsDown = false;
	}

	doPointerUpOutside(app: Application) {
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		this.isActive = !this.isActive;
		if (this.isActive) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonOn,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.buttonOff,
				0,
				{ alpha: 1 },
				{ alpha: 1, repeat: 0 }
			);
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonOn,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.buttonOff,
				0,
				{ alpha: 1 },
				{ alpha: 1, repeat: 0 }
			);
		}
		this.pointerIsDown = true;
	}

	doPointerOver(app: Application) {
		this.pointerIsOver = true;
	}

	doPointerOut(app: Application) {
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}
}

export default SwitchButton;
