import axios from 'axios';
import TokenStorage from '../helpers/TokenStorage';
import Auth from './Auth';

export default class Api {
	auth: Auth;

	constructor() {
		this.auth = new Auth(this.request);

	}

	// // инстанс запросов через который буду выполятся все запросы на бекенд
	get request() {
		const instance = axios.create({ baseURL: './api/v1' });

		instance.interceptors.request.use(config => {
			// TODO: get token from storage
			const token = TokenStorage.getToken();
			if (token) {
				(config.headers as any).common['Authorization'] = `Bearer ${token}`;
			}

			return config;
		});

		return instance;
	}
}
