import { useEffect, useState } from "react";
import { PixiGame } from "..";
import { EventType, useEventReceiver } from "../../../GameEventBus";

const useHandlerGameBet = (game: PixiGame, balance: number) => {
  const [betValue, setBetValue] = useState(game.slotMachine?.betValue || 0);

  useEventReceiver<number>(EventType.GAME_BET_VALUE, (value) => {
    setBetValue(value);
  });

  useEffect(() => {
    setBetValue(betValue || game.slotMachine?.betValue!);
  }, [balance])

  useEffect(() => {
    if ((betValue! || game.slotMachine?.betValue!) > balance) {
      game.slotMachine?.changeButtonState(true);
      game.assetsController?.changeActiveButtonPlay(false);
    } else {
      game.slotMachine?.changeButtonState(false);
      game.assetsController?.changeActiveButtonPlay(true);
    }
  }, [betValue]);

  return { betValue }
}

export default useHandlerGameBet