import * as PIXI from 'pixi.js';

export const labelStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 44,
	fontWeight: '700',
	lineHeight: 52.8,
	fill: '#E2F4F2',
	align: 'center',
});

export const labelHoverStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 44,
	fontWeight: '700',
	lineHeight: 52.8,
	fill: '#151515',
	align: 'center',
});
