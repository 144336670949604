import { WrapperProxy } from "@playmer/ui-wrapper";
import { ConfigResolver } from "./ConfigResolver";
import EventResolver from "./EventResolver";

class UI {
	private _wrapper: WrapperProxy = document.querySelector("#ui-wrapper") as WrapperProxy;
	private isRegistered: boolean = false;
	public events!: EventResolver;

	public config = new ConfigResolver(); 

	/**
	 * Регистрация UI в игре
	 *
	 * В игре должен быть элемент с id `ui-wrapper`
	 * а также заполнена вся нужная конфигурация UI
	 * @see http://161.35.76.219/v0.x/config.html
	 *
	 * @param gameName Название игры
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.register("Candy Slot");
	 * ```
	 */
	async register(gameName: string) {
		if (this.isRegistered) return;
		this.isRegistered = true;
        
		this.config.setTitle(gameName);

        try {

            await this._wrapper.init(this.config.options);
			this.events = new EventResolver(this._wrapper);
            this._wrapper.display();
            console.log(this._wrapper)
        } catch(e) {
            console.log(e)
        }
	}
}

export default new UI();
