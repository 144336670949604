import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';
import ConfirmBuyButton from '../BuyBonusButton/confirmBuyButton';
import ConfirmBuyButtonPortrait from '../BuyBonusModalPortrait/ConfirmBuyButtonPortrait';
import CustomButton from '../CustomButton';

class BuyMaxBonusModalPortrait {
	app: Application;
	game: PixiGame;
	modalWrapper: PIXI.Container;
	windowBackground: PIXI.Graphics;
	modal: PIXI.Container;
	background: PIXI.Graphics;
	title: PIXI.Text;
	maxCost: PIXI.Text;
	maxBonusAmount: PIXI.Text;
	maxBonusTitle: PIXI.Text;
	maxBonusSprite: PIXI.Sprite;
	betValue: number | string;
	closeButton?: CustomButton;
	confirmButtonMax?: ConfirmBuyButton;
	disclaimButtonMax?: ConfirmBuyButton;
	isModalOpen: boolean;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.betValue = Math.trunc((this.game.initialData?.getBetValue() || 1))
		this.isModalOpen = false;
		this.modal = new PIXI.Container();
		this.modalWrapper = new PIXI.Container();
		this.background = new PIXI.Graphics();
		this.windowBackground = new PIXI.Graphics();
		this.title = new PIXI.Text('CONFIRMATION', {
			fontFamily: 'Rubik',
			fontSize: 28,
			fontWeight: '700',
			lineHeight: 33.6,
			fill: '#E2F4F2',
		});
		this.maxCost = new PIXI.Text(`€ ${Math.trunc((400 * Number(this.betValue)))}`, {
			fontFamily: 'Rubik',
			fontSize: 16,
			fontWeight: '700',
			lineHeight: 24,
			fill: '#F9AD29',
		});

		this.maxBonusAmount = new PIXI.Text('10 FREE SPINS', {
			fontFamily: 'Rubik',
			fontSize: 14,
			fontWeight: '700',
			lineHeight: 21,
			fill: '#E2F4F2',
		});

		this.maxBonusTitle = new PIXI.Text('BUY BONUS', {
			fontFamily: 'Rubik',
			fontSize: 16,
			fontWeight: '700',
			lineHeight: 24,
			fill: '#F9AD29',
		});

		this.maxBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['rightBonus'].texture!
		);
	}

	initialize = async () => {
		await this.setupButtons();
		this.modalWrapper.x = 0;
		this.modalWrapper.y = 0;
		this.modalWrapper.width = this.app.screen.width;
		this.modalWrapper.height = this.app.screen.height + 32;
		this.modalWrapper.visible = false;
		this.modalWrapper.zIndex = 10000;

		this.modal.width = 1618;
		this.modal.height = 879;

		this.windowBackground.beginFill(0x151515);
		this.windowBackground.drawRect(
			0,
			0,
			this.app.screen.width,
			this.app.screen.height + 32
		);
		this.windowBackground.endFill();
		this.windowBackground.alpha = 0.8;

		this.background.beginFill(0x151515);
		this.background.drawRoundedRect(0, 0, 304, 536, 10);
		this.background.endFill();

		this.windowBackground.zIndex = 1;
		this.modal.zIndex = 16;

		this.title.x = (this.background.width - this.title.width) / 2;
		this.title.y = 56;

		this.maxCost.x = 186;
		this.maxCost.y = 217;

		this.maxBonusAmount.x = 186;
		this.maxBonusAmount.y = 187;

		this.maxBonusTitle.x = 186;
		this.maxBonusTitle.y = 152;

		this.maxBonusSprite.width = 155;
		this.maxBonusSprite.height = 90;
		this.maxBonusSprite.x = 11;
		this.maxBonusSprite.y = 151;

		this.app.stage.addChild(this.modalWrapper);
		this.modalWrapper.addChild(this.windowBackground!);
		this.modalWrapper.addChild(this.modal!);
		this.modal.addChild(this.background!);
		this.background.addChild(this.title!);
		this.background.addChild(this.maxCost!);
		this.background.addChild(this.maxBonusAmount!);
		this.background.addChild(this.maxBonusTitle!);
		this.background.addChild(this.maxBonusSprite);
	};
	changeMaxCost = (betValue:number) => {
		this.maxCost.text = `€ ${Math.trunc((400 * betValue))}`
		this.betValue = betValue;
	}

	onClickAndTouch = (element: any, func: Function, state: boolean) => {
		element.button.on('touchstart', () => func(state));
		element.button.on('click', (e:any) => this.leftClickAutoPlayButton(e, func, state));
	};

	leftClickAutoPlayButton = (e:any, func: Function, state: boolean) => {
		if(e.data.button === 0){
			func(state);
		};
	}

	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
		}
	};

	confirmBuy = async (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
		}
	};

	disclaimBuy = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
		}
	};

	setupButtons = async () => {
		this.closeButton = new CustomButton(
			this.game,
			this.app,
			'closeButtonDefault',
			'closeButtonHover',
			'closeButtonDisabled',
			40,
			40
		);
		this.confirmButtonMax = new ConfirmBuyButtonPortrait(
			this.game,
			this.app,
			'confirmBuyBonusDefaultPortrait',
			'confirmBuyBonusDefaultPortrait',
			'confirmBuyBonusDefaultPortrait',
			103,
			60,
			'YES'
		);

		this.disclaimButtonMax = new ConfirmBuyButtonPortrait(
			this.game,
			this.app,
			'confirmBuyBonusDefaultPortrait',
			'confirmBuyBonusDefaultPortrait',
			'confirmBuyBonusDefaultPortrait',
			103,
			60,
			'NO'
		);

		this.onClickAndTouch(this.closeButton, this.showModal, false);
		this.onClickAndTouch(this.confirmButtonMax, this.confirmBuy, false);
		this.onClickAndTouch(this.disclaimButtonMax, this.disclaimBuy, false);

		this.onClickAndTouch(
			this.closeButton,
			this.game.assetsController?.buyMaxBonusModal!.showModal!,
			false
		);
		this.onClickAndTouch(
			this.confirmButtonMax,
			this.game.assetsController?.buyMaxBonusModal!.confirmBuy!,
			false
		);
		this.onClickAndTouch(
			this.disclaimButtonMax,
			this.game.assetsController?.buyMaxBonusModal!.disclaimBuy!,
			false
		);

		await this.closeButton.initialize();
		await this.confirmButtonMax.initialize();
		await this.disclaimButtonMax.initialize();

		this.closeButton.button.x = 276;
		this.closeButton.button.y = -12;

		this.confirmButtonMax.button.x = 44;
		this.confirmButtonMax.button.y = 340;

		this.disclaimButtonMax.button.x = 166;
		this.disclaimButtonMax.button.y = 340;
		this.disclaimButtonMax!.label!.x = 50;

		this.background.addChild(this.closeButton.button!);
		this.background.addChild(this.confirmButtonMax.button!);
		this.background.addChild(this.disclaimButtonMax.button!);
	};

	changeBuyMaxBonusModalPortraitScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeBuyMaxBonusModalPortraitPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}
}

export default BuyMaxBonusModalPortrait;
