import gameRules1 from '../../../assets/img/menu-modal/candy_game_rules1.png';
import gameRules2 from '../../../assets/img/menu-modal/candy_game_rules2.png';
import gameRules3 from '../../../assets/img/menu-modal/candy_game_rules3.png';

export const rulesList = {
	content: `<h2>Game description:</h2>
      <p>
      Square Gummy Bear is a 5-reel, 3-row video slot with 10 paylines, and
      a special row on top.
      </p>
      <br />
      <h2>Cascades Feature:</h2>
      <p>
      After every win, all the winning symbols are destroyed. New symbols
      drop down to replace them, giving a chance for more wins. This repeats
      until there are no more wins.
      </p>
      <br />
      <h2>Top Row:</h2>
      <p>
      The top row is an extra spot over each of the reels. None on the bet
      lines goes through the top row. The symbols available in the top row
      are:
      </p>
      <p>
      ● wilds, with the following possible multipliers: x1, x2, x3, x5, x10,
      x20, x30, x50 and x100
	    </p>
	    <p>● and blanks (empty symbols)</p>
	    <p>
      If a cascade creates an empty spot below a wild, that wild will drop
      down to the active game area
	    </p>
	    <br />
	    <h2>Free Falls:</h2>
	    <p>
      Landing 3, 4 or 5 bonus symbols awards 6, 8 or 10 free spins,
      respectively. Bonus symbols are available in the initial spin and in
      cascades, but only in base game.
	    </p>
	    <p>Free spins start after there cascade sequence has ended.</p>
	    <p>
      If you win on a multiplier wild in free spins, no lower multiplier
      wilds will appear in the top row until the end of that free spins
      session.
	    </p>
	    <p>
      The list of available multiplier wilds for the top row is shown in the
      multiplier bar.
	    </p>
	    <p>Free spins can not be retriggered.</p>
	    <br />
	    <h2>Payouts:</h2>
	    <p>
      Winning combinations and payouts are made according to the paytable.
	    </p>
	    <p>Only the highest combinations per bet line is paid.</p>
	    <p>
      Bet line wins pay if in succession from the leftmost reel to the
      right.
	    </p>
	    <p>Simultaneous wins on different bet lines are added.</p>
	    <p>
      Wild symbol substitutes for whichever symbol ensures the highest win
      on a given payline.
	    </p>
	    <p>
	    Multiplier wild additionally multiplies the win by the number
	    indicated on the wild.
      </p>
      <p>
	    If there is more that one multiplier wild on a payline, the win is
	    multiplied by the sum of all the wild multipliers.
      </p>
      <br />
      <h2>How to play:</h2>
      <p>Use the ‘-' and '+’ buttons to adjust the bet size</p>
      <p>Click the spin button or press ‘space’ to make a bet</p>
      <p>Enable turbo in settings to speed up the game</p>
      <p>
	    Autoplay launches the game for a seleced number of bets at current
	    stake.
      </p>
      <br />
      <h2>Additional information:</h2>
      <p>The RTP of the game is 96.0%.</p>
      <p>Malfunction voids all pays and plays.</p>`,
};

export const slidesList = [
	{
		img: gameRules1,
	},
	{
		img: gameRules2,
	},
	{
		img: gameRules3,
	},
];
