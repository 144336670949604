import { FC } from 'react';
import {
	ButtonsWrapper,
	Form,
	FormInput,
	FormPasswordInput,
	FormTitle,
	SubmitButton,
	Wrapper,
} from '../../styles/formStyles';
import { RouteNames } from '../../router';
import { useNavigate } from 'react-router-dom';
import { useUpdate } from './hooks/useAuth';
import { useForm } from '@mantine/form';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Header from '../../components/Header/Header';

const UpdateProfile: FC = () => {
	const { user } = useTypedSelector(state => state.auth);
	const navigate = useNavigate();

	const { handleUpdate } = useUpdate();

	const form = useForm({
		validateInputOnChange: true,
		initialValues: {
			userName: user?.userName ? user.userName : '',
			email: user?.email ? user.email : '',
			newPassword: '',
		},

		validate: {
			userName: value =>
				value.length < 2 ? 'Name must have at least 2 letters' : null,
			email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
			newPassword: value =>
				value.length < 0 ? 'Password must have at least 2 letters' : null,
		},
	});

	return (
		<>
			<Header />
			<Wrapper>
				<Form onSubmit={form.onSubmit(handleUpdate)}>
					<FormTitle>Update profile</FormTitle>
					<FormInput placeholder='Name' {...form.getInputProps('userName')} />
					<FormInput placeholder='Email' {...form.getInputProps('email')} />
					<FormPasswordInput
						placeholder='New password'
						{...form.getInputProps('newPassword')}
					/>
					<ButtonsWrapper>
						<SubmitButton mt='sm' mb='sm' type='submit'>
							Update
						</SubmitButton>
						<SubmitButton
							type='button'
							onClick={() => navigate(RouteNames.LOGIN)}
						>
							Back
						</SubmitButton>
					</ButtonsWrapper>
				</Form>
			</Wrapper>
		</>
	);
};

export default UpdateProfile;
