export const arrHaveValue = (arr: number[][] | number[], value: number) => {
    return new Promise((resolve) => {
        if (typeof arr[0] === 'object') {
            arr = arr as number[][];
            //    two-dimensional array
            arr.forEach((elem) => {
                if (elem.includes(value)) return resolve(true);
            });
        }
        else {
            //    STANDARD ARR
            arr = arr as number[];
            if (arr.includes(value)) return resolve(true);
        }
        
        return resolve(false);
    });
    
};


export const arrCopy = (arr: number[][]): Promise<number[][]> => {
    return new Promise((resolve) => {
        const res: number[][] = [];
        arr.forEach((element, index) => {
            res.push([]);
            element.forEach((item) => {
                res[index].push(item);
            });
        });
        return resolve(res);
    });
};


export const findExtraSymbols = async(winMap: number[][], previousSymbols: number[][]):Promise<number[][]> => {
    const newPositions: number[][] = await arrCopy(previousSymbols!)
    
    for (let i = 0; i < 5; i++) {
        // newPositions.push([])
        for (let j = 0; j < 3; j++) {
            if (winMap[i][j] === -1) {
                const startFrom = newPositions[i].length === 3 ? j : newPositions[i].length === 2 ? j - 1 : j - 2
                newPositions[i].splice(startFrom, 1)
            }
        }
    }
    
    //marking available positions
    for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 3; j++) {
            if (newPositions[i].length < 3) {
                newPositions[i].unshift(-1)
            }
        }
    }
    
    return newPositions
}
