import { useCallback, useEffect } from "react";
import { PixiGame } from "..";
import {store} from "../../../store";

let handleClickTimeout:any = null;
const useHandlerKeyDown = (game: PixiGame, isAssetsLoaded:boolean) => {
  const handleClick = useCallback(
    (e: any) => {
      if (!game.isInitialized) return;
      const autoplayModalIsOpened = game.assetsController?.autoPlayButton?.isModalActive
      const buyBonusModalIsOpened = game.assetsController?.buyBonusModal?.isModalOpen
      const buyMinBonusModalIsOpened = game.assetsController?.buyMinBonusModal?.isModalOpen
      const buyMaxBonusModalIsOpened = game.assetsController?.buyMaxBonusModal?.isModalOpen
      const autoSpin = game.assetsController?.autoPlayModal?.stopSpin
      const winFreeSpinModal = game.assetsController?.winStartPopUp?.isModalOpen
      const finishFreeSpinModal = game.assetsController?.winFinishPopUp?.isModalOpen
      const menuModalIsOpened = game.assetsController?.isMenuOpen
      const settingModalIsOpened = store.getState().settingModalReducer.settingModalIsOpened;
      if (
          e.code === "Space" &&
          !autoplayModalIsOpened &&
          !buyBonusModalIsOpened &&
          !buyMaxBonusModalIsOpened &&
          !buyMinBonusModalIsOpened &&
          !winFreeSpinModal &&
          !finishFreeSpinModal &&
          !settingModalIsOpened &&
          autoSpin &&
          !menuModalIsOpened
      ) {
        if(e.repeat){
          if(game.slotMachine?.isFsDropped === true){
            return
          } else {
            game.slotMachine?.changeButtonState(true);
            game.assetsController?.buyBonusButton?.doDefaultVisible(e);
            game.assetsController?.doubleBonusChanceButton?.doDefaultVisible(e);
            game.assetsController?.betInput?.doDefaultVisible(e);
            game.slotMachine?.onSpin({isBonusGame: false, isDoubleBonusGame: false});
          }
        }
        if((game.assetsController!.isSpacePressed && !game.assetsController?.isFs) || game.slotMachine?.isFsDropped === true
          ){
          return
        } else {
          game.slotMachine?.onSpin({isBonusGame: false, isDoubleBonusGame: false});
          game.assetsController!.isSpacePressed = true;
        }
       
      }
    },
    [game]
  );

  useEffect(() => {
    if(!isAssetsLoaded) return;
    window.addEventListener("keydown", handleClick);

    return () => {
      window.removeEventListener("keydown", handleClick);
    };
  }, [isAssetsLoaded]);
}

export default useHandlerKeyDown
