import {Application} from 'pixi.js';
import arrowLeftDefault from '../assets/buttons/btn-arrow-left-default.png';
import arrowLeftHover from '../assets/buttons/btn-arrow-left-hover.png';
import arrowLeftDisabled from '../assets/buttons/btn-arrow-left-disabled.png';
import arrowRightDefault from '../assets/buttons/btn-arrow-right-default.png';
import arrowRightHover from '../assets/buttons/btn-arrow-right-hover.png';
import arrowRightDisabled from '../assets/buttons/btn-arrow-right-disabled.png';
import settingsModalSwitchBackgroundOff from '../assets/modal/switch-background-off.png';
import settingsModalSwitchBackgroundOn from '../assets/modal/switch-background-on.png';
import settingsModalSwitchBulletOff from '../assets/modal/switch-bullet-off.png';
import settingsModalSwitchBulletOn from '../assets/modal/switch-bullet-on.png';
import autoPlayModalLogo from '../assets/modal/auto-play-modal-logo.png';
import autoPlayModalSecondaryBackground from '../assets/modal/auto-play-modal-secondary-background.svg';
import settingsModalAudio from '../assets/modal/icon-audio.svg';
import settingsModalVisualEffects from '../assets/modal/icon-visual-effects.svg';
import settingsModalAditionalOptions from '../assets/modal/icon-aditional-options.svg';
import betModalLogo from '../assets/modal/bet-modal-logo.png';
import number0 from '../assets/modal/number0.png';
import number1 from '../assets/modal/number1.png';
import number2 from '../assets/modal/number2.png';
import number3 from '../assets/modal/number3.png';
import number4 from '../assets/modal/number4.png';
import number5 from '../assets/modal/number5.png';
import number6 from '../assets/modal/number6.png';
import number7 from '../assets/modal/number7.png';
import number8 from '../assets/modal/number8.png';
import number9 from '../assets/modal/number9.png';
import euro from '../assets/modal/euro.png';
import dollar from '../assets/modal/dollar.png';
import dot from '../assets/modal/dot.png';
import {CDN_BASE_URL} from "../Game";

export const modalLoader = (app: Application) => {
    app.loader
        .add('arrowLeftDefault', arrowLeftDefault)
        .add('arrowLeftHover', arrowLeftHover)
        .add('arrowLeftDisabled', arrowLeftDisabled)
        .add('arrowRightDefault', arrowRightDefault)
        .add('arrowRightHover', arrowRightHover)
        .add('arrowRightDisabled', arrowRightDisabled)
        .add('settingsModalSwitchBackgroundOff', settingsModalSwitchBackgroundOff)
        .add('settingsModalSwitchBackgroundOn', settingsModalSwitchBackgroundOn)
        .add('settingsModalSwitchBulletOff', settingsModalSwitchBulletOff)
        .add('settingsModalSwitchBulletOn', settingsModalSwitchBulletOn)
        .add('autoPlayModalLogo', autoPlayModalLogo)
        .add('autoPlayModalSecondaryBackground', autoPlayModalSecondaryBackground)
        .add('settingsModalAudio', settingsModalAudio)
        .add('settingsModalVisualEffects', settingsModalVisualEffects)
        .add('settingsModalAditionalOptions', settingsModalAditionalOptions)
        .add('popUpFinish', `${CDN_BASE_URL}/spine/win_modals/pop_up_finish_desktop.json`)
        .add('popUpStart', `${CDN_BASE_URL}/spine/win_modals/pop_up_start_desktop.json`)
        .add('betModalLogo', betModalLogo)
        .add('number0', number0)
        .add('number1', number1)
        .add('number2', number2)
        .add('number3', number3)
        .add('number4', number4)
        .add('number5', number5)
        .add('number6', number6)
        .add('number7', number7)
        .add('number8', number8)
        .add('number9', number9)
        .add('euro', euro)
        .add('dollar', dollar)
        .add('dot', dot);

};
