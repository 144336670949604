import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
	Button,
	ButtonProps,
	createPolymorphicComponent,
	PasswordInput,
	TextInput,
} from '@mantine/core';
import {
	respondHeightFrom,
} from './mixins';

export const Wrapper = styled.main`
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Form = styled.form`
	position: relative;
	width: 300px;
	padding: 35px 25px 25px;
	background: #fafafa;
	border: 1px solid #ebebeb;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px,
		rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
	${respondHeightFrom.base`
		padding: 15px 15px 15px;
	`};
`;

export const UpdateForm = styled.form`
	width: 400px;
	padding: 35px 25px 25px;
	background: #fafafa;
	border: 1px solid #ebebeb;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px,
		rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
`;

export const FormTitle = styled.h2`
	font-family: 'Rubik', sans-serif;
	text-align: center;
	padding-bottom: 20px;
	color: #212529;
	font-size: 28px;
	font-weight: 700;
	${respondHeightFrom.base`
		font-size: 24px;
		padding-bottom: 10px;
	`};
`;

export const FormError = styled.div`
	top: 70px;
	position: absolute;
	color: #fa5252;
`;

export const FormPasswordInput = styled(PasswordInput)`
	flex: auto;
	position: relative;
	height: 58px;
	input {
		font-family: 'Rubik', sans-serif;
		padding: 0;
	}
	.mantine-InputWrapper-error {
		font-family: 'Rubik', sans-serif;
		position: absolute;
		bottom: 2;
		left: 0;
	}
	.mantine-PasswordInput-input {
		font-family: 'Rubik', sans-serif;
		padding: 0 10px 0 0;
		height: 32px;
		border: none;
		border-radius: 0;
		background: transparent;
		border-bottom: 1px solid #ced4da;
		font-size: 14px;
		font-weight: 400;
		color: #212529;
	}
`;

export const FormInput = styled(TextInput)`
	position: relative;
	height: 58px;
	flex: auto;
	.mantine-InputWrapper-error {
		font-family: 'Rubik', sans-serif;
		position: absolute;
		bottom: 2;
		left: 0;
	}
	input {
		font-family: 'Rubik', sans-serif;
		padding: 0;
		height: 32px;
		border: none;
		border-radius: 0;
		background: transparent;
		border-bottom: 1px solid #ced4da;
		font-size: 14px;
		font-weight: 400;
		color: #212529;
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const LinkWrapper = styled.div`
	padding-top: 15px;
	text-align: center;
	span {
		font-family: 'Rubik', sans-serif;
		color: #212529;
		font-size: 14px;
		padding-right: 5px;
	}
`;

export const StyledLink = styled(Link)`
	font-family: 'Rubik', sans-serif;
	color: #212529;
	font-size: 14px;
	font-weight: 700;
	text-decoration: underline;
	:hover {
		opacity: 0.8;
		transition: all ease 0.5s;
	}
`;

const _SubmitButton = styled(Button)`
	font-family: 'Rubik', sans-serif;
	width: 100%;
`;

export const SubmitButton = createPolymorphicComponent<'button', ButtonProps>(
	_SubmitButton
);
