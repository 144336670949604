import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import {
	autoPlayItemFooterTitle,
	switchButtonLabel,
} from '../../../../styles/pixiStyles';
import { PixiGame } from '../../index';
import CommonButton from './CommonButton';

class Limit {
	app: Application;
	game: PixiGame;
	buttons?: any[];
	container: PIXI.Container;
	background: PIXI.Sprite;
	title: PIXI.Text;
	subTitle: PIXI.Text;
	noLimit?: CommonButton;
	limit5x?: CommonButton;
	limit10x?: CommonButton;
	limit20x?: CommonButton;
	limit50x?: CommonButton;
	limit100x?: CommonButton;

	constructor(game: PixiGame, app: Application, label: string) {
		this.game = game;
		this.app = app;
		this.buttons = [];
		this.container = new PIXI.Container();
		this.background = new PIXI.Sprite(
			this.app.loader.resources['autoPlayModalSecondaryBackground'].texture!
		);
		this.title = new PIXI.Text(label, autoPlayItemFooterTitle);
		this.subTitle = new PIXI.Text(
			'Expressed as multiplier of total bet',
			switchButtonLabel
		);
	}

	initialize = async () => {
		await this.setupButtons();
		this.container.width = 288;
		this.container.height = 89;

		this.background.width = 288;
		this.background.height = 30;

		this.title.x = 288 / 2 - this.title.width / 2;
		this.title.y = 9.5;

		this.subTitle.x = 288 / 2 - this.subTitle.width / 2;
		this.subTitle.y = 38;

		this.container.addChild(this.background!);
		this.background.addChild(this.title!);
		this.background.addChild(this.subTitle!);
	};

	setupButtons = async () => {
		this.noLimit = new CommonButton(this.game, this.app, 'NO\nLIMIT');
		this.limit5x = new CommonButton(this.game, this.app, '5X');
		this.limit10x = new CommonButton(this.game, this.app, '10X');
		this.limit20x = new CommonButton(this.game, this.app, '20X');
		this.limit50x = new CommonButton(this.game, this.app, '50X');
		this.limit100x = new CommonButton(this.game, this.app, '100X');

		await this.noLimit.initialize();
		await this.limit5x.initialize();
		await this.limit10x.initialize();
		await this.limit20x.initialize();
		await this.limit50x.initialize();
		await this.limit100x.initialize();

		this.buttons?.push(this.noLimit.button);
		this.buttons?.push(this.limit5x.button);
		this.buttons?.push(this.limit10x.button);
		this.buttons?.push(this.limit20x.button);
		this.buttons?.push(this.limit50x.button);
		this.buttons?.push(this.limit100x.button);

		this.noLimit.button.x = 0;
		this.noLimit.button.y = 57;
		this.limit5x.button.x = 49;
		this.limit5x.button.y = 57;
		this.limit10x.button.x = 98;
		this.limit10x.button.y = 57;
		this.limit20x.button.x = 147;
		this.limit20x.button.y = 57;
		this.limit50x.button.x = 196;
		this.limit50x.button.y = 57;
		this.limit100x.button.x = 245;
		this.limit100x.button.y = 57;

		this.buttons?.forEach(btn => {
			this.background.addChild(btn);
		});
	};
}

export default Limit;
