import * as PIXI from 'pixi.js';
import {Application} from 'pixi.js';
import {PixiGame} from '../../index';
import {valueStyles} from './style';
import {ITrackEntry, Spine} from 'pixi-spine';

class WinModal {
    app: Application;
    game: PixiGame;
    modalWrapper: PIXI.Container;
    windowBackground: PIXI.Graphics;
    modal: PIXI.Container;
    background: PIXI.Graphics;
    value: PIXI.Text;
    winScreens: Spine[];
    megaWin: Spine;
    bigWin: Spine;
    superWin: Spine;
    displayType: 'desktop' | 'portrait';
    winType: 'Big Win' | 'Super Win' | 'Mega Win' | '';
    winSpineType?: Spine;
    count: any;
    currency: string;
    amount: number;
    canCloseModal: boolean;
    valueAnimIsFinished: boolean;
    bigWinCoins: Spine;
    superWinCoins: Spine;
    megaWinCoins: Spine;
    winScreensCoins: Spine[];
    lastModalIsOpen: boolean;

    constructor(game: PixiGame, app: Application) {
        this.game = game;
        this.app = app;
        this.displayType = 'desktop';
        this.winType = '';
        this.winScreens = [];
        this.modalWrapper = new PIXI.Container();
        this.modal = new PIXI.Container();
        this.background = new PIXI.Graphics();
        this.windowBackground = new PIXI.Graphics();
        this.value = new PIXI.Text('', valueStyles);
        this.superWin = new Spine(this.app.loader.resources['superWin'].spineData!);
        this.bigWin = new Spine(this.app.loader.resources['bigWin'].spineData!);
        this.megaWin = new Spine(this.app.loader.resources['megaWin'].spineData!);
        this.count = 0;
        this.currency = "$";
        this.amount = 0;
        this.canCloseModal = false;
        this.valueAnimIsFinished = false;
        this.winScreensCoins = [];
        this.bigWinCoins = new Spine(this.app.loader.resources['bigWinCoins'].spineData!);
        this.superWinCoins = new Spine(this.app.loader.resources['superWinCoins'].spineData!);
        this.megaWinCoins = new Spine(this.app.loader.resources['megaWinCoins'].spineData!);
        this.lastModalIsOpen = false;
    }

    initialize = async () => {
        this.winScreens.push(this.megaWin);
        this.winScreens.push(this.bigWin);
        this.winScreens.push(this.superWin);

        this.winScreensCoins.push(this.bigWinCoins);
        this.winScreensCoins.push(this.superWinCoins);
        this.winScreensCoins.push(this.megaWinCoins);

        this.modalWrapper.x = 0;
        this.modalWrapper.y = 0;
        this.modalWrapper.width = this.app.screen.width;
        this.modalWrapper.height = this.app.screen.height + 32;
        this.modalWrapper.visible = false;
        this.modalWrapper.zIndex = 12000;
        this.modal.x = 0;
        this.modal.y = 0;
        this.modal.width = 1920;
        this.modal.height = 1080;

        this.modal.sortableChildren = true;

        this.windowBackground.beginFill(0x151515);
        this.windowBackground.drawRect(
            0,
            0,
            this.app.screen.width,
            this.app.screen.height + 32
        );
        this.windowBackground.endFill();
        this.windowBackground.alpha = 0.8;

        this.background.beginFill(0x151515);
        this.background.drawRect(0, 0, 1920, 1080);
        this.background.alpha = 0;
        this.background.endFill();

        this.winScreensCoins.forEach(item => {
            item.x = 950;
            item.y = 1100;
            item.zIndex = 1;
            item.visible = false;
        });

        this.value.x = (1920 - this.value.width) / 2;
        this.value.y = 460;
        this.winScreens.forEach(item => {
            item.x = 950;
            item.y = 1055;
        });

        this.windowBackground.zIndex = 1;
        this.modal.zIndex = 2;
        this.superWin.zIndex = 2;
        this.bigWin.zIndex = 2;
        this.megaWin.zIndex = 2;
        this.value.zIndex = 3;

        this.app.stage.addChild(this.modalWrapper);
        this.modalWrapper.addChild(this.windowBackground!);
        this.modalWrapper.addChild(this.modal!);
        this.modal.addChild(this.value!);
        this.modal.addChild(this.superWin!);
        this.modal.addChild(this.bigWin!);
        this.modal.addChild(this.megaWin!);
        this.modal.addChild(this.background!);
        this.modal.addChild(this.bigWinCoins!);
    };

    setCloseModal = () => {
        if (this.canCloseModal) {
            this.modal.buttonMode = true;
            this.modal.interactive = true;
            this.modal
                .on('touchstart', () => this.closeModalOnClick())
                .on('click', () => this.closeModalOnClick());
        } else {
            this.modal.buttonMode = false;
            this.modal.interactive = false;
        }
    }

    startAnim = async (item: Spine, skipAnim?: boolean): Promise<boolean> => {
        return new Promise(resolve => {
            if (skipAnim && item !== this.megaWin) return resolve(true);
            item.state.setAnimation(0, '1_start', false);
            item.state.addListener({
                complete(entry: ITrackEntry) {
                    return resolve(true);
                },
            });
        });
    };

    continueAnim = async (item: Spine, skipAnim?: boolean): Promise<boolean> => {
        return new Promise(resolve => {
            if (skipAnim) return resolve(true);
            item.state.setAnimation(1, '2_animation', true);
            item.state.addListener({
                complete(entry: ITrackEntry) {
                    return resolve(true);
                },
            });
        });
    };

    endAnim = async (item: Spine, skipAnim?: boolean): Promise<boolean> => {
        this.lastModalIsOpen = false
        return new Promise(resolve => {
            if (skipAnim) return resolve(true);
            item.state.setAnimation(0, '3_finish', false);
            this.bigWinCoins.state.setAnimation(0, '1_big', false);
            this.superWinCoins.state.setAnimation(0, '3_super', false);
            this.megaWinCoins.state.setAnimation(0, '2_mega', false);
            item.state.addListener({
                complete(entry: ITrackEntry) {
                    return resolve(true);
                },
            });
        });
    };

    showAnimCoins = async (item: Spine, anim: string): Promise<boolean> => {
        return new Promise(resolve => {
            item.state.timeScale = 1.5;
            const timer = setTimeout(() => {
                item.visible = true;

                this.bigWinCoins.state.setAnimation(0, anim, true);
                clearTimeout(timer);
                return resolve(true);
            }, 100);
        });
    };

    showWinAnim = async (type: string, endAnim: boolean) => {
        let spine: Spine;
        if (type === 'Big Win') {
            spine = this.bigWin;
        } else if (type === 'Mega Win') {
            spine = this.megaWin;
        } else if (type === 'Super Win') {
            spine = this.superWin;
        }
        if(type === this.winType){
            this.lastModalIsOpen = true;
        }
        const coinsType = type === 'Super Win' ? this.superWinCoins : type === 'Big Win' ? this.bigWinCoins : this.megaWinCoins;
        const coinsAnim = type === 'Super Win' ? '2_mega' : type === 'Big Win' ? '1_big' : '3_super';
        spine!.visible = true;

        this.showAnimCoins(coinsType, coinsAnim);
        await this.startAnim(spine!, !this.canCloseModal);
        await this.continueAnim(spine!, !this.canCloseModal);
        if (endAnim) {
            await this.endAnim(spine!, !this.canCloseModal);
            spine!.visible = false;
        }
    }

	checkWinType = async () => {
		this.canCloseModal = true;
		const timer = setTimeout(() => {
			this.setCloseModal();
			clearTimeout(timer);
		}, 1);
		this.superWin.visible = false;
		this.bigWin.visible = false;
		this.megaWin.visible = false;
		const timer2 = setTimeout(() => {
			clearTimeout(timer2)
			this.game.soundController.win_counter?.play()
			this.setValue(this.currency, this.amount)
		}, 300)

		if (this.winType === 'Big Win') {
            this.winSpineType = this.bigWin;
            this.valueAnimIsFinished = false;
            await this.showWinAnim('Big Win', false);
            this.winSpineType!.visible = true;
        } else if (this.winType === 'Super Win') {
            this.winSpineType = this.superWin;
            this.valueAnimIsFinished = false;
            await this.showWinAnim('Big Win', true)
            this.bigWin!.visible = false;
            await this.showWinAnim('Super Win', false);
			this.winSpineType!.visible = true;
        } else if (this.winType === 'Mega Win') {
            this.winSpineType = this.megaWin;
            this.valueAnimIsFinished = false;
            await this.showWinAnim('Big Win', true)
            this.bigWin!.visible = false;
            await this.showWinAnim('Super Win', true)
            this.superWin!.visible = false;
            await this.showWinAnim('Mega Win', false);
            this.winSpineType!.visible = true;
        }
	};

    checkDisplayType = async (type: 'desktop' | 'portrait') => {
        this.windowBackground.width = window.innerWidth;
        this.windowBackground.height = window.innerHeight + 32;
        this.displayType = type;

        if (this.displayType === 'desktop') {
            this.modal.x = 0;
            this.modal.y = 0;
            this.winScreensCoins.forEach(item => {
                item.x = 950;
                item.y = 1100;
            });
            this.value.y = 460;
            this.winScreens.forEach(item => {
                item.x = 950;
                item.y = 1055;
            });
        } else if (this.displayType === 'portrait') {
            this.modal.x = -100;
            this.modal.y = 0;
            this.winScreensCoins.forEach(item => {
                item.x = 950;
                item.y = 1350;
            });
            this.value.y = 660;
            this.winScreens.forEach(item => {
                item.x = 950;
                item.y = 1250;
            });
        }
    };

    setValue = (
        currency: string,
        value: number,
    ) => {
        let animatedValue = 0;
        const counterSound = setInterval(() => {
            this.game.soundController.win_counter?.play();
        }, 850);
        const animValue = (start: number, end: number, duration: number) => {
			let startTimestamp: any = null;
			const step = (timestamp: any) => {
				if (!startTimestamp) startTimestamp = timestamp;
				let progress = Math.min((timestamp - startTimestamp) / duration, 1);
				animatedValue = Number((progress * (end - start) + start).toFixed(2));
				if ((progress < 1) && !this.valueAnimIsFinished) {
					window.requestAnimationFrame(step);
				} else {
                    animatedValue = value;
					clearInterval(counterSound);
                    const timer1 = setTimeout(() => {
                        this.game.soundController.win_counter?.stop();
                        this.game.soundController.win_counter_end?.play();
                        const closeModalTimer = setTimeout(() => {
                            this.closeModal();
                            clearTimeout(closeModalTimer);
                        }, 100);
                        clearTimeout(timer1);
                    }, 1);
				}
                this.value.text = `${currency} ${Number(animatedValue).toFixed(2)}`;
				this.value.x = (1920 - this.value.width) / 2;
			};
			window.requestAnimationFrame(step);
		};
		animValue(0, value, 15360);
    };

    hideModalContainer = async () => {
        this.lastModalIsOpen = false
        this.modal.buttonMode = false;
        this.modal.interactive = false;
        // this.bigWinCoins.state.setAnimation(0, '1_big', false);
        // this.superWinCoins.state.setAnimation(0, '3_super', false);
        // this.megaWinCoins.state.setAnimation(0, '2_mega', false);
        if(this.game.assetsController?.isFs){
            this.game.soundController.backgroundFSSound?.play();
        }
        this.value.text = "";
        this.modal.visible = false;
        this.valueAnimIsFinished = false;
        await this.showModalContainerTimer(false);
    }

    openModal = (winType: 'Big Win' | 'Super Win' | 'Mega Win', currency: string, value: number) => {
        this.modalWrapper.visible = true;
        this.modal.visible = false;

        const showModalContatierTimer = setTimeout(() => {
            clearTimeout(showModalContatierTimer);
            this.modal.visible = true;
            this.currency = currency
            this.amount = value
            this.winType = winType;
            this.game.soundController.sfx_hw_start?.play();
            this.game.soundController.backgroundFSSound?.stop();
            this.game.soundController.bg_bigWin?.play();
            this.checkWinType();
            this.game.gameContainer.interactiveChildren = false;
        }, 500);
    };

    closeModal = () => {
		this.canCloseModal = false;
		this.setCloseModal();
		const timer = setTimeout(async() => {
            this.winSpineType!.visible = true;
            if(this.winSpineType === this.megaWin){
                this.bigWin!.visible = false;
                this.superWin!.visible = false;
                if(this.lastModalIsOpen === false){
                    await this.startAnim(this.winSpineType!);
                }
                await this.continueAnim(this.winSpineType!);
			    await this.endAnim(this.winSpineType!);
            } else if(this.winSpineType === this.superWin){
                this.bigWin!.visible = false;
                if(this.lastModalIsOpen === false){
                    await this.startAnim(this.winSpineType!);
                }
                await this.continueAnim(this.winSpineType!);
			    await this.endAnim(this.winSpineType!);
            } else if(this.winSpineType === this.bigWin){
                await this.continueAnim(this.winSpineType!);
			    await this.endAnim(this.winSpineType!);
            }
			this.game.soundController.bg_bigWin?.stop();
		 	this.game.soundController.sfx_hw_end?.play();
			await this.hideModalContainer()
		 	clearTimeout(timer);
		}, 500);
	};

	closeModalOnClick = () => {
		this.valueAnimIsFinished = true;
		// this.canCloseModal = false;
		this.modal.buttonMode = false;
		this.modal.interactive = false;
	};

    changeWinModalScale(scalingX: number, scalingY: number) {
        if (!this.modal) return;
        this.modal.scale.x = scalingX;
        this.modal.scale.y = scalingY;
    }

    changeWinModalPosition(x: number, y: number) {
        if (!this.modal) return;
        this.modal.x = x;
        this.modal.y = y;
    }

    showModalContainerTimer = async (state: boolean): Promise<boolean> => {
        return new Promise(resolve => {
            const timer = setTimeout(() => {
                this.modalWrapper.visible = state;
                this.game.gameContainer.interactiveChildren = !state;
                clearTimeout(timer);
                resolve(true);
            }, 500);
        });
    };
}

export default WinModal;

