import { IInfoBar, IUser } from '../../../api/types/auth';

export interface AuthState {
	isAuth: boolean;
	user: IUser;
	info: IInfoBar;
	bet: number;
	totalBet: any[];
	isLoading: boolean;
	error: string;
}

export enum AuthActionEnum {
	SET_AUTH = 'SET_AUTH',
	SET_BET = 'SET_BET',
	SET_USER = 'SET_USER',
	SET_INFO_BAR = 'SET_INFO_BAR',
	SET_TOTAL_BET = 'SET_TOTAL_BET',
	SET_IS_LOADING = 'SET_IS_LOADING',
	SET_ERROR = 'SET_ERROR',
}

export interface SetAuthAction {
	type: AuthActionEnum.SET_AUTH;
	payload: boolean;
}

export interface SetUserAction {
	type: AuthActionEnum.SET_USER;
	payload: IUser;
}

export interface SetInfoBarAction {
	type: AuthActionEnum.SET_INFO_BAR;
	payload: IInfoBar;
}

export interface SetTotalBetAction {
	type: AuthActionEnum.SET_TOTAL_BET;
	payload: number[];
}
export interface SetBetAction {
	type: AuthActionEnum.SET_BET;
	payload: number;
}

export interface SetIsLoadingAction {
	type: AuthActionEnum.SET_IS_LOADING;
	payload: boolean;
}

export interface SetErrorAction {
	type: AuthActionEnum.SET_ERROR;
	payload: string;
}

export type AuthAction =
	| SetAuthAction
	| SetUserAction
	| SetBetAction
	| SetInfoBarAction
	| SetIsLoadingAction
	| SetTotalBetAction
	| SetErrorAction;
