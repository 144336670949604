import { FC } from 'react';
import {
	Form,
	FormError,
	FormInput,
	FormPasswordInput,
	FormTitle,
	LinkWrapper,
	StyledLink,
	SubmitButton,
	Wrapper,
} from '../../styles/formStyles';
import { RouteNames } from '../../router';
import { useAuth } from './hooks/useAuth';
import { useForm } from '@mantine/form';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useUnmount } from '../../hooks/useUnmount';
import { useActions } from '../../hooks/useActions';
import Header from '../../components/Header/Header';

const Login: FC = () => {
	const { handleLogin } = useAuth();
	const { error } = useTypedSelector(state => state.auth);
	const { setError } = useActions();

	useUnmount(() => {
		setError('');
	});

	const form = useForm({
		initialValues: {
			token: '',
		},
		validate: {
			token: value => (/^\S+$/.test(value) ? null : 'Invalid token'),
		},
	});

	return (
		<>
			<Header />
			<Wrapper>
				<Form onSubmit={form.onSubmit(handleLogin)}>
					<FormTitle>Login</FormTitle>
					{error && <FormError>{error}</FormError>}
					<FormInput placeholder='Token' {...form.getInputProps('token')} />
					<SubmitButton type='submit' mt='sm'>
						Login
					</SubmitButton>
					{/*<LinkWrapper>*/}
					{/*  <span>No account?</span>*/}
					{/*  <StyledLink to={RouteNames.REGISTRATION}>Create</StyledLink>*/}
					{/*</LinkWrapper>*/}
				</Form>
			</Wrapper>
		</>
	);
};

export default Login;
