import { PixiGame } from '../index';
import { Application } from 'pixi.js';

export class AdaptiveComponent {
    game: PixiGame;
    app: Application;
    leftHandMode: boolean;

    constructor(game: PixiGame, app: Application) {
        this.game = game;
        this.app = app;
        this.leftHandMode = false;
    }

    setupAllComponents = async () => {
        await this.resizeModal();
        await this.resizeToPortrait();
        await this.resizeToDesktop1();
        await this.resizeToDesktop2();
        await this.resizeToLandscape();
        await this.resizeToLandscapeMobile();
    };

    resizeModal = () => {
        let scaleByWidth = 100 / 1920 * window.innerWidth / 100;
        let scaleByHeight = 100 / 1080 * window.innerHeight / 100;
        if (scaleByWidth < scaleByHeight) {
            this.game.assetsController?.buyBonusModal?.changeBuyBonusModalScale(scaleByWidth, scaleByWidth);
            this.game.assetsController?.buyBonusModal?.changeBuyBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyBonusModal?.modal.width / 2, 30);
            this.game.assetsController?.buyMaxBonusModal?.changeBuyMaxBonusModalScale(scaleByWidth, scaleByWidth);
            this.game.assetsController?.buyMaxBonusModal?.changeBuyMaxBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyMaxBonusModal?.modal.width / 2, 30);
            this.game.assetsController?.buyMinBonusModal?.changeBuyMinBonusModalScale(scaleByWidth, scaleByWidth);
            this.game.assetsController?.buyMinBonusModal?.changeBuyMinBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyMinBonusModal?.modal.width / 2, 30);
            this.game.assetsController?.winModal?.changeWinModalScale(scaleByWidth, scaleByWidth);
            this.game.assetsController?.winModal?.changeWinModalPosition(window.innerWidth / 2 - this.game.assetsController.winModal.modal.width / 2, 0);
            this.game.assetsController?.winModal?.checkDisplayType('desktop')
            this.game.assetsController!.winModal!.value.x = (1920 / 2 - this.game.assetsController!.winModal!.value.width / 2);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishScale(scaleByWidth, scaleByWidth);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2, window.innerHeight / 2)
            this.game.assetsController?.winStartPopUp?.changeWinStartScale(scaleByWidth, scaleByWidth);
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2, window.innerHeight / 2)
        } else {
            this.game.assetsController?.buyBonusModal?.changeBuyBonusModalScale(scaleByHeight, scaleByHeight);
            this.game.assetsController?.buyBonusModal?.changeBuyBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyBonusModal?.modal.width / 2, 30);
            this.game.assetsController?.buyMaxBonusModal?.changeBuyMaxBonusModalScale(scaleByHeight, scaleByHeight);
            this.game.assetsController?.buyMaxBonusModal?.changeBuyMaxBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyMaxBonusModal?.modal.width / 2, 30);
            this.game.assetsController?.buyMinBonusModal?.changeBuyMinBonusModalScale(scaleByHeight, scaleByHeight);
            this.game.assetsController?.buyMinBonusModal?.changeBuyMinBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyMinBonusModal?.modal.width / 2, 30);
            this.game.assetsController?.winModal?.changeWinModalScale(scaleByHeight, scaleByHeight);
            this.game.assetsController?.winModal?.changeWinModalPosition(window.innerWidth / 2 - this.game.assetsController.winModal.modal.width / 2, 0);
            this.game.assetsController!.winModal!.value.x = (1920 / 2 - this.game.assetsController!.winModal!.value.width / 2);
            this.game.assetsController?.winModal?.checkDisplayType('desktop')
            this.game.assetsController?.winFinishPopUp?.changeWinFinishScale(scaleByHeight, scaleByHeight);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2, window.innerHeight / 2)
            this.game.assetsController?.winStartPopUp?.changeWinStartScale(scaleByHeight, scaleByHeight);
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2, window.innerHeight / 2)
        }
        this.game.assetsController?.winFinishPopUp?.windowBackground.drawRect(0, 0, this.app.screen.width, this.app.screen.height + 32);
        this.game.assetsController?.winStartPopUp?.windowBackground.drawRect(0, 0, this.app.screen.width, this.app.screen.height + 32);
        this.game.assetsController?.changeSize(
            this.game.assetsController!.buyBonusModalPortrait!.windowBackground,
            window.innerWidth,
            window.innerHeight
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController!.buyMinBonusModalPortrait!.windowBackground,
            window.innerWidth,
            window.innerHeight
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController!.buyMaxBonusModalPortrait!.windowBackground,
            window.innerWidth,
            window.innerHeight
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController!.buyBonusModal!.windowBackground,
            window.innerWidth,
            window.innerHeight
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController!.buyMinBonusModal!.windowBackground,
            window.innerWidth,
            window.innerHeight
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController!.buyMaxBonusModal!.windowBackground,
            window.innerWidth,
            window.innerHeight
        );

        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);
        this.game.assetsController?.buyBonusModal?.modalWrapper.scale.set(1);
        this.game.assetsController?.buyMinBonusModal?.modalWrapper.scale.set(1);
        this.game.assetsController?.buyMaxBonusModal?.modalWrapper.scale.set(1);
        this.game.assetsController?.buyBonusModalPortrait?.modalWrapper.scale.set(0);
        this.game.assetsController?.buyMinBonusModalPortrait?.modalWrapper.scale.set(0);
        this.game.assetsController?.buyMaxBonusModalPortrait?.modalWrapper.scale.set(0);
    }

    resizeToPortrait = () => {
        this.game.gameContainer.scale.x = (window.innerWidth / 1920) * 0.97;
        this.game.gameContainer.scale.y = this.game.gameContainer.scale.x * 2.9;
        this.game.gameContainer.x =
            (window.innerWidth - this.game.gameContainer.width) / 2;
        this.game.gameContainer.y = 10;

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBar,
            false
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.buyBonusButton?.button,
            279,
            93
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.doubleBonusChanceButton?.button,
            279,
            93
        );

        if (this.leftHandMode) {
            this.game.assetsController?.changePosition(
                this.game.assetsController?.buyBonusButton?.button,
                1760,
                628
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.doubleBonusChanceButton?.button,
                1425,
                628
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.autoPlayButton?.button,
                485,
                857
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.betButton?.button,
                1435,
                857
            );
        } else {
            this.game.assetsController?.changePosition(
                this.game.assetsController?.buyBonusButton?.button,
                515,
                628
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.doubleBonusChanceButton?.button,
                180,
                628
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.autoPlayButton?.button,
                1435,
                857
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.betButton?.button,
                485,
                857
            );
        }

        if (this.game.assetsController?.isFs) {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                true
            );
            this.game.assetsController.changeVisibility(
                this.game.assetsController.buyBonusButton?.button,
                false
            );
            this.game.assetsController.changeVisibility(
                this.game.assetsController.doubleBonusChanceButton?.button,
                false
            );
        } else {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.buyBonusButton?.button,
                true
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.doubleBonusChanceButton?.button,
                true
            );
        }

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobilePortret,
            true
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.settingsButton?.button,
            965,
            990
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.settingsButton?.button,
            true
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.settingsButton?.button,
            255,
            85
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.autoPlayButton?.button,
            255,
            85
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.playButton?.button,
            965,
            867
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.playButton?.button,
            405,
            135
        );

        this.game.assetsController?.changeVisibility(
            this.game.assetsController?.betButton?.button,
            true
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.betButton?.button,
            255,
            85
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.logo,
            740,
            -5
        );
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logo, false);
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, true);

        let scaleByWidth = 100 / 500 * window.innerWidth / 100;
        let scaleByHeight = 100 / 600 * window.innerHeight / 100;
        if (scaleByWidth < scaleByHeight) {
            this.game.assetsController?.logoPortrait?.scale.set(scaleByWidth / 2);
            this.game.assetsController?.changePosition(
                this.game.assetsController?.logoPortrait, window.innerWidth / 2 - this.game.assetsController!.logoPortrait!.width / 2, 1);
        } else {
            this.game.assetsController?.logoPortrait?.scale.set(scaleByHeight / 2);
            this.game.assetsController?.changePosition(
                this.game.assetsController?.logoPortrait, window.innerWidth / 2 - this.game.assetsController!.logoPortrait!.width / 2, 1);
        }

        this.game.assetsController?.buyBonusModalPortrait?.changeBuyBonusModalPortraitScale(scaleByWidth * 1.5, scaleByWidth * 1.5);
        this.game.assetsController?.buyBonusModalPortrait?.changeBuyBonusModalPortraitPosition(window.innerWidth / 2 - this.game.assetsController.buyBonusModalPortrait.modal.width / 2, 14);

        this.game.assetsController?.buyMaxBonusModalPortrait?.changeBuyMaxBonusModalPortraitScale(scaleByWidth * 1.5, scaleByWidth * 1.5);
        this.game.assetsController?.buyMaxBonusModalPortrait?.changeBuyMaxBonusModalPortraitPosition(window.innerWidth / 2 - this.game.assetsController.buyMaxBonusModalPortrait.modal.width / 2, 14);

        this.game.assetsController?.buyMinBonusModalPortrait?.changeBuyMinBonusModalPortraitScale(scaleByWidth * 1.5, scaleByWidth * 1.5);
        this.game.assetsController?.buyMinBonusModalPortrait?.changeBuyMinBonusModalPortraitPosition(window.innerWidth / 2 - this.game.assetsController.buyMinBonusModalPortrait.modal.width / 2, 14);

        this.game.assetsController?.buyBonusModalPortrait?.modalWrapper.scale.set(1);
        this.game.assetsController?.buyMinBonusModalPortrait?.modalWrapper.scale.set(1);
        this.game.assetsController?.buyMaxBonusModalPortrait?.modalWrapper.scale.set(1);

        this.game.assetsController?.buyBonusModal?.modalWrapper.scale.set(0);
        this.game.assetsController?.buyMinBonusModal?.modalWrapper.scale.set(0);
        this.game.assetsController?.buyMaxBonusModal?.modalWrapper.scale.set(0);

        this.game.assetsController?.reelsContainer?.changeContainerPosition(50, 55);
        this.game.assetsController?.changeContainerScaling(1.545, 0.5);
        this.game.assetsController?.changeVisibility(
            this.game.assetsController?.infoBarMobile,
            false
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.infoBarMobilePortret,
            -10,
            685
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.infoBarMobilePortret,
            2000,
            127
        );

        this.game.assetsController?.settingsModal?.changeSettingsModalScale(6, 2);
        this.game.assetsController?.settingsModal?.changeSettingsModalPosition((this.game.assetsController.reelsContainer!.container.width - this.game.assetsController.settingsModal.modal.width) / 1.4, 50);

        this.game.assetsController?.changeMultiplierMobileScale(1.8, 0.85)

        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalScale(6, 2);
        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalPosition((this.game.assetsController.reelsContainer!.container.width - this.game.assetsController.autoPlayModal.modal.width) / 1.4, 50);

        this.game.assetsController?.betModal?.changeBetModalScale(6, 2);
        this.game.assetsController?.betModal?.changeBetModalPosition((this.game.assetsController.reelsContainer!.container.width - this.game.assetsController.betModal.modal.width) / 1.4, 50);
        this.game.gameContainer!.x = 0;
        if (window.innerHeight > 1000) {

            this.game.gameContainer.scale.x = (window.innerWidth / 1920) * 0.75;
            this.game.gameContainer.scale.y = this.game.gameContainer.scale.x * 2.9;
            this.game.gameContainer.x =
                (window.innerWidth - this.game.gameContainer.width) / 2;
            this.game.gameContainer.y = 30;

            this.game.assetsController?.winStartPopUp?.changeWinStartScale(0.65,0.65);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishScale(0.65,0.65);
            this.game.assetsController?.changeSize(
                this.game.assetsController?.infoBarMobilePortret,
                2600,
                150
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.infoBarMobilePortret,
                -300,
                678
            );
        } else {
            let scaleByWidthPortrait = 100 / 1920 * window.innerWidth / 100;
            let scaleByHeightPortrait = 100 / 1080 * window.innerHeight / 100;
            this.game.assetsController?.winModal?.changeWinModalScale(scaleByWidthPortrait * 1.5, scaleByWidthPortrait * 1.5);
            this.game.assetsController?.winModal?.changeWinModalPosition(window.innerWidth / 2 - this.game.assetsController.winModal.modal.width / 2, 0);
            this.game.assetsController?.winStartPopUp?.changeWinStartScale(0.35,0.35);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishScale(0.35,0.35);
            this.game.assetsController?.winModal?.checkDisplayType('portrait')
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2, window.innerWidth / 1.25)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2, window.innerWidth / 1.25)
        }

        if(this.game.slotMachine?.rootContainer) {
            this.game.slotMachine.rootContainer.scale.set(1.545, 0.53)
        }

        if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === true){
            this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
            this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(false);
        }
    };

    resizeToDesktop1 = () => {
        this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 - 20, window.innerHeight / 2 + 220)
        this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 - 20, window.innerHeight / 2 + 220)
        this.game.gameContainer.y = 10;
        this.game.assetsController?.changeSize(
            this.game.assetsController?.infoBarMobilePortret,
            1920,
            127
        );
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);
        this.game.assetsController?.settingsModal?.changeSettingsModalScale(0, 0);
        this.game.assetsController?.betModal?.changeBetModalScale(0, 0);

        this.game.assetsController?.changeVisibility(
            this.game.assetsController?.infoBar,
            true
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController?.infoBarMobile,
            true
        );

        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalScale(1.5, 1.5);
        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalPosition(this.game.assetsController.reelsContainer?.container!.x! + this.game.assetsController.reelsContainer?.container.width! / 2 - this.game.assetsController.autoPlayModal?.modal.width / 1.7, 50);
        this.game.assetsController?.changePosition(
            this.game.assetsController?.buyBonusButton?.button,
            190,
            406
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.buyBonusButton?.button,
            220,
            220
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.doubleBonusChanceButton?.button,
            190,
            662
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.doubleBonusChanceButton?.button,
            220,
            220
        );

        this.game.assetsController?.changeMultiplierScale(1, 1);
        this.game.assetsController?.changeMultiplierPosition(250, 1000);

        if (this.game.assetsController?.isFs) {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                true
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController.changeVisibility(
                this.game.assetsController.buyBonusButton?.button,
                false
            );
            this.game.assetsController.changeVisibility(
                this.game.assetsController.doubleBonusChanceButton?.button,
                false
            );
        } else {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.buyBonusButton?.button,
                true
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.doubleBonusChanceButton?.button,
                true
            );
        }


        // this.game.gameContainer!.x = window.innerWidth - (this.game.gameContainer.width / 2);
        this.game.assetsController?.changePosition(
            this.game.assetsController?.infoBar,
            473,
            938
        );

        this.game.assetsController?.changeVisibility(
            this.game.assetsController?.settingsButton?.button,
            false
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.settingsButton?.button,
            190,
            190
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.autoPlayButton?.button,
            1731,
            749
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.autoPlayButton?.button,
            120,
            120
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.playButton?.button,
            1726,
            488
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.playButton?.button,
            220,
            220
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.logo,
            88,
            14
        );
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);
        this.game.assetsController?.reelsContainer?.changeContainerPosition(398, -6);
        this.game.assetsController?.changeContainerScaling(0.95, 0.95);
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.betButton?.button,
            false
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobile,
            false
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobilePortret,
            false
        );
        if (window.innerWidth / window.innerHeight <= 2) {
            this.game.gameContainer.x =
                (window.innerWidth - this.game.gameContainer.width) / 4;
            this.game.gameContainer.y = 0;
        } else if (window.innerWidth / window.innerHeight <= 2.5) {
            this.game.gameContainer.x =
                (window.innerWidth - this.game.gameContainer.width) / 3;
            this.game.gameContainer.y = 0;
        } else if (window.innerWidth / window.innerHeight <= 3) {
            this.game.gameContainer.x =
                (window.innerWidth - this.game.gameContainer.width) / 2;
            this.game.gameContainer.y = 0;
        }

        if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === true){
            this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
            this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(false);
        }
        this.game.assetsController?.winModal?.changeWinModalPosition(100, 0);
    };

    resizeToDesktop2 = () => {
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);
        if (window.innerHeight > 710) {
            this.game.gameContainer.x =
                (window.innerWidth - this.game.gameContainer.width) / 10;
        } else {
            this.game.gameContainer.x =
                (window.innerWidth - this.game.gameContainer.width) / 2;
        }

        this.game.gameContainer.y = 10;
        this.game.assetsController?.changeSize(
            this.game.assetsController.infoBarMobilePortret,
            1920,
            127
        );

        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalScale(1.5, 1.5);
        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalPosition(this.game.assetsController.reelsContainer?.container!.x! + this.game.assetsController.reelsContainer?.container.width! / 2 - this.game.assetsController.autoPlayModal?.modal.width / 1.7, 50);

        this.game.assetsController?.changePosition(
            this.game.assetsController?.buyBonusButton?.button,
            190,
            406
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.buyBonusButton?.button,
            220,
            220
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.doubleBonusChanceButton?.button,
            190,
            662
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.doubleBonusChanceButton?.button,
            220,
            220
        );
        this.game.assetsController?.changeMultiplierScale(1, 1);
        this.game.assetsController?.changeMultiplierPosition(240, 1000);

        if (this.game.assetsController?.isFs) {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                true
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.buyBonusButton?.button,
                false
            )
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.doubleBonusChanceButton?.button,
                false
            )
        } else {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.buyBonusButton?.button,
                true
            )
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.doubleBonusChanceButton?.button,
                true
            )
        }

        this.game.assetsController?.settingsModal?.changeSettingsModalScale(0, 0);
        this.game.assetsController?.betModal?.changeBetModalScale(0, 0);

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBar,
            true
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobile,
            true
        );
        if (window.innerHeight > 475) {
            this.game.assetsController?.changePosition(
                this.game.assetsController?.infoBar,
                473,
                938
            );
        } else {
            this.game.assetsController?.changePosition(
                this.game.assetsController?.infoBar,
                473,
                938
            );
        }
        this.game.assetsController?.changeVisibility(
            this.game.assetsController?.settingsButton?.button,
            false
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.settingsButton?.button,
            190,
            190
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.autoPlayButton?.button,
            1731,
            749
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.autoPlayButton?.button,
            120,
            120
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.playButton?.button,
            1726,
            488
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.playButton?.button,
            220,
            220
        );

        this.game.assetsController?.changePosition(
            this.game.assetsController?.logo,
            88,
            14
        );
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);
        this.game.assetsController?.reelsContainer?.changeContainerPosition(398, -6);
        if (window.innerHeight > 475) {
            this.game.assetsController?.changeContainerScaling(0.95, 0.95);
        } else this.game.assetsController?.changeContainerScaling(0.93, 0.93);

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.betButton?.button,
            false
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobile,
            false
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobilePortret,
            false
        );
        if (window.innerHeight > 1600) {
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2, window.innerHeight / 2 + 200)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2, window.innerHeight / 2 + 200)
        } else if (window.innerHeight > 1460) {
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2, window.innerHeight / 2 + 150)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2, window.innerHeight / 2 + 150)
        } else {
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2, window.innerHeight / 2 + 200)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2, window.innerHeight / 2 + 200)
        }
        if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === true){
            this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
            this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(false);
        }
    };

    resizeToLandscape = () => {
        this.game.assetsController?.winModal?.changeWinModalPosition(0, 0);

        this.game.assetsController?.buyBonusModal?.changeBuyBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyBonusModal?.modal.width / 2, 10);
        this.game.assetsController?.buyMaxBonusModal?.changeBuyMaxBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyMaxBonusModal?.modal.width / 2, 10);
        this.game.assetsController?.buyMinBonusModal?.changeBuyMinBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyMinBonusModal?.modal.width / 2, 10);
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);
        this.game.assetsController?.changeSize(
            this.game.assetsController?.infoBarMobilePortret,
            1920,
            127
        );
        this.game.assetsController?.settingsModal?.changeSettingsModalScale(2, 2);
        this.game.assetsController?.settingsModal?.changeSettingsModalPosition(
            615,
            200
        );

        this.game.assetsController?.betModal?.changeBetModalScale(2, 2);
        this.game.assetsController?.betModal?.changeBetModalPosition(615, 200);

        if (this.leftHandMode) {
            this.game.gameContainer.x = 0;
            this.game.assetsController?.changePosition(
                this.game.assetsController?.logo,
                1632,
                80
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.buyBonusButton?.button,
                1742,
                335
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.doubleBonusChanceButton?.button,
                1742,
                543
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.settingsButton?.button,
                1742,
                795
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.autoPlayButton?.button,
                213,
                158
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.playButton?.button,
                213,
                485
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.betButton?.button,
                213,
                795
            );
            this.game.assetsController?.changeMultiplierPosition(1753, 710);
            if (window.innerHeight < 305) {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(505, 70);
                this.game.gameContainer.y = -15;
           } else if (window.innerHeight < 700) {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(475, 45);
                this.game.gameContainer.y = -15;
            } else if (window.innerHeight < 800) {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(475, 45);
                this.game.gameContainer.y = -15;
            } else {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(475, 45);
                this.game.gameContainer.y = -15;
            }
        } else {
            this.game.gameContainer.x = 0;
            this.game.assetsController?.changePosition(
                this.game.assetsController?.buyBonusButton?.button,
                173,
                335
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.doubleBonusChanceButton?.button,
                173,
                543
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.settingsButton?.button,
                173,
                795
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.autoPlayButton?.button,
                1718,
                158
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.playButton?.button,
                1718,
                485
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.logo,
                70,
                80
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.betButton?.button,
                1718,
                795
            );
            this.game.assetsController?.changeMultiplierPosition(220, 710);
            if (window.innerHeight < 305) {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(430, 70);
                this.game.gameContainer.y = -15;
           } else if (window.innerHeight < 700) {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(400, 45);
                this.game.gameContainer.y = -15;
            } else if (window.innerHeight < 800) {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(400, 45);
                this.game.gameContainer.y = -15;
            } else {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(400, 45);
                this.game.gameContainer.y = -15;
            }
        }

        this.game.assetsController?.changeSize(
            this.game.assetsController?.buyBonusButton?.button,
            165,
            160
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.doubleBonusChanceButton?.button,
            165,
            160
        );
        this.game.assetsController?.changeMultiplierScale(0.60, 0.60);

        if (this.game.assetsController?.isFs) {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                true
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.buyBonusButton?.button,
                false
            )
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.doubleBonusChanceButton?.button,
                false
            )
        } else {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.buyBonusButton?.button,
                true
            )
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.doubleBonusChanceButton?.button,
                true
            )
        }

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBar,
            false
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.settingsButton?.button,
            180,
            180
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.settingsButton?.button,
            true
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.autoPlayButton?.button,
            200,
            200
        );

        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalScale(2, 2);
        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalPosition(615, 200);

        this.game.assetsController?.changeVisibility(this.game.assetsController.infoBar, false);

        this.game.assetsController?.changeSize(
            this.game.assetsController?.playButton?.button,
            290,
            290
        );

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.logo,
            true
        );
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.betButton?.button,
            true
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.betButton?.button,
            180,
            180
        );

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobile,
            true
        );
        this.game.assetsController!.infoBarMobile.scale.x = window.innerWidth / 1920 * 1.6;

        if (window.innerHeight < 305) {
            this.game.assetsController!.infoBarMobile.y = window.innerHeight - this.game.assetsController!.infoBarMobile.height * 1.3;
            this.game.assetsController?.changeContainerScaling(0.8, 0.8);
           this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 - 35, window.innerHeight / 2 + 50)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 - 35, window.innerHeight / 2 + 50)
        } else if (window.innerHeight < 700) {
            this.game.assetsController!.infoBarMobile.scale.y = window.innerWidth / 1920 * 1.6;
            this.game.assetsController!.infoBarMobile.y = window.innerHeight - this.game.assetsController!.infoBarMobile.height * 1.9;
            this.game.assetsController?.changeContainerScaling(0.87, 0.87);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 - 35, window.innerHeight / 2 + 75)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 - 35, window.innerHeight / 2 + 75)
        } else if (window.innerHeight < 800) {
            this.game.assetsController!.infoBarMobile.scale.y = window.innerWidth / 1920 * 1.6;
            this.game.assetsController!.infoBarMobile.y = window.innerHeight - this.game.assetsController!.infoBarMobile.height * 1.6;
            this.game.assetsController?.changeContainerScaling(0.87, 0.87);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 -20, window.innerHeight / 2 + 175)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 -20, window.innerHeight / 2 + 175)
        } else {
            this.game.assetsController!.infoBarMobile.scale.y = window.innerWidth / 1920 * 1.6;
            this.game.assetsController!.infoBarMobile.y = window.innerHeight - this.game.assetsController!.infoBarMobile.height * 1.5;
            this.game.assetsController?.changeContainerScaling(0.87, 0.87);
           this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 -50, window.innerHeight / 2 + 120)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 -50, window.innerHeight / 2 + 120)
        }
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobilePortret,
            false
        );
        if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === true){
            this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
            this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(false);
        }
        // this.game.gameContainer.y = 0;
    };

    resizeToLandscapeMobile = () => {
        this.game.assetsController?.winModal?.changeWinModalPosition(this.game.gameContainer.x, 0);

        this.game.assetsController?.buyBonusModal?.changeBuyBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyBonusModal?.modal.width / 2, 10);
        this.game.assetsController?.buyMaxBonusModal?.changeBuyMaxBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyMaxBonusModal?.modal.width / 2, 10);
        this.game.assetsController?.buyMinBonusModal?.changeBuyMinBonusModalPosition(window.innerWidth / 2 - this.game.assetsController.buyMinBonusModal?.modal.width / 2, 10);
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);
        this.game.assetsController?.changeSize(
            this.game.assetsController?.infoBarMobilePortret,
            1920,
            127
        );
        this.game.assetsController?.settingsModal?.changeSettingsModalScale(2, 2);
        this.game.assetsController?.settingsModal?.changeSettingsModalPosition(615, 200);

        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalScale(2, 2);
        this.game.assetsController?.autoPlayModal?.changeAutoPlayModalPosition(615, 200);

        this.game.assetsController?.changeSize(
            this.game.assetsController?.buyBonusModal?.closeButton?.button,
            120,
            120
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.buyMinBonusModal?.closeButton?.button,
            120,
            120
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.buyMaxBonusModal?.closeButton?.button,
            120,
            120
        );
        this.game.assetsController?.changePosition(
            this.game.assetsController?.buyBonusModal?.closeButton?.button,
            1550,
            -25
        );
        this.game.assetsController?.changePosition(
            this.game.assetsController?.buyMaxBonusModal?.closeButton?.button,
            1550,
            -25
        );
        this.game.assetsController?.changePosition(
            this.game.assetsController?.buyMinBonusModal?.closeButton?.button,
            1550,
            -25
        );

        this.game.assetsController?.betModal?.changeBetModalScale(2, 2);
        this.game.assetsController?.betModal?.changeBetModalPosition(615, 200);

        this.game.assetsController?.changeSize(
            this.game.assetsController?.buyBonusButton?.button,
            165,
            160
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.doubleBonusChanceButton?.button,
            165,
            160
        );
        this.game.assetsController?.changeMultiplierScale(0.6, 0.6);

        if (this.leftHandMode) {
            this.game.gameContainer.x =
                (window.innerWidth - this.game.gameContainer.width) / 2 - 26;
            this.game.assetsController?.changePosition(
                this.game.assetsController?.logo,
                1643,
                80
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.buyBonusButton?.button,
                1753,
                335
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.doubleBonusChanceButton?.button,
                1753,
                543
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.settingsButton?.button,
                1753,
                795
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.autoPlayButton?.button,
                213,
                158
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.playButton?.button,
                213,
                485
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.betButton?.button,
                213,
                795
            );
            this.game.assetsController?.changeMultiplierPosition(1753, 710);
            if (window.innerHeight < 400) {
                this.game.gameContainer.y = -15;
                this.game.assetsController?.reelsContainer?.changeContainerPosition(505, 70);
            } else if (window.innerWidth < 750) {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(475, 45);
                this.game.gameContainer.y = -15;
            } else {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(475, 45);
                this.game.gameContainer.y = -15;
            }
        } else {
            this.game.gameContainer.x =
                (window.innerWidth - this.game.gameContainer.width) / 2 - 20;
            this.game.assetsController?.changePosition(
                this.game.assetsController?.buyBonusButton?.button,
                173,
                335
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.doubleBonusChanceButton?.button,
                173,
                543
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.settingsButton?.button,
                173,
                795
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.autoPlayButton?.button,
                1718,
                158
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.playButton?.button,
                1718,
                485
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.logo,
                70,
                80
            );
            this.game.assetsController?.changePosition(
                this.game.assetsController?.betButton?.button,
                1718,
                795
            );
            this.game.assetsController?.changeMultiplierPosition(220, 710);
            if (window.innerHeight < 400) {
                this.game.gameContainer.y = -15;
                this.game.assetsController?.reelsContainer?.changeContainerPosition(430, 70);
            } else if (window.innerWidth < 750) {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(400, 45);
                this.game.gameContainer.y = -15;
            } else {
                this.game.assetsController?.reelsContainer?.changeContainerPosition(400, 45);
                this.game.gameContainer.y = -15;
            }
        }

        if (this.game.assetsController?.isFs) {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                true
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.buyBonusButton?.button,
                false
            )
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.doubleBonusChanceButton?.button,
                false
            )
        } else {
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBar,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController?.multiplierBarMobile,
                false
            );
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.buyBonusButton?.button,
                true
            )
            this.game.assetsController?.changeVisibility(
                this.game.assetsController.doubleBonusChanceButton?.button,
                true
            )
        }

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBar,
            false
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.settingsButton?.button,
            180,
            180
        );
        this.game.assetsController?.changeVisibility(
            this.game.assetsController.settingsButton?.button,
            true
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.autoPlayButton?.button,
            200,
            200
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.playButton?.button,
            290,
            290
        );

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.logo,
            true
        );
        this.game.assetsController?.changeVisibility(this.game.assetsController?.logoPortrait, false);
        this.game.assetsController?.changeContainerScaling(0.9, 0.9);

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.betButton?.button,
            true
        );
        this.game.assetsController?.changeSize(
            this.game.assetsController?.betButton?.button,
            180,
            180
        );

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobile,
            true
        );

        this.game.assetsController!.infoBarMobile.scale.x = window.innerWidth / 1920 * 1.6;

        if (window.innerHeight < 400) {
            this.game.assetsController!.infoBarMobile.scale.y = window.innerWidth / 1920 * 1.5;
            this.game.assetsController!.infoBarMobile.y = window.innerHeight - this.game.assetsController!.infoBarMobile.height * 1.9;
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 - 45, window.innerHeight / 2 + 65)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 - 45, window.innerHeight / 2 + 65)
            if (window.innerWidth > 700) {
                this.game.assetsController!.infoBarMobile.scale.y = window.innerWidth / 1920 * 1.4;
                this.game.assetsController!.infoBarMobile.y = window.innerHeight - this.game.assetsController!.infoBarMobile.height * 1.9;
                this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 - 45, window.innerHeight / 2 + 65)
                this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 - 45, window.innerHeight / 2 + 65)
            }
            this.game.assetsController?.changeContainerScaling(0.8, 0.8);
        } else if (window.innerWidth < 750) {
            this.game.assetsController!.infoBarMobile.scale.y = window.innerWidth / 1920 * 1.8;
            this.game.assetsController!.infoBarMobile.y = window.innerHeight - this.game.assetsController!.infoBarMobile.height * 1.6;
            this.game.assetsController?.changeContainerScaling(0.87, 0.87);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 - 40, window.innerHeight / 2 + 100)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 - 40, window.innerHeight / 2 + 100)
        } else {
            this.game.assetsController!.infoBarMobile.scale.y = window.innerWidth / 1920 * 1.8;
            this.game.assetsController!.infoBarMobile.y = window.innerHeight - this.game.assetsController!.infoBarMobile.height * 1.6;
            this.game.assetsController?.changeContainerScaling(0.87, 0.87);
            this.game.assetsController?.winFinishPopUp?.changeWinFinishPosition(window.innerWidth / 2 - 40, window.innerHeight / 2 + 150)
            this.game.assetsController?.winStartPopUp?.changeWinStartPosition(window.innerWidth / 2 - 40, window.innerHeight / 2 + 150)
        }

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobilePortret,
            false
        );

        this.game.assetsController?.changeVisibility(
            this.game.assetsController.infoBarMobilePortret,
            false
        );

        this.game.assetsController?.changeSize(
            this.game.assetsController?.infoBarMobilePortret,
            0,
            0
        );
        if(this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === true){
            this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
            this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(false);
        }
    };

    enableLeftHandMode = async (isActive: boolean) => {
        this.leftHandMode = isActive;
        let adaptiveRatio = window.innerWidth / window.innerHeight;
        if (adaptiveRatio < 1) {
            await this.resizeToPortrait()
        } else if (adaptiveRatio > 1.78 && window.innerWidth >= 1280) {
            await this.resizeToDesktop1();
        } else if (adaptiveRatio < 1.78 && window.innerWidth < 1280) {
            await this.resizeToLandscape();
        } else if (adaptiveRatio > 1.78 && window.innerWidth < 1280) {
            await this.resizeToLandscapeMobile();
        } else {
            await this.resizeToDesktop2();
        }
    }

    fixGameContainer = async () => {
        let adaptiveRatio = window.innerWidth / window.innerHeight;
        if (adaptiveRatio < 1) {
            await this.resizeToPortrait()
        } else if (adaptiveRatio > 1.78 && window.innerWidth >= 1280) {
            await this.resizeToDesktop1();
        } else if (adaptiveRatio < 1.78 && window.innerWidth < 1280) {
            await this.resizeToLandscape();
        } else if (adaptiveRatio > 1.78 && window.innerWidth < 1280) {
            await this.resizeToLandscapeMobile();
        } else {
            await this.resizeToDesktop2();
        }
    }
}
