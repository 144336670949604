export enum EventType {
  GAME_ASSETS_LOADED = "GAME_ASSETS_LOADED",
  GAME_ON_SPIN = "GAME_ON_SPIN",
  GAME_ON_SPIN_RUNNING = "GAME_ON_SPIN_RUNNING",
  GAME_BET_VALUE = "GAME_BET_VALUE",
  GAME_WIN_VALUE = "GAME_WIN_VALUE",
  GAME_AUTO_SPIN_COUNT = "GAME_AUTO_SPIN_COUNT",
  GAME_BTN_UI_SOUND = "GAME_BTN_UI_SOUND",
  BG_SOUND = "BG_SOUND",
  OUT_OF_MONEY = "OUT_OF_MONEY",
  GET_BALANCE = "GET_BALANCE",
  CURRENT_BET_INDEX = "CURRENT_BET_INDEX",
}
