import { useCallback, useEffect, useState } from "react";
import { PixiGame } from "..";
import { ISpinResponse } from "../../../api/types/auth";
import { api } from "../../../App";
import { EventType, getEvent, useEventReceiver } from "../../../GameEventBus";
import useHandlerGameBet from "./useHandlerGameBet";
import {roundNum} from "../../../utils/roundNum";
import { UI } from "../../../ui";

const useUpdateGameBalance = (game: PixiGame, isAssetsLoaded: boolean) => {
  const [onSpinRunning, setOnSpinRunning] = useState(false);
  const [spin, setSpin] = useState<ISpinResponse | undefined>();
  const [balance, setBalance] = useState(0);
  const [totalBetValue, setTotalBetValue] = useState(0);
  const { betValue } = useHandlerGameBet(game, balance);


  useEventReceiver<boolean>(EventType.GAME_ON_SPIN_RUNNING, (value) => {
    setOnSpinRunning(value);
  });

  useEventReceiver<ISpinResponse | undefined>(
    EventType.GAME_ON_SPIN,
    (value) => {
      setSpin(value);
    }
  );

  const getBalance = useCallback(async () => {
    setBalance((await api.auth.getUserBalance()) / 100);
  }, [setBalance]);

  useEffect(() => {
    if (!isAssetsLoaded) return;
    getBalance();
  }, [isAssetsLoaded]);

  useEffect(() => {
    if (!balance) return;
    game.balance.changeBalanceValue(balance);
    // game.assetsController?.balanceInput?.changeValue(balance);
    // game.assetsController?.balanceInputLandscape?.changeValueLandscape(balance)
    // game.assetsController?.balanceInputPortret?.changeValue(balance)
    // game.assetsController?.buyMaxBonusModal?.getBalance(balance);
    // game.assetsController?.buyMinBonusModal?.getBalance(balance);
    game.slotMachine?.getBalance(balance);
    game.balance.changeBalanceValue(balance);

    UI.events?.emit("balance:update", {
      value: balance
    });
  }, [game, balance]);


  useEffect(() => {
    if (onSpinRunning && !spin && game.assetsController?.isFs) {
      return;
    }else if(onSpinRunning && !spin && !game.assetsController?.isFs){
      setBalance((balance - (game.balance.betValue || betValue)));
      setTotalBetValue((totalBetValue + (game.balance.betValue || betValue!)))

      game.balance.changeBalanceValue(
         (balance - (game.balance.betValue || betValue))
      );
      // game.assetsController?.balanceInput?.changeValue(
      //     (balance - (game.slotMachine?.betValue! || betValue!))
      // );
      // game.assetsController?.balanceInputLandscape?.changeValueLandscape((balance - (game.slotMachine?.betValue! || betValue!)))
      // game.assetsController?.balanceInputPortret?.changeValue((balance - (game.slotMachine?.betValue! || betValue!)))
      // game.assetsController?.buyMaxBonusModal?.getBalance(balance);
      // game.assetsController?.buyMinBonusModal?.getBalance(balance);
      game.slotMachine?.getBalance(balance);
      game.balance.changeBalanceValue(balance);
    }
    if (!onSpinRunning && !!spin) {
      setBalance((spin.account.value / 100));
      // setTotalBetValue(spin.totalBet/100);

      // game.balance.changeBalanceValue()

      // game.assetsController?.balanceInput?.changeValue(
      //     (spin.account.value / 100)
      // );

      // game.assetsController?.balanceInputLandscape?.changeValueLandscape((spin.account.value / 100)
      // )
      // game.assetsController?.balanceInputPortret?.changeValue((spin.account.value / 100)
      // )
      // game.assetsController?.buyMaxBonusModal?.getBalance(balance);
      // game.assetsController?.buyMinBonusModal?.getBalance(balance);
      game.slotMachine?.getBalance(balance);
      getEvent(EventType.GAME_ON_SPIN).send(undefined);
    }
  }, [onSpinRunning]);

  useEventReceiver<number>(EventType.GET_BALANCE, (value) => {
    setBalance(value);
  });

  return { balance, betValue, totalBetValue }
}

export default useUpdateGameBalance
