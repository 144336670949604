import {FC, useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import { respondFrom, respondTo } from '../../../styles/mixins';
import { PixiGame } from '../../../features/Game';
import { betList } from './constants';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import {EventType, getEvent, useEventReceiver} from '../../../GameEventBus';
import { useActions } from '../../../hooks/useActions';

import arrowDown from '../../../assets/icons/close-arrow-down.svg';
import {Howl} from "howler";

interface ITotalBetModal {
	game: PixiGame;
}

const TotalBetModal: FC<ITotalBetModal> = ({ game }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const { info, bet, totalBet } = useTypedSelector(state => state.auth);
	const [currency, setCurrency] = useState('USD');
	const [selectedBet, setSelectedBet] = useState<number>(0)
	const { setBetValue } = useActions();
	useEventReceiver<number>(EventType.CURRENT_BET_INDEX, value => {
		setSelectedBet(value);
	});

	const setBet = useCallback(
		(value: number, currency: string) => {
		if(game.assetsController?.doubleBonusChanceButton?.isDBCActive === false){
			getEvent(EventType.GAME_BET_VALUE).send(game.slotMachine?.betValue);
			setBetValue(value);
			setCurrency(currency);
			game.soundController.btn_ui?.play()
			game.slotMachine?.changeBetValue(value);

			game.assetsController?.betModal?.changeBetValue(value);
			game.assetsController?.buyBonusModal?.changeMaxCost(value);
			game.assetsController?.buyBonusModal?.changeMinCost(value);
			game.assetsController?.buyBonusModalPortrait?.changeMaxCost(value);
			game.assetsController?.buyBonusModalPortrait?.changeMinCost(value);
			game.assetsController?.buyMinBonusModal?.changeMinCost(value);
			game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(value);
			game.assetsController?.buyMaxBonusModal?.changeMaxCost(value);
			game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(value);
		} else {
			getEvent(EventType.GAME_BET_VALUE).send(game.slotMachine?.betValue);
			setBetValue(value * 1.25);
			setCurrency(currency);
			game.soundController.btn_ui?.play()
			game.slotMachine?.changeBetValue(value * 1.25);

			game.assetsController?.betModal?.changeBetValue(value * 1.25);
			game.assetsController?.buyBonusModal?.changeMaxCost(value * 1.25);
			game.assetsController?.buyBonusModal?.changeMinCost(value * 1.25);
			game.assetsController?.buyBonusModalPortrait?.changeMaxCost(value * 1.25);
			game.assetsController?.buyBonusModalPortrait?.changeMinCost(value * 1.25);
			game.assetsController?.buyMinBonusModal?.changeMinCost(value * 1.25);
			game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(value * 1.25);
			game.assetsController?.buyMaxBonusModal?.changeMaxCost(value * 1.25);
			game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(value * 1.25);
		}
	},
		[setCurrency, setBetValue, game]
	);
	useEffect(()=>{
		if(bet === 0){
			setBet(Number(info.betValue), 'USD')
		}
	},[])

	const showModal = (isOpen: boolean) => {
		setModalOpen(isOpen);
	};

	const closeModal = useCallback(() => {
		game.soundController.btn_ui?.play()
		setModalOpen(false)
	}, [setModalOpen])

	game.assetsController?.betButton?.button.on('click', () => showModal(true));
	game.assetsController?.betButton?.button.on('touchstart', () =>
		showModal(true)
	);

	// const totalBetWithBonus = [];
	// if(game.assetsController?.doubleBonusChanceButton?.isDBCActive === true){
	// 	for();
	// }

	const changeBet = useCallback((index: number) => {
		setSelectedBet(index)
		game.initialData?.setBetValueIndex(index);
		setBet(Number(totalBet[index]), 'USD')
	}, [setSelectedBet, setBet])

	return (
		<Wrapper style={isModalOpen ? { display: 'flex' } : { display: 'none' }}>
			<Container>
				<Header>
					<CloseButton>
						<img src={arrowDown} alt='Close' onClick={closeModal} />
					</CloseButton>
					<HeaderTitle>
						<h2>TOTAL BET</h2>
						<p>Choose you bet</p>
					</HeaderTitle>
					<HeaderBet>
						<HeaderBetBody>
							{bet === 0
								? Number(info.betValue).toFixed(2)
								: Number(game.assetsController?.doubleBonusChanceButton?.isDBCActive === false? totalBet[selectedBet] : totalBet[selectedBet] * 1.25).toFixed(2)}
							{currency}
						</HeaderBetBody>
					</HeaderBet>
				</Header>
				<BetDisplay>
					<div>Min Bet: {game.assetsController?.doubleBonusChanceButton?.isDBCActive === false? totalBet[0] : totalBet[0] * 1.25} USD</div>
					<div>Max Bet: {game.assetsController?.doubleBonusChanceButton?.isDBCActive === false? totalBet[totalBet?.length - 1] : totalBet[totalBet?.length - 1] * 1.25} USD</div>
				</BetDisplay>
				<BetButtonWrapper>
					{totalBet.map((item, index) => (
						<BetButton
							key={index}
							className= {index === selectedBet ? 'active-bet' : ''}
							onClick={() => 
								changeBet(index)
							}
						>
							{(game.assetsController?.doubleBonusChanceButton?.isDBCActive === false? item : item * 1.25).toFixed(2)} USD
						</BetButton>
					))}
				</BetButtonWrapper>
			</Container>
		</Wrapper>
	);
};

export default TotalBetModal;

const Wrapper = styled.div`
	padding: 38px 6px 6px;
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(21, 21, 21, 0.8);
	left: 0;
	top: 0;
	align-items: flex-start;
	justify-content: flex-end;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	${respondTo.lg`
		display: none !important;
	`}
	${respondFrom.sm`
		align-items: flex-end;
		justify-content: center;
	`}
`;

const Container = styled.div`
	display: flex;
	height: 100%;
	max-height: 300px;
	flex-direction: column;
	background-color: #151515;
	padding: 8px 9px 10px;
	border: 1px solid #2a2c2b;
	border-radius: 15px;
	width: 304px;
	${respondFrom.sm`
		max-width: 360px;
		width: 100%;
	`}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 8px;
`;

const HeaderTitle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h2 {
		color: #e2f4f2;
		font-weight: 700;
		font-size: 17px;
		line-height: 24px;
	}
	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #7c8584;
	}
`;

const HeaderBet = styled.div`
	border-radius: 6px;
	height: 32px;
	width: 71px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
`;

const HeaderBetBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 67px;
	border: 2px solid #151515;
	border-radius: 4px;
	background: #e2f4f2;
	color: #151515;
	font-family: 'Rubik';
	font-weight: 700;
	font-size: 13px;
	line-height: 17px;
`;

const BetDisplay = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 30px;
	background: #2a2c2b;
	border-radius: 5px;
	div {
		padding: 0 6px;
		font-family: 'Rubik';
		font-weight: 400;
		font-size: 11px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #7c8584;
	}
`;

const BetButtonWrapper = styled.div`
	display: flex;
	scroll-behavior: smooth;
	overflow: auto;
	flex-direction: column;
	align-items: center;
`;

const BetButton = styled.button`
	margin-top: 8px;
	border: 2px solid #535858;
	border-radius: 5px;
	width: 101px;
	min-height: 27px;
	color: #535858;
	background: #151515;
	font-family: 'Rubik';
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 13px;
	line-height: 17px;
	cursor: pointer;
	&.active-bet {
		color: #e2f4f2;
		border-color: #e2f4f2;
	}
`;

const CloseButton = styled.div`
	width: 33px;
	height: 27px;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	img {
		cursor: pointer;
	}
`;
