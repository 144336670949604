import { css } from 'styled-components';

export type Breakpoint =
	| 'default'
	| 'baseMin'
	| 'base'
	| 'xs'
	| 'smTo'
	| 'sm'
	| 'md'
	| 'lgTo'
	| 'lg'
	| 'xlg'
	| 'xl';

export const breakpoints: Record<Breakpoint, string> = {
	default: '0px',
	baseMin: '280px',
	base: '320px',
	xs: '400px',
	sm: '600px',
	smTo: '600.9px',
	md: '900px',
	lgTo: '1279px',
	lg: '1279.9px',
	xlg: '1440px',
	xl: '1920px',
};

type cssParams = Parameters<typeof css>;
const keys = Object.keys(breakpoints) as Array<keyof typeof breakpoints>;

export const respondTo = keys.reduce((accumulator, label) => {
	accumulator[label] = (...args: cssParams) => {
		return css`
			@media (min-width: ${breakpoints[label]}) {
				${css(...args)};
			}
		`;
	};
	return accumulator;
}, {} as Record<keyof typeof breakpoints, Function>);

export const respondFrom = keys.reduce((accumulator, label) => {
	accumulator[label] = (...args: cssParams) => {
		return css`
			@media (max-width: ${breakpoints[label]}) {
				${css(...args)};
			}
		`;
	};
	return accumulator;
}, {} as Record<keyof typeof breakpoints, Function>);

export const respondHeightFrom = keys.reduce((accumulator, label) => {
	accumulator[label] = (...args: cssParams) => {
		return css`
			@media (max-height: ${breakpoints[label]}) {
				${css(...args)};
			}
		`;
	};
	return accumulator;
}, {} as Record<keyof typeof breakpoints, Function>);
