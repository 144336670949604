import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';
import {
    respondFrom,
    respondHeightFrom,
} from '../../../styles/mixins';

import iconClose from '../../../assets/icons/close-icon.svg';
import iconCloseHover from '../../../assets/icons/close-icon-hover.svg';
import {EventType, useEventReceiver} from "../../../GameEventBus";
import {Simulate} from "react-dom/test-utils";
import click = Simulate.click;
import {Howl, Howler} from 'howler';
import {PixiGame} from "../../../features/Game";

interface IPaytableModal {
    game: PixiGame;
}

const OutOfMoneyModal: FC<IPaytableModal> = ({game}) => {
    const [isModalActive, setModalActive] = useState(false);
    const [clickUiSound, setClickUiSound] = useState<Howl | undefined>()

    useEventReceiver<Howl>(EventType.GAME_BTN_UI_SOUND, value => {
        if (value) {
            setClickUiSound(value)
        }
    });

    useEventReceiver<boolean>(EventType.OUT_OF_MONEY, value => {
        setModalActive(value);
    });

    const closeModal = useCallback(() => {
        clickUiSound?.play()
        setModalActive(false)
    }, [clickUiSound]);

    return (
        <Wrapper style={isModalActive ? {display: 'flex'} : {display: 'none'}}>
            <DesktopView>
                <CloseButton>
                    <CloseButtonBorder>
                        <CloseButtonBody className='nav-btn-hover' onClick={closeModal}>
                            <img src={iconClose} alt='Settings' draggable={false}/>
                            <img src={iconCloseHover} alt='Settings' draggable={false}/>
                        </CloseButtonBody>
                    </CloseButtonBorder>
                </CloseButton>
                <Title>Out of Money</Title>
                <SubTitle>Please deposit more money to continue playing</SubTitle>
                <ConfirmButton>
                    <ConfirmButtonBorder>
                        <ConfirmButtonBody className='nav-btn-hover' onClick={closeModal}>
                            OK
                        </ConfirmButtonBody>
                    </ConfirmButtonBorder>
                </ConfirmButton>
            </DesktopView>
        </Wrapper>
    );
};

export default OutOfMoneyModal;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(21, 21, 21, 0.8);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${respondHeightFrom.sm`
    padding: 40px 10px 10px;
  `}
`;

const DesktopView = styled.div`
  text-align: center;
  position: relative;
  background: #151515;
  border-radius: 10px;
  border: 1px solid #2a2c2b;
  padding: 8px;
  color: #fff;
  max-width: 809px;
  max-height: 439px;
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${respondFrom.sm`
	padding: 40px 12px 12px;
	max-height: 600px;
  `};
`;

const Title = styled.h2`
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  padding: 87px 0px 28px 0px;
  ${respondHeightFrom.xs`
    padding: 60px 0px 20px 0px;
    font-size: 34px;
    line-height: 43px;
  `}
  ${respondHeightFrom.base`
    padding: 30px 0px 15px 0px;
    font-size: 28px;
    line-height: 36px;
  `}
`;
const SubTitle = styled.div`
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  padding: 0px 0px 52px 0px;
  ${respondHeightFrom.xs`
    padding: 0px 0px 20px 0px;
    font-size: 20px;
    line-height: 28px;
  `}
  ${respondHeightFrom.base`
    padding: 0px 0px 20px 0px;
    font-size: 18px;
    line-height: 22px;
  `}
`;

const CloseButton = styled.div`
  z-index: 10000;
  right: -15px;
  top: -15px;
  position: absolute;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background: rgba(21, 21, 21, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  img {
    height: 21px;
    width: 21px;
    display: inline-block;
  }

  img:last-child {
    display: none;
  }

  &:hover {
    .nav-btn-hover {
      background: #e2f4f2;
      border: 2px solid rgba(21, 21, 21, 0.8);

      img {
        display: none;
      }

      img:last-child {
        transition: all ease 0.5s;
        display: inline-block;
      }
    }
  }
`;

const CloseButtonBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: #151515;
`;

const CloseButtonBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 52px;
  width: 52px;
  background: conic-gradient(from 180deg at 50% 50%,
  #af70c1 0deg,
  #45d1bd 125.63deg,
  #f9ad29 266.25deg,
  #af70c1 360deg);
`;

const ConfirmButton = styled.div`
  border-radius: 60px;
  height: 60px;
  width: 128px;
  background: rgba(21, 21, 21, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: #E2F4F2;

  &:hover {
    .nav-btn-hover {
      background: #e2f4f2;
      border: 2px solid rgba(21, 21, 21, 0.8);
    }
    div {
      color: #151515;
    }
    transition: all ease 0.2s;
  }
`;

const ConfirmButtonBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 116px;
  border-radius: 60px;
  background: #151515;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
`;

const ConfirmButtonBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  height: 52px;
  width: 120px;
  background: conic-gradient(from 180deg at 50% 50%,
  #af70c1 0deg,
  #45d1bd 125.63deg,
  #f9ad29 266.25deg,
  #af70c1 360deg);
`;
