export class InitialData {
  constructor() {
    this.betValue = 1;
    this.betValueIndex = 0;
  }

  setBetValue(betValue) {
    sessionStorage.setItem('betValue', betValue.toFixed(2))
  }

  setBetValueIndex(betValueIndex) {
    sessionStorage.setItem('betValueIndex', betValueIndex)
  }

  getBetValue() {
    const betValueFromStorage = sessionStorage.getItem('betValue');

    if (!betValueFromStorage || isNaN(Number(betValueFromStorage))) {
      return this.betValue;
    }

    return Number(betValueFromStorage);
  }

  getBetValueIndex() {
    const betValueIndexFromStorage = sessionStorage.getItem('betValueIndex');
    console.log(betValueIndexFromStorage, "betValueIndexFromStorage")
    if (!betValueIndexFromStorage || isNaN(Number(betValueIndexFromStorage)) || Number(betValueIndexFromStorage) < 0) {
      return this.betValueIndex;
    }

    return Number(betValueIndexFromStorage);
  }
}
