import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';
import {
	respondFrom,
	respondTo,
	respondHeightFrom,
} from '../../../styles/mixins';
import { rulesList } from './constants';
import RulesSlider from './RulesSlider';

import iconClose from '../../../assets/icons/close-icon.svg';
import iconCloseHover from '../../../assets/icons/close-icon-hover.svg';
import {Howl} from "howler";
import {EventType, useEventReceiver} from "../../../GameEventBus";

interface IPaytableModal {
	handleCloseModal: () => void;
	clickUiSound: Howl
}

const RulesModal: FC<IPaytableModal> = ({ handleCloseModal, clickUiSound }) => {
	const [slideIndex, setSlideIndex] = useState(1);

	const closeModal = useCallback(() => {
		clickUiSound.play()
		setSlideIndex(1);
		handleCloseModal();
	}, [handleCloseModal, setSlideIndex, clickUiSound])

	return (
		<>
			<MobileView>
				<MobileCloseButton>
					<MobileCloseButtonBorder>
						<MobileCloseButtonBody
							className='nav-btn-hover'
							onClick={handleCloseModal}
						>
							<img src={iconClose} alt='Settings' draggable={false} />
							<img src={iconCloseHover} alt='Settings' draggable={false} />
						</MobileCloseButtonBody>
					</MobileCloseButtonBorder>
				</MobileCloseButton>
				<Title>RULES</Title>
				<Content
					dangerouslySetInnerHTML={{ __html: rulesList.content }}
					draggable={false}
				/>
			</MobileView>

			<DesktopView>
				<Wrapper>
					<CloseButton>
						<CloseButtonBorder>
							<CloseButtonBody className='nav-btn-hover' onClick={closeModal}>
								<img src={iconClose} alt='Settings' draggable={false} />
								<img src={iconCloseHover} alt='Settings' draggable={false} />
							</CloseButtonBody>
						</CloseButtonBorder>
					</CloseButton>
					<RulesSlider slideIndex={slideIndex} clickUiSound={clickUiSound} setSlideIndex={setSlideIndex} />
				</Wrapper>
			</DesktopView>
		</>
	);
};

export default RulesModal;

const MobileView = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #151515;
	border-radius: 10px;
	border: 1px solid #2a2c2b;
	padding: 8px;
	color: #fff;
	height: 100%;
	min-height: 200px;
	${respondFrom.sm`
		padding: 12px;
	`};
	${respondTo.lg`
		display: none;
	`}
`;

const MobileCloseButton = styled.div`
	right: -6px;
	top: -6px;
	position: absolute;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	background: rgba(21, 21, 21, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	img {
		height: 10px;
		width: 10px;
		display: inline-block;
	}
	img:last-child {
		display: none;
	}
	&:hover {
		.nav-btn-hover {
			background: #e2f4f2;
			border: 1px solid rgba(21, 21, 21, 0.8);
			img {
				display: none;
			}
			img:last-child {
				transition: all ease 0.5s;
				display: inline-block;
			}
		}
	}
	${respondTo.lg`
		display: none;
	`}
`;

const MobileCloseButtonBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #151515;
`;

const MobileCloseButtonBorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 26px;
	width: 26px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
`;

const Title = styled.h2`
	text-align: center;
	color: #e2f4f2;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 10px;
`;

const Content = styled.div`
	overflow-y: auto;
	padding: 0 5px;
	margin-bottom: 10px;
	h2 {
		font-weight: 700;
		font-size: 15px;
		line-height: 20px;
		padding-bottom: 5px;
	}
	p {
		font-weight: 400;
		font-size: 13px;
		line-height: 17px;
	}
	${respondTo.sm`
		padding: 0 30px;
	`}
	${respondHeightFrom.base`
		margin-bottom: 0;
	`}
`;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	width: 100%;
	height: 100%;
	min-height: 200px;
`;

const DesktopView = styled.div`
	margin: auto;
	background: #151515;
	border-radius: 10px;
	border: 1px solid #2a2c2b;
	padding: 8px;
	color: #fff;
	max-width: 1618px;
	max-height: 100%;
	width: 84.32%;
	height: calc(100vw / (1920 / 879));
	min-height: 200px;
	${respondFrom.sm`
		padding: 12px;
	`};
	${respondFrom.lg`
		display: none;
	`}
`;

const CloseButton = styled.div`
	z-index: 10000;
	right: -22px;
	top: -22px;
	position: absolute;
	border-radius: 50%;
	height: calc(100vw / (1920 / 60));
	width: calc(100vw / (1920 / 60));
	background: rgba(21, 21, 21, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	img {
		height: calc(100vw / (1920 / 21));
		width: calc(100vw / (1920 / 21));
		display: inline-block;
	}
	img:last-child {
		display: none;
	}
	&:hover {
		.nav-btn-hover {
			background: #e2f4f2;
			border: 2px solid rgba(21, 21, 21, 0.8);
			img {
				display: none;
			}
			img:last-child {
				transition: all ease 0.5s;
				display: inline-block;
			}
		}
	}
`;

const CloseButtonBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vw / (1920 / 48));
	width: calc(100vw / (1920 / 48));
	border-radius: 50%;
	background: #151515;
`;

const CloseButtonBorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: calc(100vw / (1920 / 52));
	width: calc(100vw / (1920 / 52));
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
`;
