export interface SettingModalState {
	settingModalIsOpened: boolean;
}

export enum SettingModalActionEnum {
	SET_MODAL = 'SET_MODAL',
}

export interface SetSettingModalAction {
	type: SettingModalActionEnum.SET_MODAL;
	payload: boolean;
}

export type SettingModalAction =
	| SetSettingModalAction;
