import * as PIXI from 'pixi.js';
import '../../../../styles/index.css';

export const valueStyles = new PIXI.TextStyle({
	fontFamily: 'Lilta One',
	dropShadow: true,
	dropShadowAngle: -0.8,
	dropShadowBlur: 6,
	dropShadowColor: '#671106',
	dropShadowDistance: -4,
	fill: 'white',
	fontSize: 72,
	letterSpacing: 4,
	lineJoin: 'round',
	miterLimit: 11,
	stroke: '#fe232c',
	strokeThickness: 11,
});
