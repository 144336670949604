import { ITrackEntry, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';
import CustomButton from '../CustomButton';
import gsap from "gsap";

class WinFinishPopUp {
  app: Application;
  game: PixiGame;
  confirmButton?: CustomButton;
  buttonBackground: PIXI.Graphics;
  modal: PIXI.Container;
  background?: Spine;
  value?: PIXI.Container;
  modalWrapper: PIXI.Container;
  windowBackground: PIXI.Graphics;
  isModalOpen: boolean;
  stopIfFSWon: boolean | undefined;

  constructor(game: PixiGame, app: Application) {
    this.game = game;
    this.app = app;
    this.buttonBackground = new PIXI.Graphics();
    this.modal = new PIXI.Container();
    this.modalWrapper = new PIXI.Container();
    this.windowBackground = new PIXI.Graphics();
    this.value = new PIXI.Container();
    this.isModalOpen = false;
    this.stopIfFSWon = false;
  }

  initialize = async () => {

    this.modalWrapper.x = 0
    this.modalWrapper.y = 0
    this.modalWrapper.width = this.app.screen.width;
    this.modalWrapper.height = this.app.screen.height + 32;
    this.modalWrapper.visible = false;
    this.modalWrapper.zIndex = 10000;

    this.windowBackground.beginFill(0x151515);
    this.windowBackground.drawRect(0, 0, this.app.screen.width, this.app.screen.height + 32);
    this.windowBackground.endFill();
    this.windowBackground.alpha = 0.8;
    this.windowBackground.zIndex = 1

    this.value!.y = -345;
    this.value!.x = this.value!.width/2;
    this.modal.x = window.innerWidth/2;
    this.modal.y = window.innerHeight/2 + this.modal.height/2;
    this.modal.zIndex = 15000;

    const bg = this.app.loader.resources['popUpFinish']?.spineData;
    if (!bg) return;
    const background = new Spine(bg)
    background.x = 0;
    background.y = 0;
    this.background = background

    this.app.stage.addChild(this.modalWrapper);
    this.modalWrapper.addChild(this.windowBackground!);
    this.modalWrapper.addChild(this.modal!);
    this.modal.addChild(this.background!);
    this.modal.addChild(this.value!);
    await this.setupButtons()
  };

  setValue = (
      value: string,
  ) => {
    const resultArray = value.split('')
    let x = 0
    console.log(resultArray);
    resultArray.map((el:string) => {
      let elementValue
      if (el ==='€'){
        elementValue = new PIXI.Sprite(
            this.app.loader.resources['euro'].texture!
        )
      }else if (el === '$') {
        elementValue = new PIXI.Sprite(
            this.app.loader.resources['dollar'].texture!
        )
      } else if (el === '.') {
        elementValue = new PIXI.Sprite(
            this.app.loader.resources['dot'].texture!
        )
      }else {
        elementValue = new PIXI.Sprite(
            this.app.loader.resources[`number${el}`].texture!
        )
      }

      elementValue.x = x
      elementValue.y = 0
      this.value?.addChild(elementValue);
      if (el === '1') {
        x += 30
      } else if (el === '0' || el === '4' || el === '8') {
        x += 50
      } else if (el==='€') {
        x += 60
      } else if (el==='$') {
        x += 35
      } else if (el === '.') {
        x += 15
      } else {
        x += 40
      }
    })
    const widthValue = this.value?.width
    let animation2 = gsap.timeline();
    let animation = gsap.timeline();
    animation.fromTo(
        this.value!,
        0.55,
        {x: 0},
        {x: -this.value!.width/2, repeat: 0}
    );

    animation2.fromTo(
        this.value!,
        0.55,
        {width:0},
        {width: widthValue, repeat: 0}
    )
  };

  startAnim = async (): Promise<boolean> => {
    return new Promise((resolve) => {
      this.background?.state.setAnimation(1, "1_start", false);

      this.background?.state.addListener({
        complete(entry: ITrackEntry) {
          return resolve(true);
        }
      });

    });

  };

  continueAnim = async (): Promise<boolean> => {
    return new Promise((resolve) => {
      this.background?.state.setAnimation(1, "2_anim", true);

      this.background?.state.addListener({
        end(entry: ITrackEntry) {
          return resolve(true)

        }
      });
      return resolve;

    });

  };

  endAnim = async (): Promise<boolean> => {
    return new Promise((resolve) => {
      this.background?.state.setAnimation(1, "3_finish", false);
      setTimeout(() => {
        const widthValue = this.value?.width
        let animation2 = gsap.timeline();
        let animation = gsap.timeline();
        animation.fromTo(
            this.value!,
            0.55,
            {x: -this.value!.width/2},
            {x: 0, repeat: 0}
        );

        animation2.fromTo(
            this.value!,
            0.55,
            {width: widthValue},
            {width: 0, repeat: 0}
        )
      }, 300)
      setTimeout(() => {
        this.value?.removeChildren()
      }, 650)

      setTimeout(() => {
        return resolve(true)
      }, 850)

    });

  };

  openModal = async () => {
    this.isModalOpen = true;
    const timer = setTimeout(() => {
      this.game.soundController.popup_fs_out?.play()
      clearTimeout(timer)
    }, 150)

    await this.startAnim()
    await this.continueAnim()

  }

  showModal = async (isActive: boolean) => {
    if (isActive) {
      this.modalWrapper.visible = true;
      this.game.gameContainer.interactiveChildren = false;
      this.openModal()
    } else {
      this.game.assetsController?.changeGameType(false)
      this.modalWrapper.visible = false;
      this.game.gameContainer.interactiveChildren = true;
    }
  };

  closeModal = async () => {
    this.game.soundController.backgroundSound?.play()
    this.game.soundController.backgroundFSSound?.stop()
    await this.endAnim()
    this.isModalOpen = false;
    this.showModal(false)
    // this.game.assetsController!.playButton!.buttonLabel.alpha = 0;
    // this.game.assetsController!.playButton!.buttonBorderFS.alpha = 0;
    // this.game.assetsController!.playButton!.buttonBorder.alpha = 1;
    // this.game.assetsController!.playButton!.buttonStop.alpha = 1;
    // this.value?.removeChildren()
    // if(this.stopIfFSWon === false){
    //   this.game.assetsController?.autoPlayModal?.startAutoPlay(this.game.assetsController.autoPlayButton!.autoSpinCount)
    // }
  }

  setupButtons = async () => {

    this.confirmButton = new CustomButton(
      this.game,
      this.app,
      'confirmButtonDefault',
      'confirmButtonHover',
      'confirmButtonDisabled',
      270,
      70,
      ''
    );


    this.confirmButton.button.on('touchstart', () => {
      this.confirmButton!.button.buttonMode = false;
      this.confirmButton!.button.interactive = false;
      this.closeModal()
    })
    this.confirmButton.button.on('click', () => {
      this.confirmButton!.button.buttonMode = false;
      this.confirmButton!.button.interactive = false;
      this.closeModal()
    })

    await this.confirmButton.initialize();

    this.confirmButton.button.x = -this.confirmButton.button.width/2;
    this.confirmButton.button.y = -170;
    this.confirmButton.button.alpha=0;

    this.background?.addChild(this.confirmButton.button!);
  };

  doPointerDown = () => {
    this.showModal(false)
  };

  leftClick = (e: any) => {
    if (e.data.button === 0) {
      this.showModal(false)
    };
  }


  changeWinFinishScale(scalingX: number, scalingY: number) {
    if (!this.modal) return;
    this.modal.scale.x = scalingX;
    this.modal.scale.y = scalingY;
    this.windowBackground.width = window.innerWidth;
    this.windowBackground.height = window.innerHeight;
  }

  changeWinFinishPosition(x: number, y: number) {
    if (!this.modal) return;
    this.modal.x = x;
    this.modal.y = y;
  }

  changeWinFinishSize(x: number, y: number) {
    if (!this.modal) return;
    this.modal.width = x;
    this.modal.height = y;
  }
}

export default WinFinishPopUp;
