import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';
import CustomButton from '../CustomButton';

class OutOfMoneyModal {
	app: Application;
	game: PixiGame;
	modalWrapper: PIXI.Container;
	windowBackground: PIXI.Graphics;
	modal: PIXI.Container;
	background: PIXI.Graphics;
	title: PIXI.Text;
	subTitle: PIXI.Text;
	closeButton?: CustomButton;
	confirmButton?: CustomButton;
	isModalOpen: boolean;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.isModalOpen = false;
		this.modal = new PIXI.Container();
		this.modalWrapper = new PIXI.Container();
		this.background = new PIXI.Graphics();
		this.windowBackground = new PIXI.Graphics();
		this.title = new PIXI.Text('Out of Money', {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 53,
			fill: '#E2F4F2',
		});
		this.subTitle = new PIXI.Text(
			'Please deposit more money to continue playing',
			{
				fontFamily: 'Rubik',
				fontSize: 28,
				fontWeight: '700',
				lineHeight: 34,
				fill: '#E2F4F2',
			}
		);
	}

	initialize = async () => {
		await this.setupButtons();
		this.modalWrapper.x = 0
		this.modalWrapper.y = 0
		this.modalWrapper.width = this.app.screen.width;
		this.modalWrapper.height = this.app.screen.height + 32;
		this.modalWrapper.visible = false;
		this.modalWrapper.zIndex = 10000;

		this.modal.width = 809;
		this.modal.height = 439;
		
		this.windowBackground.beginFill(0x151515);
		this.windowBackground.drawRect(0, 0, this.app.screen.width, this.app.screen.height + 32);
		this.windowBackground.endFill();
		this.windowBackground.alpha = 0.8;
		
		this.background.beginFill(0x151515);
		this.background.drawRoundedRect(0, 0, 809, 439, 10);
		this.background.endFill();
		
		this.windowBackground.zIndex = 1
		this.modal.zIndex = 15
		
		this.title.x = this.background.width / 2 - this.title.width / 2;
		this.title.y = 87;
		
		this.subTitle.x = this.background.width / 2 - this.subTitle.width / 2;
		this.subTitle.y = 164;
		
		this.modal.x = (window.innerWidth - 809) / 2;
		this.modal.y = (this.game.gameContainer.height - 439) / 2
		
		this.app.stage.addChild(this.modalWrapper);
		this.modalWrapper.addChild(this.windowBackground!);
		this.modalWrapper.addChild(this.modal!);
		this.modal.addChild(this.background!);
		this.background.addChild(this.title!);
		this.background.addChild(this.subTitle!);
	};
	
	showModal = (isActive: boolean) => {
		let adaptiveRatio = window.innerWidth / window.innerHeight;
		this.modal.y = (this.game.gameContainer.height - this.modal.height) / 2
		if (adaptiveRatio < 1) {
			
			if(window.innerHeight > 1000){
				this.modal.scale.set(0.85);
				this.modal.x = (window.innerWidth - this.modal.width) / 2;
			} else {
				this.modal.scale.set(0.45)
				this.modal.x = (window.innerWidth - this.modal.width) / 2;
			}
		} else if (adaptiveRatio > 1.78 && window.innerWidth > 1280) {

		} else if (adaptiveRatio < 1.78 && window.innerWidth < 1280) {
			this.modal.scale.set(0.85)
			this.modal.x = (window.innerWidth - this.modal.width) / 2;
			this.modal.y = (this.game.gameContainer.height - this.modal.height) / 2
		} else {

		}

		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
		}
	};

	setupButtons = async () => {
		this.closeButton = new CustomButton(
			this.game,
			this.app,
			'closeButtonDefault',
			'closeButtonHover',
			'closeButtonDisabled',
			60,
			60
		);
		this.confirmButton = new CustomButton(
			this.game,
			this.app,
			'confirmButtonDefault',
			'confirmButtonHover',
			'confirmButtonDisabled',
			128,
			60,
			'OK'
		);

		this.closeButton.button.on('touchstart', () => this.showModal(false))
		this.confirmButton.button.on('touchstart', () => this.showModal(false))

		this.closeButton.button.on('click', (e) => this.leftClick(e))
		this.confirmButton.button.on('click', (e) => this.leftClick(e))

		await this.closeButton.initialize();
		await this.confirmButton.initialize();

		this.closeButton.button.x = 809 - 45;
		this.closeButton.button.y = -15;

		this.confirmButton.button.x = 809 / 2 - this.confirmButton.button.width / 2;
		this.confirmButton.button.y = 250;

		this.background.addChild(this.closeButton.button!);
		this.background.addChild(this.confirmButton.button!);
	};

	leftClick = (e:any) => {
		if(e.data.button === 0){
			this.showModal(false)
		};
	}

	changeOutOfMoneyModalScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeOutOfMoneyModalPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}

	changeOutOfMoneyModalSize(x: number, y: number) {
		if (!this.modal) return;
		this.modal.width = x;
		this.modal.height = y;
	}
}

export default OutOfMoneyModal;
