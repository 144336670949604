import {
	SettingModalActionEnum,
	SetSettingModalAction,
} from './types';

export const SettingModalActionCreators = {
	setSettingModalIsOpen: (payload: boolean): SetSettingModalAction => ({
		type: SettingModalActionEnum.SET_MODAL,
		payload
	}),
};
