import {
	Button,
	ButtonProps,
	createPolymorphicComponent,
	TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FC } from 'react';
import styled from 'styled-components';

interface IProps {
	closeModal: () => void;
}

const DebugPlayModal: FC<IProps> = ({ closeModal }) => {
	const saveReelsSet = () => {
		closeModal();
		localStorage.setItem('debugReels', JSON.stringify(form));
	};

	const cleanDebugReels = () => {
		localStorage.removeItem('debugReels');
	};

	const form = useForm({
		initialValues: {
			positions: '',
			wilds: '',
		},
		validate: {
			positions: value => (value.length < 1 ? 'Cannot be empty' : null),
			wilds: value => (value.length < 1 ? 'Cannot be empty' : null),
		},
	});

	return (
		<Form onSubmit={form.onSubmit(saveReelsSet)}>
			<FormTitle>Try your luck</FormTitle>
			<FormInput placeholder='Positions' {...form.getInputProps('positions')} />
			<FormInput placeholder='Wilds' {...form.getInputProps('wilds')} />
			<ButtonsWrapper>
				<SubmitButton type='submit' mr='sm'>
					Save
				</SubmitButton>
				<SubmitButton onClick={cleanDebugReels}>Remove</SubmitButton>
			</ButtonsWrapper>
		</Form>
	);
};

export default DebugPlayModal;

const Form = styled.form`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 600px;
	height: 250px;
	padding: 25px;
	border-radius: 4px;
`;

const FormTitle = styled.h2`
	font-family: 'Rubik', sans-serif;
	text-align: center;
	padding-bottom: 10px;
	color: #fff;
	font-size: 28px;
	font-weight: 700;
`;

const FormInput = styled(TextInput)`
	position: relative;
	flex: auto;
	.mantine-InputWrapper-error {
		font-family: 'Rubik', sans-serif;
		position: absolute;
		bottom: 2;
		left: 0;
	}
	input {
		font-family: 'Rubik', sans-serif;
		padding: 0;
		border: none;
		border-radius: 0;
		background: transparent;
		border-bottom: 1px solid #ced4da;
		font-size: 14px;
		font-weight: 400;
		color: #fff;
	}
`;

const ButtonsWrapper = styled.div`
	display: flex;
`;

const _SubmitButton = styled(Button)`
	font-family: 'Rubik', sans-serif;
	width: 100%;
`;

const SubmitButton = createPolymorphicComponent<'button', ButtonProps>(
	_SubmitButton
);
