import { FC, useState, useCallback } from 'react';
import styled from 'styled-components';
import { respondFrom, respondTo } from '../../../styles/mixins';
import { PixiGame } from '../../../features/Game';
import StopAutoPlay from './StopAutoPlay';
import LossLimit from './LossLimit';
import SingleWinLimit from './SingleWinLimit';
import NumberOfSping from './NumberOfSping';
import { EventType, useEventReceiver } from '../../../GameEventBus';
import AutoPlayHeader from './AutoPlayHeader';

import advancedSettingsIcon from '../../../assets/icons/arrow-down-small.svg';

interface IAutoPlayModal {
	game: PixiGame;
}

const AutoPlayModal: FC<IAutoPlayModal> = ({ game }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [isAdvancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);
	const [spinCount, setSpinCount] = useState<number | string>('OFF');
	const [onAnyWin, setOnAnyWin] = useState(false);
	const [isFreeSpinWon, setIsFreeSpinWon] = useState(false);
	const [lossLimit, setLossLimit] = useState<string>('NO LIMIT');
	const [singleWinLimit, setSingleWinLimit] = useState<string>('NO LIMIT');

	useEventReceiver<number | string>(EventType.GAME_AUTO_SPIN_COUNT, value => {
		setSpinCount(value);
	});

	// const changeSpinCount = useCallback(
	// 	(count: number | string) => {
	// 		game.assetsController?.autoPlayModal?.startAutoPlay('OFF')
	// 		setSpinCount(count);
	// 		game.soundController.btn_ui?.play()
	// 		game.slotMachine!.refreshLimits = true
	// 		game.assetsController?.autoPlayModal?.setSpinCounter(count);
	// 	},
	// 	[setSpinCount, game]
	// );

	const stopOnAnyWin = useCallback(
		(check: boolean) => {
			game.assetsController?.autoPlayModal?.setStopOnAnyWin(check)
			game.soundController.btn_ui?.play()
			setOnAnyWin(check);
		},
		[setOnAnyWin]
	);

	const stopIsFreeSpinWon = useCallback(
		(check: boolean) => {
			game.assetsController?.autoPlayModal?.setStopIsFreeSpinWon(check)
			game.soundController.btn_ui?.play()
			setIsFreeSpinWon(check);
		},
		[setIsFreeSpinWon]
	);

	const changeLossLimit = useCallback(
		(limit: string) => {
			if(!game.slotMachine) return
			game.assetsController?.autoPlayModal?.setLossLimitValue(limit)
			game.soundController.btn_ui?.play()
			game.slotMachine.refreshLimits = true
			setLossLimit(limit);
		},
		[setLossLimit]
	);

	const changeSingleWinLimit = useCallback(
		(limit: string) => {
			game.assetsController?.autoPlayModal?.setSingleWinLimitValue(limit)
			game.soundController.btn_ui?.play()
			setSingleWinLimit(limit);
		},
		[setSingleWinLimit]
	);
	// TODO: отключил так как модалка есть во враппере
	// const startAutoPlay = useCallback(() => {
	// 	game.assetsController?.autoPlayModal?.startAutoPlay(spinCount);
	// 	if (spinCount !== 'OFF') {
	// 		game.soundController.btn_ui?.play()
	// 		showModal(false);
	// 	}
	// }, [game, spinCount]);

	const showModal = (isOpen: boolean) => {
		setModalOpen(isOpen);
		game.assetsController?.isInteractiveActive(!isOpen);
		if (game.assetsController && game.assetsController.autoPlayButton) {
			game.assetsController.autoPlayButton.isModalActive = isOpen;
		}
	};

	const toggleAdvancedSettings = useCallback((status: boolean) => {
		game.soundController.btn_ui?.play()
		setAdvancedSettingsOpen(status)
	}, [setAdvancedSettingsOpen])

	if(game.assetsController?.autoPlayModal?.stopSpin){
		game.assetsController?.autoPlayButton?.button.on('click', e =>
			leftClickAutoPlayButton(e)
		);
		game.assetsController?.autoPlayButton?.button.on('touchstart', () =>
			showModal(true)
		);
	}

	const leftClickAutoPlayButton = (e:any) => {
		if(e.data.button === 0){
			showModal(true)
		};
	}

	const changeButtonState = () => {
		if(game.assetsController!.autoPlayModal!.isAutoPlayRunning === false) {
			game.slotMachine?.changeButtonState(false);
		}
	}

	const closeModal = useCallback(() => {
		game.soundController.btn_ui?.play()
		showModal(false)
		changeButtonState()
	}, [showModal])

	return (
		<Wrapper style={isModalOpen ? { display: 'flex' } : { display: 'none' }}>
			<Container>
				<div>
					{/* <AutoPlayHeader
						spinCount={spinCount}
						closeModal={closeModal}
						startAutoPlay={startAutoPlay}
						game={game}
					/>

					<NumberOfSping
						spinCount={spinCount}
						changeSpinCount={changeSpinCount}
					/> */}
					<FooterWrapper>
						<FooterTitle>ADVANCED SETTINGS</FooterTitle>
						<FooterButton
							style={isAdvancedSettingsOpen ? {} : { transform: 'rotate(0)' }}
							onClick={() => toggleAdvancedSettings(!isAdvancedSettingsOpen)}
						>
							<img src={advancedSettingsIcon} alt='Arrow' />
						</FooterButton>
					</FooterWrapper>
				</div>
				<AdvancedSettingsWrapper
					style={
						isAdvancedSettingsOpen ? { display: 'flex' } : { display: 'none' }
					}
				>
					<StopAutoPlay
						onAnyWin={onAnyWin}
						stopOnAnyWin={stopOnAnyWin}
						isFreeSpinWon={isFreeSpinWon}
						stopIsFreeSpinWon={stopIsFreeSpinWon}
					/>
					<LossLimit lossLimit={lossLimit} changeLossLimit={changeLossLimit} />
					<SingleWinLimit
						singleWinLimit={singleWinLimit}
						changeSingleWinLimit={changeSingleWinLimit}
					/>
				</AdvancedSettingsWrapper>
			</Container>
		</Wrapper>
	);
};

export default AutoPlayModal;

const Wrapper = styled.div`
	padding: 38px 6px 6px;
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(21, 21, 21, 0.8);
	left: 0;
	top: 0;
	align-items: flex-start;
	justify-content: flex-end;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	${respondFrom.sm`
		align-items: flex-end;
		justify-content: center;
	`}
	${respondTo.lg`
		align-items: center;
		justify-content: center;
	`}
`;

const Container = styled.div`
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	background-color: #151515;
	padding: 8px 9px 10px;
	border: 1px solid #2a2c2b;
	border-radius: 15px;
	width: 304px;
	max-height: 100%;
	${respondFrom.sm`
		max-width: 360px;
		width: 100%;
	`}
`;

const FooterTitle = styled.div`
	font-family: 'Rubik';
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	color: #7c8584;
	flex: auto;
	text-align: center;
`;

const FooterWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 30px;
	width: 100%;
	position: relative;
	margin-top: 16px;
`;

const FooterButton = styled.div`
	transform: rotate(180deg);
	transition: all ease 0.2s;
	position: absolute;
	right: 7px;
	cursor: pointer;
`;

const AdvancedSettingsWrapper = styled.div`
	flex-direction: column;
`;
