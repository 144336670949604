import { WrapperOptions, DevicePlatform, UserLocale, PopupsConfig, FeatureRegistry } from "@playmer/ui-wrapper";

export class ConfigResolver {
	readonly options = { title: 'Wrath of the Ronin',
	gameCode: 'playmer_wrath_of_the_ronin',
	spinOnSpaceBar: true,
	uiAutoBind: true,
	balance: 95,
	win: 100000.03,
	platform: 'desktop',
	locale: 'en',
	bet: {
	  steps: [5, 10, 20, 50, 100],
	  default: 5
	},
	popups: {
	  rules: {
		type: "iframe",
	  },
	  paytable: {
		type: "iframe"
	  }
	},
	features: {
	  "BUY_BONUS": 'true',
	  "ANTE_BET":  'true',
	  "SPIN_COOL_DOWN": '0',
	  "ELAPSED_PROGRESS": 'true',
	  "AUTO_PLAY": 'true',
	  "FORCE_STOP_SPIN": 'true',
	  "CURRENCY_CODE": 'USD',
	  "TURBO_SPIN": 'true',
	  "REALITY_CHECK": 'true',
	  "JURISDICTION_CODE": 'CU',
	  "RTP": '96'
	},
	freeSpinOffers: [
	  {
		type: 'float',
		cost: 20000,
		options: [2, 4, 5, 10, 20],
		expired: 1000*60*60*12,
		offerID: 'qwer124'
	  },
	  {
		type: 'float',
		cost: 20000,
		options: [2, 4, 5, 10, 20],
		expired: 1000*60*60*12,
		offerID: 'qwer124'
	  }
	]} as WrapperOptions;

	/**
	 * Устанавливает название игры
	 * @param title Название игры
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.setTitle("Candy Slot");
	 * ```
	 */
	setTitle(title: string) {
		this.options.title = title;
	}

	/**
	 * Устанавливает селектор канваса
	 * @param selector Селектор канваса
	 * @default "#game-canvas"
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.setCanvasQuerySelector("#game-canvas");
	 * ```
	 * @see https://developer.mozilla.org/ru/docs/Web/API/Document/querySelector
	 */
	setGameIdentificator(selector: string) {
		this.options.gameCode = selector;
	}

	/**
	 * Устанавливает баланс игрока
	 * @param balance Баланс игрока
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.setBalance(1000);
	 * ```
	 */
	setBalance(balance: number) {
		this.options.balance = balance;
	}

	/**
	 * Устанавливает значения ставок игры
	 * @param steps Список значений, которые можно выбрать с помощью кнопок + (больше), - (меньше) в качестве ставки для следующей игры.
	 * @param default Значение ставки по умолчанию (должно быть одним из значений шагов).
	 *
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.setBetSteps([1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500], 1);
	 * ```
	 */
	setBetSteps(steps: number[], defaultStep: number) {
		this.options.bet.steps = steps;
		this.options.bet.default = defaultStep;
	}

	/**
	 * Устанавливает локализацию UI
	 * @param locale Локализация
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.setLocale("en");
	 * ```
	 */
	setLocale(locale: UserLocale) {
		this.options.locale = locale;
	}

	/**
	 * Устанавливает валюту отображаемую в UI
	 * @param currency Валюта
	 * @example
	 * ```typescript
	 * import { UI } from "@/ui";
	 *
	 * UI.setCurrency("USD");
	 * ```
	 * @see http://161.35.76.219/v0.x/config.html#currency
	 */
	setCurrency(currency: string) {
		this.options.features.CURRENCY_CODE = currency;
	}

	setPlatform(platform: "desktop" | "mobile") {
		this.options.platform = platform;
	}

    /**
     * Устанавливает значение выигрыша
     * @param win Значение выигрыша
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     * 
     * UI.setWin(100);
     * ```
     */
    setWin(win: number) {
        this.options.win = win;
    }

    /**
     * Привязка кнопок UI к попапам, к примеру:
	 * Нажатие на значок настроек — открывает всплывающее окно настроек.
     * @param uiAutoBind 
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     * 
     * UI.setUiAutoBind(true);
     * ```
     */
    setUiAutoBind(uiAutoBind: boolean) {
        this.options.uiAutoBind = uiAutoBind;
    }

    /**
     * Запуск спина при нажатии на кнопку Space
     * @param uiAutoBind 
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     * 
     * UI.spinOnSpaceBar(true);
     * ```
     */
    setSpinOnSpaceBar(spinOnSpaceBar: boolean) {
        this.options.spinOnSpaceBar = spinOnSpaceBar;
    }

    /**
     * Запуск спина при нажатии на кнопку Space
     * @param uiAutoBind 
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     * 
     * UI.popups(
	 *  rules: {
			url: '/your_url', //optional
			type: "iframe",
		},
		paytable: {
			type: "iframe"
		}
	 * );
     * ```
     */
    setPopups(popups: PopupsConfig) {
        this.options.popups = popups;
    }

    /**
     * Запуск спина при нажатии на кнопку Space
     * @param uiAutoBind 
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     * 
     * UI.features(
	 *  features: {
			"BUY_BONUS": 'true',
			"ANTE_BET":  'true',
			"SPIN_COOL_DOWN": '0',
			"ELAPSED_PROGRESS": 'true',
			"AUTO_PLAY": 'true',
			"FORCE_STOP_SPIN": 'true',
			"CURRENCY_CODE": 'USD',
			"TURBO_SPIN": 'true',
			"REALITY_CHECK": 'true',
			"JURISDICTION_CODE": 'CU',
			"RTP": '96'
		},
	 * );
     * ```
     */
    setFeatures(features: FeatureRegistry) {
        this.options.features = features;
    }
}
