import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { settingsModalTitle } from '../../../../styles/pixiStyles';
import { PixiGame } from '../../index';
import SettingsItem from './SettingsItem';

class SettingsModal {
	app: Application;
	game: PixiGame;
	buttons?: any[];
	modal: PIXI.Container;
	background: PIXI.Graphics;
	title: PIXI.Text;
	audio?: SettingsItem;
	visualEffects?: SettingsItem;
	aditionalOptions?: SettingsItem;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.buttons = [];
		this.modal = new PIXI.Container();
		this.background = new PIXI.Graphics();

		this.title = new PIXI.Text('SETTINGS', settingsModalTitle);
	}

	initialize = async () => {
		await this.setupButtons();

		this.modal.x = 150;
		this.modal.y = 750;
		this.modal.width = 304;
		this.modal.height = 260;
		this.modal.visible = false;
		this.modal.zIndex = 1000;

		this.background.beginFill(0x151515);
		this.background.drawRoundedRect(0, 0, 304, 260, 15);
		this.background.endFill();

		this.title.x = this.background.width / 2 - this.title.width / 2;
		this.title.y = 8;

		this.game.gameContainer.addChild(this.modal!);
		this.modal.addChild(this.background!);
		this.background.addChild(this.title!);
	};

	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modal.visible = true;
		} else {
			this.modal.visible = false;
		}
	};

	setupButtons = async () => {
		this.audio = new SettingsItem(this.game, this.app, 'audio');
		this.visualEffects = new SettingsItem(this.game, this.app, 'visualEffects');
		this.aditionalOptions = new SettingsItem(
			this.game,
			this.app,
			'leftHendedMode'
		);

		await this.audio.initialize();
		await this.visualEffects.initialize();
		await this.aditionalOptions.initialize();

		this.buttons?.push(this.audio);
		this.buttons?.push(this.visualEffects);
		this.buttons?.push(this.aditionalOptions);

		this.audio.button.x = 32;
		this.audio.button.y = 40;
		this.visualEffects.button.x = 32;
		this.visualEffects.button.y = 108;
		this.aditionalOptions.button.x = 32;
		this.aditionalOptions.button.y = 176;

		this.buttons?.forEach(btn => this.background.addChild(btn.button));
	};

	changeSettingsModalScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeSettingsModalPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}

	changeSettingsModalSize(x: number, y: number) {
		if (!this.modal) return;
		this.modal.width = x;
		this.modal.height = y;
	}
}

export default SettingsModal;
