import * as PIXI from 'pixi.js'
import x1Img from '../assets/multipliers/x1.png'
import x2Img from '../assets/multipliers/x2.png'
import x3Img from '../assets/multipliers/x3.png'
import x5Img from '../assets/multipliers/x5.png'
import x10Img from '../assets/multipliers/x10.png'
import x20Img from '../assets/multipliers/x20.png'
import x30Img from '../assets/multipliers/x30.png'
import x50Img from '../assets/multipliers/x50.png'
import x100Img from '../assets/multipliers/x100.png'
export const wildMultipliers: any =  {
    x1: PIXI.Texture.from(x1Img),
    x2: PIXI.Texture.from(x2Img),
    x3: PIXI.Texture.from(x3Img),
    x5: PIXI.Texture.from(x5Img),
    x10: PIXI.Texture.from(x10Img),
    x20: PIXI.Texture.from(x20Img),
    x30: PIXI.Texture.from(x30Img),
    x50: PIXI.Texture.from(x50Img),
    x100: PIXI.Texture.from(x100Img),
}
export const wildMultiplierImages: any =  {
    x1: x1Img,
    x2: x2Img,
    x3: x3Img,
    x5: x5Img,
    x10: x10Img,
    x20: x20Img,
    x30: x30Img,
    x50: x50Img,
    x100: x100Img,
}


