import { FC } from 'react';
import styled from 'styled-components';
import { singleWinLimitList } from './constants';

interface ISingleWinLimit {
	singleWinLimit: string | number;
	changeSingleWinLimit: Function;
}

const SingleWinLimit: FC<ISingleWinLimit> = ({
	singleWinLimit,
	changeSingleWinLimit,
}) => {
	return (
		<Wrapper>
			<Title>SINGLE WIN LIMIT</Title>
			<Subtitle>Expressed as multiplier of total bet</Subtitle>
			<ButtonWrapper>
				{singleWinLimitList.map(item => (
					<Button
						className={
							singleWinLimit === item.singleWinLimitNumber ? 'active' : ''
						}
						key={item.singleWinLimitNumber}
						onClick={() => changeSingleWinLimit(item.singleWinLimitNumber)}
					>
						{item.singleWinLimitNumber}
					</Button>
				))}
			</ButtonWrapper>
		</Wrapper>
	);
};

export default SingleWinLimit;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding-top: 16px;
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 30px;
	background: #2a2c2b;
	border-radius: 5px;
	font-family: 'Rubik';
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	color: #7c8584;
	margin-bottom: 8px;
`;

const Subtitle = styled.div`
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	display: flex;
	justify-content: center;
	color: #7c8584;
	margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	.active {
		color: #e2f4f2;
		border-color: #e2f4f2;
	}
`;

const Button = styled.button`
	border: 2px solid #535858;
	border-radius: 5px;
	width: 43px;
	height: 32px;
	color: #535858;
	background: #151515;
	font-family: 'Rubik';
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	cursor: pointer;
	&:hover {
		color: #e2f4f2;
		border-color: #e2f4f2;
	}
`;
