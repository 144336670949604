import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { EventType, useEventReceiver } from '../../../../GameEventBus';
import {
	respondFrom,
	respondHeightFrom,
	respondTo,
} from '../../../../styles/mixins';

import backgroundLandscape from '../../assets/loading_bar_desktop.png';
import backgroundPortrait from '../../assets/loading_bar_portrait.png';
import logo from '../../assets/logo_horisontal.png';
import wildImg from '../../assets/wild.png';
import { Howl } from 'howler';
import { PixiGame } from '../../index';
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

interface IProps {
	game: PixiGame;
	setAssetsLoaded: (value: boolean) => void;
}

const GameLoaderOverlay: FC<IProps> = ({ game, setAssetsLoaded }) => {
	const [loaderBarWidth, setLoaderBarWidth] = useState(100);
	const [loaderBarBorderRadius, setLoaderBarBorderRadius] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [bgSound, setBgSound] = useState<Howl | undefined>();
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const { totalBet } = useTypedSelector(state => state.auth);

	// game.assetsController?.betInput?.setTotalBetList(totalBet);
	// game.assetsController?.betInputLandscape?.setTotalBetList(totalBet);
	// game.assetsController?.betInputPortret?.setTotalBetList(totalBet);

	useEffect(() => {
		if (window.localStorage.getItem('IsLeftHandedModeOn') === 'true') {
			game.adaptive?.enableLeftHandMode(true);
		} else {
			window.localStorage.setItem('IsLeftHandedModeOn', 'false');
			game.adaptive?.enableLeftHandMode(false);
		}
	}, [game.adaptive])

	useEventReceiver<boolean>(EventType.GAME_ASSETS_LOADED, value => {
		setIsDataLoading(value);
	});

	useEventReceiver<Howl>(EventType.BG_SOUND, value => {
		if (value) setBgSound(value);
	});

	const loadGame = useCallback(() => {
		if (isDataLoading && !isLoading) {
			setAssetsLoaded(true);
			bgSound?.play();
		}
	}, [bgSound, isDataLoading, isLoading, setAssetsLoaded]);

	useEffect(() => {
		let i = 0;
		function load() {
			if (i === 0) {
				i = 1;
				let id = setInterval(frame, 10);
				function frame() {
					if (game.app.loader.progress <= 25) {
						setLoaderBarBorderRadius(30);
					} else if (game.app.loader.progress <= 50) {
						setLoaderBarBorderRadius(40);
					} else if (game.app.loader.progress <= 75) {
						setLoaderBarBorderRadius(50);
					} else if (game.app.loader.progress <= 100) {
						setLoaderBarBorderRadius(60);
					}
					if (game.app.loader.progress === 100) {
						clearInterval(id);
						i = 0;
						setIsLoading(false);
					} else {
						setLoaderBarWidth(game.app.loader.progress);
					}
				}
			}
		}
		game.adaptive?.fixGameContainer();
		load();
	}, [isLoading, game.app.loader.progress]);

	const onImageLoad = useCallback(() => {
		setIsImageLoaded(true);
	}, [setAssetsLoaded]);

	return (
		<Wrapper
			onClick={loadGame}
			style={!isLoading ? { cursor: 'pointer' } : { cursor: 'auto' }}
		>
			<Logo>
				<img onLoad={onImageLoad} src={logo} alt='Logo' draggable={false} />
			</Logo>
			<Title>WILDS WITH UP TO x100 MULTIPLIER</Title>
			<WildImage>
				<img src={wildImg} alt='Wild' draggable={false} />
			</WildImage>

			{isImageLoaded && (
				<Loader
					style={{
						display: isLoading ? 'flex' : 'none',
						justifyContent: 'center',
					}}
				>
					<LoaderBody>
						<LoaderBar className={game.app.loader.progress > 0 ? '':'none'} $width={loaderBarWidth} $radius={loaderBarBorderRadius}>
							<LoaderBarHighlight></LoaderBarHighlight>
						</LoaderBar>
						<LoaderTitle>Loading</LoaderTitle>
					</LoaderBody>
				</Loader>
			)}
			<MobileView>
				{!isLoading && (
					<LoaderComplete>Tap anywhere to continue</LoaderComplete>
				)}
			</MobileView>
			<DesktopView>
				{!isLoading && (
					<LoaderComplete>Click anywhere to continue</LoaderComplete>
				)}
			</DesktopView>
		</Wrapper>
	);
};

export default GameLoaderOverlay;

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 30px;
	z-index: 9999;
	background-image: url(${backgroundPortrait});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	${respondHeightFrom.sm`
		padding: 10px 20px;
	`}
	${respondTo.lg`
		background-image: url(${backgroundLandscape});
	`}
`;

const Logo = styled.div`
	text-align: center;
	img {
		width: 100%;
		height: 100%;
	}
	${respondHeightFrom.sm`
		img {
			width: 95%;
			height: 95%;
		}
	`}
	${respondTo.lg`
		img {
			width: 100%;
			height: 100%;
		}
	`}
`;

const Title = styled.div`
	text-align: center;
	padding: 15px 5px;
	background: linear-gradient(180deg, #bb2385, #062f8d);
	font-size: 30px;
	font-weight: 700;
	font-family: 'Phonk Regular';
	-webkit-background-clip: text;
	-webkit-text-stroke: 5px transparent;
	color: #fff;
	${respondHeightFrom.sm`
		font-size: 16px;
		padding: 0;
	`}
	${respondFrom.sm`
		padding: 15px 5px;
		font-size: 18px;
		-webkit-text-stroke: 3px transparent;
	`}
	${respondTo.lg`
		font-size: 50px;
		padding: 25px 5px;
	`}
`;

const WildImage = styled.div`
	max-width: 250px;
	max-height: 250px;
	img {
		height: 100%;
		width: 100%;
	}
	${respondHeightFrom.sm`
		max-width: 100px;
		max-height: 100px;
	`}
	${respondFrom.sm`
		max-width: 150px;
		max-height: 150px;
	`}
	${respondTo.lg`
		max-width: 320px;
		max-height: 320px;
	`}
`;

const Loader = styled.div`
	margin-top: 15px;
	width: 100%;
	height: 100%;
	max-width: 875px;
	max-height: 80px;
	min-height: 70px;
	background: #fff;
	border-radius: 60px;
	padding: 10px;
	justify-content: center;
	text-align: center;
	${respondHeightFrom.sm`
		max-width: 600px;
		max-height: 60px;
		min-height: 50px;
		margin-top: 1px;
	`}
	${respondFrom.sm`
		max-height: 70px;
		min-height: 60px;
	`}
`;

const LoaderBody = styled.div`
	flex: auto;
	background: linear-gradient(180deg, #7485b6, #98b4e2, #7485b6);
	border-radius: 60px;
	position: relative;
	display: flex;
	align-items: center;
`;

const LoaderTitle = styled.div`
	padding: 0 5px;
	position: absolute;
	left: calc(50% - 86px);
	z-index: 2;
	font-size: 30px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: 'Phonk Regular';
	background: linear-gradient(180deg, #bb2385, #062f8d);
	-webkit-background-clip: text;
	-webkit-text-stroke: 5px transparent;
	color: #fff;
	${respondHeightFrom.sm`
		font-size: 16px;
		left: calc(50% - 51.5px);
		-webkit-text-stroke: 3px transparent;
	`}
	${respondFrom.sm`
		font-size: 18px;
		left: calc(50% - 51.5px);
		-webkit-text-stroke: 3px transparent;
	`}
	${respondTo.lg`
		font-size: 50px;
		left: calc(50% - 144px);
	`}
`;

interface ILoaderBar {
	$width: number;
	$radius: number;
}

const LoaderBar = styled.div<ILoaderBar>`
	padding: 4px 0px 0;
	border: 1px solid #305f8f;
	display: flex;
	justify-content: center;
	z-index: 1;
	background: linear-gradient(180deg, #f53dfc, #6b048d);
	width: ${props => props.$width}%;
	height: 100%;
	border-radius: 60px;
	border-top-right-radius: ${props => props.$radius}px;
	border-bottom-right-radius: ${props => props.$radius}px;
	&.none{
		display: none;
	}
`;

const LoaderBarHighlight = styled.div`
	margin: 0 15px;
	z-index: 1;
	background: rgba(255, 255, 255, 0.5);
	width: 95%;
	height: 25%;
	border-radius: 10px;
`;

const LoaderComplete = styled.div`
	text-align: center;
	z-index: 2;
	font-size: 30px;
	padding: 28px 5px;
	font-weight: 600;
	font-family: 'Phonk Regular';
	background: linear-gradient(180deg, #bb2385, #062f8d);
	-webkit-text-stroke: 5px transparent;
	-webkit-background-clip: text;
	color: #fff;
	${respondHeightFrom.sm`
		padding: 24px 5px;
		font-size: 18px;
		-webkit-text-stroke: 3px transparent;
	`}
	${respondFrom.sm`
		padding: 31px 5px;
		font-size: 18px;
		-webkit-text-stroke: 3px transparent;
		`}
	${respondTo.lg`
		font-size: 50px;
		padding: 16px 5px;
	`}
`;

const MobileView = styled.div`
	${respondTo.smTo`
		display: none;
	`}
`;

const DesktopView = styled.div`
	${respondFrom.sm`
		display: none;
	`}
`;
