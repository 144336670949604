import * as PIXI from 'pixi.js';
import PlayButton from '../components/PlayButton';
import { PixiGame } from '../index';
import { Application } from 'pixi.js';
import AutoPlayButton from '../components/AutoPlayButton';
import InfoButton from '../components/InfoButton';
import { InfoText } from '../components/InfoText';
import BuyBonusButton from '../components/BuyBonusButton';
import DoubleBonusChanceButton from '../components/DoubleBonusChance';
import SettingsButton from '../components/SettingsButton';
import { ReelsContainer } from '../components/ReelsContainer';
import BetButton from '../components/BetButton';
import AutoPlayModal from '../components/AutoPlayModal';
import SettingsModal from '../components/SettingsModal';
import BetModal from '../components/BetModal';
import { Spine } from "pixi-spine";
import OutOfMoneyModal from '../components/OutOfMoneyModal';
import { MultiplierItem } from "../components/MultiplierButton";
import {MultiplierItemMobile} from "../components/MultiplierButtonMobile";
import WinModal from '../components/WinModal';
import BuyBonusModal from '../components/BuyBonusModal';
import BuyMaxBonusModal from '../components/buyMaxBonusModal';
import BuyMinBonusModal from '../components/buyMinBonusModal';
import BuyBonusModalPortrait from '../components/BuyBonusModalPortrait';
import BuyMaxBonusModalPortrait from '../components/BuyMaxBonusModalPortrait';
import BuyMinBonusModalPortrait from '../components/BuyMinBonusModalPortrait';
import WinFinishPopUp from '../components/WinFinishPopUp.ts';
import WinStartPopUp from "../components/WinStartPopUp.ts";
import BaseAmount from '../components/BaseAmount';
import {Antisipator} from "./Antisipator";
import {WinLine} from "./WinLine";

export class AssetsController {
    game: PixiGame;
    app: Application;
    background?: Spine;
    backgroundFS?: Spine;
    isFs?: boolean;
    isSpacePressed?: boolean;
    logo?: PIXI.Sprite;
    logoPortrait?: PIXI.Sprite;
    playButton?: PlayButton;
    autoPlayButton?: AutoPlayButton;
    buyBonusButton?: BuyBonusButton;
    doubleBonusChanceButton?: DoubleBonusChanceButton;
    infoBar: PIXI.Container;
    infoBarMobileBG?: PIXI.Sprite;
    infoBarMobilePortretBG?: PIXI.Sprite;
    infoBarMobile: PIXI.Container;
    infoBarMobilePortret: PIXI.Container;
    infoButton?: InfoButton;

    multiplierX1?: MultiplierItem;
    multiplierX2?: MultiplierItem;
    multiplierX3?: MultiplierItem;
    multiplierX5?: MultiplierItem;
    multiplierX10?: MultiplierItem;
    multiplierX20?: MultiplierItem;
    multiplierX30?: MultiplierItem;
    multiplierX50?: MultiplierItem;
    multiplierX100?: MultiplierItem;
    multiplierBar?: PIXI.Container;
    multiplierArray?: MultiplierItem[];

    multiplierX1Mobile?: MultiplierItemMobile;
    multiplierX2Mobile?: MultiplierItemMobile;
    multiplierX3Mobile?: MultiplierItemMobile;
    multiplierX5Mobile?: MultiplierItemMobile;
    multiplierX10Mobile?: MultiplierItemMobile;
    multiplierX20Mobile?: MultiplierItemMobile;
    multiplierX30Mobile?: MultiplierItemMobile;
    multiplierX50Mobile?: MultiplierItemMobile;
    multiplierX100Mobile?: MultiplierItemMobile;
    multiplierBarMobile?: PIXI.Container;
    multiplierMobileArray?: MultiplierItemMobile[];

    betButton?: BetButton;
    betInput?: InfoText;
    winInput?: InfoText;
    betInputLandscape?: InfoText;
    winInputLandscape?: InfoText;
    betInputPortret?: InfoText;
    winInputPortret?: InfoText;
    balanceInput?: InfoText;
    balanceInputLandscape?: InfoText;
    balanceInputPortret?: InfoText;
    reelsContainer?: ReelsContainer;
    settingsButton?: SettingsButton;
    settingsModal?: SettingsModal;
    winFinishPopUp?: WinFinishPopUp;
    winStartPopUp?: WinStartPopUp;
    betModal?: BetModal;
    autoPlayModal?: AutoPlayModal;
    outOfMoneyModal?: OutOfMoneyModal;
    isActiveButtonPlay: boolean;
    winModal?: WinModal;
    buyBonusModal?: BuyBonusModal;
    buyBonusModalPortrait?: BuyBonusModalPortrait;
    buyMaxBonusModal?: BuyMaxBonusModal;
    buyMaxBonusModalPortrait?: BuyMaxBonusModalPortrait;
    buyMinBonusModalPortrait?: BuyMinBonusModalPortrait;
    buyMinBonusModal?: BuyMinBonusModal;
    baseAmount?: BaseAmount;
    antisipator?: Antisipator;
    isMenuOpen: boolean;
    winLine?: WinLine
    constructor(game: PixiGame, app: Application) {
        this.game = game;
        this.app = app;
        this.infoBar = new PIXI.Container();
        this.infoBarMobile = new PIXI.Container();
        this.infoBarMobilePortret = new PIXI.Container();
        this.isActiveButtonPlay = true
        this.multiplierArray = [];
        this.multiplierMobileArray = [];
        this.isMenuOpen = false;
    }

    setupAllComponents = async () => {
        this.isFs = false;
        this.isSpacePressed = false;
        await this.setupBackgroundFS();
        await this.setupBackground();
        await this.setupLogo();
        // await this.setupInfoBar();
        // await this.setupInfoBarMobile();
        // await this.setupInfoBarMobilePortret();
        await this.setupButtons();
        await this.setupReelsContainer();
        await this.setupModals();
        this.antisipator = new Antisipator(this.game.app.loader.resources['antisipator'].spineData)
        this.winLine = new WinLine(this.game)

    };

    changeActiveButtonPlay(value: boolean) {
        this.isActiveButtonPlay = value
    }
    setupBackground = () => {
        const bg = this.app.loader.resources['backgroundAnimated'].spineData;
        if (!bg) return;
        const background = new Spine(bg)
        background.width = 1920;
        background.height = 1080;
        background.scale.set(1, 1)
        background.state.setAnimation(0, "animation_fin", true)
        background.state.timeScale = 0.7
        this.app.stage.addChild(background);
        this.background = background;
    };

    setupBackgroundFS = () => {
        const bgFS = this.app.loader.resources['backgroundAnimatedFS'].spineData;
        if (!bgFS) return;
        const backgroundFS = new Spine(bgFS)
        backgroundFS.width = 1920;
        backgroundFS.height = 1080;
        backgroundFS.scale.set(1, 1)
        backgroundFS.state.setAnimation(0, "animation_fin", true)
        backgroundFS.state.timeScale = 0.7
        this.app.stage.addChild(backgroundFS);
        this.backgroundFS = backgroundFS;
        this.changeVisibility(this.backgroundFS, false)
    };

    changeGameType = async (isFS: boolean) => {
        if (this.isFs === isFS) return
        this.isFs = !this.isFs
        if (!this.isFs) {
            this.changeVisibility(this.multiplierBar, false)
            this.changeVisibility(this.multiplierBarMobile, false)
            this.changeVisibility(this.buyBonusButton?.button, true)
            this.changeVisibility(this.doubleBonusChanceButton?.button, true)
            this.changeVisibility(this.background, true)
            this.changeVisibility(this.backgroundFS, false)
            this.game.slotMachine!.changeButtonState(false);
        } else if (this.isFs) {
            this.game.slotMachine!.changeButtonState(true);
            this.game!.assetsController!.winStartPopUp!.confirmButton!.button.buttonMode = true;
            this.game!.assetsController!.winStartPopUp!.confirmButton!.button.interactive = true;
            this.game!.assetsController!.winFinishPopUp!.confirmButton!.button.buttonMode = true;
            this.game!.assetsController!.winFinishPopUp!.confirmButton!.button.interactive = true;
            let adaptiveRatio = window.innerWidth / window.innerHeight;
            if (adaptiveRatio < 1) {
                this.changeVisibility(this.multiplierBar, false)
                this.changeVisibility(this.multiplierBarMobile, true)
            } else {
                this.changeVisibility(this.multiplierBar, true)
                this.changeVisibility(this.multiplierBarMobile, false)
            }
            this.changeVisibility(this.buyBonusButton?.button, false);
            this.changeVisibility(this.doubleBonusChanceButton?.button, false)
            this.changeVisibility(this.background, false)
            this.changeVisibility(this.backgroundFS, true)
        }
    }

    setupLogo = () => {
        this.logo = new PIXI.Sprite(this.app.loader.resources['LogoVertical'].texture);
        this.logoPortrait = new PIXI.Sprite(this.app.loader.resources['LogoHorisontal'].texture);

        this.logoPortrait.width = 1000;
        this.logoPortrait.height = 110;
        this.logo.width = 302;
        this.logo.height = 183;
        this.logo.scale.set(0.85, 0.85);
        this.logo.anchor.set(0.07, 0.1);
        this.game.gameContainer.addChild(this.logo);
        this.app.stage.addChild(this.logoPortrait);
    };

    setupButtons = async () => {
        // this.playButton = new PlayButton(this.game, this.app);
        // this.autoPlayButton = new AutoPlayButton(this.game, this.app);
        this.buyBonusButton = new BuyBonusButton(this.game, this.app);
        this.betButton = new BetButton(this.game, this.app);
        this.doubleBonusChanceButton = new DoubleBonusChanceButton(
            this.game,
            this.app
        );
        // this.settingsButton = new SettingsButton(this.game, this.app);

        // await this.playButton.initialize();
        // await this.autoPlayButton.initialize();
        await this.betButton.initialize();
        await this.setMultiplier();
        await this.setMultiplierMobile();
        await this.buyBonusButton.initialize();
        await this.doubleBonusChanceButton.initialize();
        // await this.settingsButton.initialize();
    };

    setMultiplier = async () => {
        this.multiplierBar = new PIXI.Container();

        this.game.gameContainer.addChild(this.multiplierBar);

        this.multiplierBar.x = 250;
        this.multiplierBar.y = 1000;


        this.multiplierX1 = new MultiplierItem(1, this.app);
        this.multiplierX2 = new MultiplierItem(2, this.app);
        this.multiplierX3 = new MultiplierItem(3, this.app);
        this.multiplierX5 = new MultiplierItem(5, this.app);
        this.multiplierX10 = new MultiplierItem(10, this.app);
        this.multiplierX20 = new MultiplierItem(20, this.app);
        this.multiplierX30 = new MultiplierItem(30, this.app);
        this.multiplierX50 = new MultiplierItem(50, this.app);
        this.multiplierX100 = new MultiplierItem(100, this.app);

        this.multiplierArray?.push(this.multiplierX1);
        this.multiplierArray?.push(this.multiplierX2);
        this.multiplierArray?.push(this.multiplierX3);
        this.multiplierArray?.push(this.multiplierX5);
        this.multiplierArray?.push(this.multiplierX10);
        this.multiplierArray?.push(this.multiplierX20);
        this.multiplierArray?.push(this.multiplierX30);
        this.multiplierArray?.push(this.multiplierX50);
        this.multiplierArray?.push(this.multiplierX100);

        this.multiplierBar.addChild(this.multiplierX1.multiplier!)
        this.multiplierBar.addChild(this.multiplierX2.multiplier!)
        this.multiplierBar.addChild(this.multiplierX3.multiplier!)
        this.multiplierBar.addChild(this.multiplierX5.multiplier!)
        this.multiplierBar.addChild(this.multiplierX10.multiplier!)
        this.multiplierBar.addChild(this.multiplierX20.multiplier!)
        this.multiplierBar.addChild(this.multiplierX30.multiplier!)
        this.multiplierBar.addChild(this.multiplierX50.multiplier!)
        this.multiplierBar.addChild(this.multiplierX100.multiplier!)
    }

    changeMultiplierScale(scalingX: number, scalingY: number) {
        if (!this.multiplierBar) return;
        this.multiplierBar.scale.x = scalingX;
        this.multiplierBar.scale.y = scalingY;
    }
    changeMultiplierPosition(x: number, y: number) {
        if (!this.multiplierBar) return;
        this.multiplierBar.x = x;
        this.multiplierBar.y = y;
    }

    setMultiplierMobile = async () => {
        this.multiplierBarMobile = new PIXI.Container();

        this.game.gameContainer.addChild(this.multiplierBarMobile);

        this.multiplierBarMobile.x = 972;
        this.multiplierBarMobile.y = 680;

        this.multiplierX1Mobile = new MultiplierItemMobile(1, this.app);
        this.multiplierX2Mobile = new MultiplierItemMobile(2, this.app);
        this.multiplierX3Mobile = new MultiplierItemMobile(3, this.app);
        this.multiplierX5Mobile = new MultiplierItemMobile(5, this.app);
        this.multiplierX10Mobile = new MultiplierItemMobile(10, this.app);
        this.multiplierX20Mobile = new MultiplierItemMobile(20, this.app);
        this.multiplierX30Mobile = new MultiplierItemMobile(30, this.app);
        this.multiplierX50Mobile = new MultiplierItemMobile(50, this.app);
        this.multiplierX100Mobile = new MultiplierItemMobile(100, this.app);

        this.multiplierMobileArray?.push(this.multiplierX1Mobile);
        this.multiplierMobileArray?.push(this.multiplierX2Mobile);
        this.multiplierMobileArray?.push(this.multiplierX3Mobile);
        this.multiplierMobileArray?.push(this.multiplierX5Mobile);
        this.multiplierMobileArray?.push(this.multiplierX10Mobile);
        this.multiplierMobileArray?.push(this.multiplierX20Mobile);
        this.multiplierMobileArray?.push(this.multiplierX30Mobile);
        this.multiplierMobileArray?.push(this.multiplierX50Mobile);
        this.multiplierMobileArray?.push(this.multiplierX100Mobile);

        this.multiplierBarMobile.addChild(this.multiplierX1Mobile.multiplier!)
        this.multiplierBarMobile.addChild(this.multiplierX2Mobile.multiplier!)
        this.multiplierBarMobile.addChild(this.multiplierX3Mobile.multiplier!)
        this.multiplierBarMobile.addChild(this.multiplierX5Mobile.multiplier!)
        this.multiplierBarMobile.addChild(this.multiplierX10Mobile.multiplier!)
        this.multiplierBarMobile.addChild(this.multiplierX20Mobile.multiplier!)
        this.multiplierBarMobile.addChild(this.multiplierX30Mobile.multiplier!)
        this.multiplierBarMobile.addChild(this.multiplierX50Mobile.multiplier!)
        this.multiplierBarMobile.addChild(this.multiplierX100Mobile.multiplier!)
        this.multiplierBarMobile.visible = false
    }
    changeMultiplierMobileScale(scalingX: number, scalingY: number) {
        if (!this.multiplierBarMobile) return;
        this.multiplierBarMobile.scale.x = scalingX;
        this.multiplierBarMobile.scale.y = scalingY;
    }
    changeMultiplierMobilePosition(x: number, y: number) {
        if (!this.multiplierBarMobile) return;
        this.multiplierBarMobile.x = x;
        this.multiplierBarMobile.y = y;
    }


    setupInfoBar = async () => {
        this.infoBar = new PIXI.Container();
        this.infoButton = new InfoButton(this.game, this.app);
        this.betInput = new InfoText(
            'BET',
            (this.game.initialData?.getBetValue() || 1).toFixed(2),
            'bet',
            255,
            this.app,
            this.game,
            this.game.initialData?.getBetValueIndex() || 0
        );
        this.winInput = new InfoText('WIN', 0, 'default', 300, this.app, this.game);
        this.balanceInput = new InfoText(
            'BALANCE',
           0,
            'default',
            300,
            this.app,
            this.game
        );
        this.game.gameContainer.addChild(this.infoBar);

        this.infoBar.width = 986;
        this.infoBar.height = 60;
        this.infoBar.x = 470;
        this.infoBar.y = 1080 - 110;

        await this.betInput.initialize();
        await this.winInput.initialize();
        await this.balanceInput.initialize();
        await this.infoButton.initialize();

        this.infoButton.button!.x = 0;
        this.betInput.input!.x =
            this.infoButton.button.x + this.infoButton.button.width + 22;
        this.winInput.input!.x =
            this.betInput?.input!.x + this.betInput?.input!.width + 22;
        this.balanceInput.input!.x =
            this.winInput?.input!.x + this.winInput?.input!.width + 22;

        this.infoBar.addChild(this.infoButton.button!);
        this.infoBar.addChild(this.betInput.input!);
        this.infoBar.addChild(this.winInput.input!);
        this.infoBar.addChild(this.balanceInput.input!);
    };

    setupInfoBarMobile = async () => {
        this.infoBarMobile = new PIXI.Container();

        const bg = this.app.loader.resources['infoBarMobileBG'].texture;
        if (!bg) return;
        const background = new PIXI.Sprite(bg);
        background.width = 1920;
        background.height = 60;
        background.x = 0;
        this.infoBarMobileBG = background;

        this.infoBarMobile.addChild(this.infoBarMobileBG);
        this.app.stage.addChild(this.infoBarMobile);

        this.betInputLandscape = new InfoText(
            'TOTAL BET',
            (this.game.initialData?.getBetValue() || 1).toFixed(2),
            'landscape',
            255,
            this.app,
            this.game,
            this.game.initialData?.getBetValueIndex() || 0
        );
        this.winInputLandscape = new InfoText(
            'WIN',
            0,
            'landscape',
            300,
            this.app,
            this.game
        );
        this.balanceInputLandscape = new InfoText(
            'CREDIT',
            0,
            'landscape',
            300,
            this.app,
            this.game
        );

        await this.betInputLandscape.initializeMobile();
        await this.winInputLandscape.initializeMobile();
        await this.balanceInputLandscape.initializeMobile();

        this.betInputLandscape.input!.x = 180;
        this.betInputLandscape.input!.y = 30;
        this.winInputLandscape.input!.x = 600;
        this.winInputLandscape.input!.y = 30;
        this.balanceInputLandscape.input!.x = 1050;
        this.balanceInputLandscape.input!.y = 30;

        this.infoBarMobile.addChild(this.betInputLandscape.input!);
        this.infoBarMobile.addChild(this.winInputLandscape.input!);
        this.infoBarMobile.addChild(this.balanceInputLandscape.input!);
    };

    setupInfoBarMobilePortret = async () => {
        this.infoBarMobilePortret = new PIXI.Container();
        const bg = this.app.loader.resources['infoBarMobileBG'].texture;
        if (!bg) return;
        const background = new PIXI.Sprite(bg);
        background.width = 1920;
        background.height = 40;
        background.x = 0;
        this.infoBarMobilePortretBG = background;

        this.infoBarMobilePortret.addChild(this.infoBarMobilePortretBG);
        this.game.gameContainer.addChild(this.infoBarMobilePortret);

        this.betInputPortret = new InfoText(
            'TOTAL BET',
            (this.game.initialData?.getBetValue() || 1).toFixed(2),
            'portret',
            195,
            this.app,
            this.game,
            this.game.initialData?.getBetValueIndex() || 0,
        );
        this.winInputPortret = new InfoText(
            'WIN',
            0,
            'portret',
            70,
            this.app,
            this.game
        );
        this.balanceInputPortret = new InfoText(
            'CREDIT',
            0,
            'portret',
            135,
            this.app,
            this.game
        );

        await this.betInputPortret.initializeMobile();
        await this.winInputPortret.initializeMobile();
        await this.balanceInputPortret.initializeMobile();

        this.betInputPortret.input!.x = 20;
        this.betInputPortret.input!.y = -8;
        this.winInputPortret.input!.x = 430;
        this.winInputPortret.input!.y = -8;
        this.balanceInputPortret.input!.x = 820;
        this.balanceInputPortret.input!.y = -8;

        this.infoBarMobilePortretBG.addChild(this.betInputPortret.input!);
        this.infoBarMobilePortretBG.addChild(this.winInputPortret.input!);
        this.infoBarMobilePortretBG.addChild(this.balanceInputPortret.input!);
    };

    async setupReelsContainer() {
        this.reelsContainer = new ReelsContainer(this.game, this.app);
        this.baseAmount = new BaseAmount(this.game, this.app);
        await this.reelsContainer.initialize();
        await this.baseAmount.initialize();
    }

    changeContainerScaling(scalingX: number, scalingY: number) {
        if (!this.reelsContainer) return;
        this.reelsContainer.container.scale.x = scalingX;
        this.reelsContainer.container.scale.y = scalingY;
    }

    changeBackgroundSize() {
        if (!this.background) return;
        this.background.width = 220;
        this.background.height = 220;
        let adaptiveRatio = window.innerWidth / window.innerHeight;
        if (adaptiveRatio < 1) {
            //PORTREIT
            if(window.innerHeight <= 1000){
                this.background.scale.x = 0.65;
                this.background.scale.y = 0.65;
                this.background.y = this.background.height - 625;
                this.background.x = this.background.width / 2 - 100;
            } else if (window.innerHeight <= 1100){
                this.background.scale.x = 0.6;
                this.background.scale.y = 0.6;
                this.background.y = this.background.height -290;
                this.background.x = this.background.width / 2 - 20;
            } else if (window.innerHeight <= 1366){
                this.background.width = 1406;
                this.background.height = 1406;
                this.background.y = this.background.height - 50;
                this.background.x = this.background.width / 2 - 20;
            } else if(window.innerHeight <= 1600){
                this.background.width = 1640;
                this.background.height = 1640;
                this.background.y = this.background.height - 20;
                this.background.x = this.background.width / 2 - 20;
            }
            else if(window.innerHeight <= 1920){
                this.background.width = 1960;
                this.background.height = 1960;
                this.background.y = this.background.height -20 ;
                this.background.x = this.background.width / 2 - 20;
            }else if (window.innerHeight <= 2048) {
                this.background.width = 2088;
                this.background.height = 2088;
                this.background.y = this.background.height -20;
                this.background.x = this.background.width / 2 - 20;
            } else if (window.innerHeight <= 2560) {
                this.background.width = 2600;
                this.background.height = 2600;
                this.background.y = this.background.height - 20;
                this.background.x = this.background.width / 2 - 20;
            } else if (window.innerHeight <= 3200) {
                this.background.width = 3240;
                this.background.height = 3240;
                this.background.y = this.background.height -20;
                this.background.x = this.background.width / 2 - 20;
            } else if (window.innerHeight <= 3840){
                this.background.width = 3880;
                this.background.height = 3880;
                this.background.y = this.background.height -20;
                this.background.x = this.background.width / 2 -600;
            } else if (window.innerHeight > 3840) {
                this.background.width = window.innerWidth * 1.2;
                this.background.height = window.innerWidth * 1.2;
                this.background.y = this.background.height - 20;
                this.background.x = this.background.width / 2  -800;
            }
        } else if (adaptiveRatio >= 1.78 && window.innerWidth > 1280) {
            //DESKTOP
            if (window.innerWidth <= 1366) {
                this.background.width = 1406;
                this.background.height = 1406;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            }else if (window.innerWidth <= 1500) {
                this.background.width = 1540;
                this.background.height = 1540;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth <= 1600) {
                this.background.width = 1640;
                this.background.height = 1640;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth <= 1720) {
                this.background.width = 1760;
                this.background.height = 1760;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth <= 1820) {
                this.background.width = 1860;
                this.background.height = 1860;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            }else if (window.innerWidth <= 1920) {
                this.background.width = 1960;
                this.background.height = 1960;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth <= 2080) {
                this.background.width = 2120;
                this.background.height = 2120;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 70 ;
            } else if (window.innerWidth <= 2200) {
                this.background.width = 2240;
                this.background.height = 2240;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth <= 2300) {
                this.background.width = 2340;
                this.background.height = 2340;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth <= 2560) {
                this.background.width = 2600;
                this.background.height = 2600;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth <= 3200) {
                this.background.width = 3240;
                this.background.height = 3240;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 30;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth <= 3840){
                this.background.width = 3880;
                this.background.height = 3880;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 - 200;
                this.background.x = this.background.width/2 - 50;
            } else if (window.innerWidth > 3840) {
                this.background.width = window.innerWidth * 1.2;
                this.background.height = window.innerWidth * 1.2;
                this.background.y = this.background.height - this.game.gameContainer.height/2 - 1000;
                this.background.x = this.background.width/2 - 20;
            }
        } else if (adaptiveRatio < 1.78 && window.innerWidth > 1280) {
            //DESKTOP2
            this.background.width = 1920
            this.background.height = 1080
            this.background.x = this.background.width / 2;
            if (window.innerWidth <= 1366) {
                this.background.width = 1406;
                this.background.height = 1406;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40
            }else if (window.innerWidth <= 1500) {
                this.background.width = 1540;
                this.background.height = 1540;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            } else if (window.innerWidth <= 1600) {
                this.background.width = 1640;
                this.background.height = 1640;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            }else if (window.innerWidth <= 1700) {
                this.background.width = 1740;
                this.background.height = 1740;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            }else if (window.innerWidth <= 1800) {
                this.background.width = 1840;
                this.background.height = 1840;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            }else if (window.innerWidth <= 1920) {
                this.background.width = 1920;
                this.background.height = 1920;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 20;
                this.background.x = this.background.width/2;
            } else if (window.innerWidth <= 2048) {
                this.background.width = 2088;
                this.background.height = 2088;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            }else if (window.innerWidth <= 2200) {
                this.background.width = 2240;
                this.background.height = 2240;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            }else if (window.innerWidth <= 2400) {
                this.background.width = 2440;
                this.background.height = 2440;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            }else if (window.innerWidth <= 2560) {
                this.background.width = 2600;
                this.background.height = 2600;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            } else if (window.innerWidth <= 3200) {
                this.background.width = 3240;
                this.background.height = 3240;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            } else if (window.innerWidth <= 3840){
                this.background.width = 3880;
                this.background.height = 3880;
                this.background.y = this.background.height/2 + this.game.gameContainer.height/2 + 50;
                this.background.x = this.background.width/2 - 40;
            } else if (window.innerWidth > 3840) {
                this.background.width = window.innerWidth * 1.2;
                this.background.height = window.innerWidth * 1.2;
                this.background.y = this.background.height - this.game.gameContainer.height/2 - 800;
                this.background.x = this.background.width/2  -20;
            }
        } else if (adaptiveRatio < 1.78 && window.innerWidth <= 1280) {
            //LANDSCAPE PC
            this.background.scale.x = 1;
            this.background.scale.y = 1;
            this.background.width = 1380;
            this.background.height = 1380;
            this.background.x = this.background.width/2 - 20;
            this.background.y = this.background.height - 375;
            if (window.innerWidth < 700) {
                this.background.width = 740;
                this.background.height = 740;
                this.background.x = this.background.width/2 - 40;
                this.background.y = this.background.height - 200;
            } else if(window.innerWidth < 900){
                this.background.width = 940;
                this.background.height = 940;
                this.background.x = this.background.width/2 - 40;
                this.background.y = this.background.height - 200;
            } else if (window.innerWidth <= 1280){
                this.background.width = 1320;
                this.background.height = 1320;
                this.background.x = this.background.width/2 - 20;
                this.background.y = this.background.height - 320;
            }

        } else if (adaptiveRatio > 1.78 && window.innerWidth <= 1280) {
            //LANDSCAPE MOBILE
            if (window.innerWidth < 700) {
                this.background.width = 700;
                this.background.height = 700;
                this.background.x = this.background.width/2 - 20;
                this.background.y = this.background.height - 200;
            } else if (window.innerWidth < 800) {
                this.background.width = 800;
                this.background.height = 800;
                this.background.x = this.background.width/2;
                this.background.y = this.background.height - 280;
            } else if (window.innerWidth < 860) {
                this.background.width = 880;
                this.background.height = 880;
                this.background.x = this.background.width/2 - 10;
                this.background.y = this.background.height - 280;
            } else if (window.innerWidth < 920) {
                this.background.width = 960;
                this.background.height = 960;
                this.background.x = this.background.width/2 - 20;
                this.background.y = this.background.height - 300;
            } else {
                this.background.width = 1366;
                this.background.height = 1366;
                this.background.x = this.background.width/2;
                this.background.y = this.background.height - 380;
            }
        } else {
            console.log("else adaptive mode, w:", window.innerWidth);
            this.background.width = 2040;
            this.background.height = 2040;
            this.background.y = this.background.height/2 + this.game.gameContainer.height/2;
            this.background.x = this.background.width/2 - 40;
        }

        console.log(this.background)
    }
    changeBackgroundFSSize() {
        if (!this.backgroundFS) return;
        this.backgroundFS.width = 220;
        this.backgroundFS.height = 220;
        let adaptiveRatio = window.innerWidth / window.innerHeight;
        if (adaptiveRatio < 1) {
            //PORTREIT
            if(window.innerHeight <= 1000){
                this.backgroundFS.scale.x = 0.65;
                this.backgroundFS.scale.y = 0.65;
                this.backgroundFS.y = this.backgroundFS.height - 625;
                this.backgroundFS.x = this.backgroundFS.width / 2 - 100;
            } else if (window.innerHeight <= 1100){
                this.backgroundFS.scale.x = 0.6;
                this.backgroundFS.scale.y = 0.6;
                this.backgroundFS.y = this.backgroundFS.height -290;
                this.backgroundFS.x = this.backgroundFS.width / 2 - 20;
            } else if (window.innerHeight <= 1366){
                this.backgroundFS.width = 1406;
                this.backgroundFS.height = 1406;
                this.backgroundFS.y = this.backgroundFS.height - 50;
                this.backgroundFS.x = this.backgroundFS.width / 2 - 20;
            } else if(window.innerHeight <= 1600){
                this.backgroundFS.width = 1640;
                this.backgroundFS.height = 1640;
                this.backgroundFS.y = this.backgroundFS.height - 20;
                this.backgroundFS.x = this.backgroundFS.width / 2 - 20;
            }
            else if(window.innerHeight <= 1920){
                this.backgroundFS.width = 1960;
                this.backgroundFS.height = 1960;
                this.backgroundFS.y = this.backgroundFS.height -20 ;
                this.backgroundFS.x = this.backgroundFS.width / 2 - 20;
            }else if (window.innerHeight <= 2048) {
                this.backgroundFS.width = 2088;
                this.backgroundFS.height = 2088;
                this.backgroundFS.y = this.backgroundFS.height -20;
                this.backgroundFS.x = this.backgroundFS.width / 2 - 20;
            } else if (window.innerHeight <= 2560) {
                this.backgroundFS.width = 2600;
                this.backgroundFS.height = 2600;
                this.backgroundFS.y = this.backgroundFS.height - 20;
                this.backgroundFS.x = this.backgroundFS.width / 2 - 20;
            } else if (window.innerHeight <= 3200) {
                this.backgroundFS.width = 3240;
                this.backgroundFS.height = 3240;
                this.backgroundFS.y = this.backgroundFS.height -20;
                this.backgroundFS.x = this.backgroundFS.width / 2 - 20;
            } else if (window.innerHeight <= 3840){
                this.backgroundFS.width = 3880;
                this.backgroundFS.height = 3880;
                this.backgroundFS.y = this.backgroundFS.height -20;
                this.backgroundFS.x = this.backgroundFS.width / 2 -600;
            } else if (window.innerHeight > 3840) {
                this.backgroundFS.width = window.innerWidth * 1.2;
                this.backgroundFS.height = window.innerWidth * 1.2;
                this.backgroundFS.y = this.backgroundFS.height - 20;
                this.backgroundFS.x = this.backgroundFS.width / 2  -800;
            }
        } else if (adaptiveRatio >= 1.78 && window.innerWidth > 1280) {
            //DESKTOP
            if (window.innerWidth <= 1366) {
                this.backgroundFS.width = 1406;
                this.backgroundFS.height = 1406;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            }else if (window.innerWidth <= 1500) {
                this.backgroundFS.width = 1540;
                this.backgroundFS.height = 1540;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth <= 1600) {
                this.backgroundFS.width = 1640;
                this.backgroundFS.height = 1640;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth <= 1720) {
                this.backgroundFS.width = 1760;
                this.backgroundFS.height = 1760;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth <= 1820) {
                this.backgroundFS.width = 1860;
                this.backgroundFS.height = 1860;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            }else if (window.innerWidth <= 1920) {
                this.backgroundFS.width = 1960;
                this.backgroundFS.height = 1960;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth <= 2080) {
                this.backgroundFS.width = 2120;
                this.backgroundFS.height = 2120;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 70 ;
            } else if (window.innerWidth <= 2200) {
                this.backgroundFS.width = 2240;
                this.backgroundFS.height = 2240;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth <= 2300) {
                this.backgroundFS.width = 2340;
                this.backgroundFS.height = 2340;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth <= 2560) {
                this.backgroundFS.width = 2600;
                this.backgroundFS.height = 2600;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth <= 3200) {
                this.backgroundFS.width = 3240;
                this.backgroundFS.height = 3240;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 30;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth <= 3840){
                this.backgroundFS.width = 3880;
                this.backgroundFS.height = 3880;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 - 200;
                this.backgroundFS.x = this.backgroundFS.width/2 - 50;
            } else if (window.innerWidth > 3840) {
                this.backgroundFS.width = window.innerWidth * 1.2;
                this.backgroundFS.height = window.innerWidth * 1.2;
                this.backgroundFS.y = this.backgroundFS.height - this.game.gameContainer.height/2 - 1000;
                this.backgroundFS.x = this.backgroundFS.width/2 - 20;
            }
        } else if (adaptiveRatio < 1.78 && window.innerWidth > 1280) {
            //DESKTOP2
            this.backgroundFS.width = 1920
            this.backgroundFS.height = 1080
            console.log(`full hd`, this.backgroundFS.width)
            this.backgroundFS.x = this.backgroundFS.width / 2;
            if (window.innerWidth <= 1366) {
                console.log("innerHight", window.innerHeight);
                this.backgroundFS.width = 1406;
                this.backgroundFS.height = 1406;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40
            }else if (window.innerWidth <= 1500) {
                this.backgroundFS.width = 1540;
                this.backgroundFS.height = 1540;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            } else if (window.innerWidth <= 1600) {
                this.backgroundFS.width = 1640;
                this.backgroundFS.height = 1640;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            }else if (window.innerWidth <= 1700) {
                this.backgroundFS.width = 1740;
                this.backgroundFS.height = 1740;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            }else if (window.innerWidth <= 1800) {
                this.backgroundFS.width = 1840;
                this.backgroundFS.height = 1840;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            }else if (window.innerWidth <= 1920) {
                this.backgroundFS.width = 1920;
                this.backgroundFS.height = 1920;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 20;
                this.backgroundFS.x = this.backgroundFS.width/2;
            } else if (window.innerWidth <= 2048) {
                this.backgroundFS.width = 2088;
                this.backgroundFS.height = 2088;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            }else if (window.innerWidth <= 2200) {
                this.backgroundFS.width = 2240;
                this.backgroundFS.height = 2240;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            }else if (window.innerWidth <= 2400) {
                this.backgroundFS.width = 2440;
                this.backgroundFS.height = 2440;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            }else if (window.innerWidth <= 2560) {
                this.backgroundFS.width = 2600;
                this.backgroundFS.height = 2600;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            } else if (window.innerWidth <= 3200) {
                this.backgroundFS.width = 3240;
                this.backgroundFS.height = 3240;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            } else if (window.innerWidth <= 3840){
                this.backgroundFS.width = 3880;
                this.backgroundFS.height = 3880;
                this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2 + 50;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
            } else if (window.innerWidth > 3840) {
                this.backgroundFS.width = window.innerWidth * 1.2;
                this.backgroundFS.height = window.innerWidth * 1.2;
                this.backgroundFS.y = this.backgroundFS.height - this.game.gameContainer.height/2 - 800;
                this.backgroundFS.x = this.backgroundFS.width/2  -20;
            }
        } else if (adaptiveRatio < 1.78 && window.innerWidth <= 1280) {
            //LANDSCAPE PC
            this.backgroundFS.scale.x = 1;
            this.backgroundFS.scale.y = 1;
            this.backgroundFS.width = 1380;
            this.backgroundFS.height = 1380;
            this.backgroundFS.x = this.backgroundFS.width/2 - 20;
            this.backgroundFS.y = this.backgroundFS.height - 375;
            if (window.innerWidth < 700) {
                this.backgroundFS.width = 740;
                this.backgroundFS.height = 740;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
                this.backgroundFS.y = this.backgroundFS.height - 200;
            } else if(window.innerWidth < 900){
                this.backgroundFS.width = 940;
                this.backgroundFS.height = 940;
                this.backgroundFS.x = this.backgroundFS.width/2 - 40;
                this.backgroundFS.y = this.backgroundFS.height - 200;
            } else if (window.innerWidth <= 1280){
                this.backgroundFS.width = 1320;
                this.backgroundFS.height = 1320;
                this.backgroundFS.x = this.backgroundFS.width/2 - 20;
                this.backgroundFS.y = this.backgroundFS.height - 320;
            }

        } else if (adaptiveRatio > 1.78 && window.innerWidth <= 1280) {
            //LANDSCAPE MOBILE
            if (window.innerWidth < 700) {
                console.log('window.innerWidth < 700');
                this.backgroundFS.width = 700;
                this.backgroundFS.height = 700;
                this.backgroundFS.x = this.backgroundFS.width/2 - 20;
                this.backgroundFS.y = this.backgroundFS.height - 200;
            } else if (window.innerWidth < 800) {
                this.backgroundFS.width = 800;
                this.backgroundFS.height = 800;
                this.backgroundFS.x = this.backgroundFS.width/2;
                this.backgroundFS.y = this.backgroundFS.height - 280;
            } else if (window.innerWidth < 860) {
                this.backgroundFS.width = 880;
                this.backgroundFS.height = 880;
                this.backgroundFS.x = this.backgroundFS.width/2 - 10;
                this.backgroundFS.y = this.backgroundFS.height - 280;
            } else if (window.innerWidth < 920) {
                this.backgroundFS.width = 960;
                this.backgroundFS.height = 960;
                this.backgroundFS.x = this.backgroundFS.width/2 - 20;
                this.backgroundFS.y = this.backgroundFS.height - 300;
            } else {
                this.backgroundFS.width = 1366;
                this.backgroundFS.height = 1366;
                this.backgroundFS.x = this.backgroundFS.width/2;
                this.backgroundFS.y = this.backgroundFS.height - 380;
            }
        } else {
            console.log("else adaptive mode, w:", window.innerWidth);
            this.backgroundFS.width = 2040;
            this.backgroundFS.height = 2040;
            this.backgroundFS.y = this.backgroundFS.height/2 + this.game.gameContainer.height/2;
            this.backgroundFS.x = this.backgroundFS.width/2 - 40;
        }

        console.log(this.background)
    }

    changeSize(arg: any, width: number, height: number) {
        if (!arg) return;
        arg.width = width;
        arg.height = height;
    }

    changePosition(arg: any, x: number, y: number) {
        if (!arg) return;
        arg.x = x;
        arg.y = y;
    }

    changeVisibility(arg: any, isVisible: boolean) {
        if (!arg) return;
        arg.alpha = isVisible ? 1 : 0;
        arg.visible = isVisible;
    }

    setupModals = async () => {
        this.settingsModal = new SettingsModal(this.game, this.app);
        this.autoPlayModal = new AutoPlayModal(this.game, this.app);
        this.betModal = new BetModal(this.game, this.app);
        this.outOfMoneyModal = new OutOfMoneyModal(this.game, this.app);
        this.winModal = new WinModal(this.game, this.app);
        this.buyBonusModal = new BuyBonusModal(this.game, this.app);
        this.buyBonusModalPortrait = new BuyBonusModalPortrait(this.game, this.app);
        this.buyMaxBonusModal = new BuyMaxBonusModal(this.game, this.app);
        this.buyMaxBonusModalPortrait = new BuyMaxBonusModalPortrait(this.game, this.app);
        this.buyMinBonusModalPortrait = new BuyMinBonusModalPortrait(this.game, this.app);
        this.buyMinBonusModal = new BuyMinBonusModal(this.game, this.app);
        this.winFinishPopUp = new WinFinishPopUp(this.game, this.app);
        this.winStartPopUp = new WinStartPopUp(this.game, this.app);

        await this.settingsModal.initialize();
        await this.autoPlayModal.initialize();
        await this.betModal.initialize();
        await this.outOfMoneyModal.initialize();
        await this.winModal.initialize();
        await this.buyBonusModal.initialize();
        await this.buyBonusModalPortrait.initialize();
        await this.buyMaxBonusModal.initialize();
        await this.buyMaxBonusModalPortrait.initialize();
        await this.buyMinBonusModalPortrait.initialize();
        await this.buyMinBonusModal.initialize();
        await this.winFinishPopUp.initialize();
        await this.winStartPopUp.initialize()

    };

    isInteractiveActive(isActive: boolean) {
        this.app.stage.interactiveChildren = isActive;
        this.game.gameContainer.interactiveChildren = isActive;
        this.app.stage.buttonMode = isActive;
        this.game.gameContainer.buttonMode = isActive;
    }

    changeMenuState(isOpen: boolean) {
        this.isMenuOpen = isOpen;
    }
}
