import { useCallback } from 'react';
import { UpdateForm } from '../../../api/types/auth';
import { api } from '../../../App';
import { useActions } from '../../../hooks/useActions';

export const useUpdate = () => {
	const { setUser } = useActions();
	const handleUpdate = useCallback(async (form: UpdateForm) => {
		try {
			const body: UpdateForm = {};

			if (form.email !== '') {
				body.email = form.email;
			}
			if (form.userName !== '') {
				body.userName = form.userName;
			}
			if (form.newPassword !== '') {
				body.newPassword = form.newPassword;
			}
			const updateProfile = await api.auth.updateProfile(body);
		} catch (error) {}
		const user = await api.auth.whoami();
		setUser(user);
	}, []);

	return {
		handleUpdate,
	};
};
