import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import App from './App';
import './styles/index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

const rubik = new FontFace("Rubik", "url(https://fonts.gstatic.com/s/rubik/v23/iJWKBXyIfDnIV7nBrXw.woff2)", {weight:'300 600'});
document.fonts.add(rubik);
rubik.load()

document.addEventListener('gesturestart', function(e) {
	e.preventDefault();
	const body = document.body as any
	body.style.zoom = 1;
});

document.addEventListener('gesturechange', function(e) {
	e.preventDefault();
	const body = document.body as any
	body.style.zoom = 1;
});

document.addEventListener('gestureend', function(e) {
	e.preventDefault();
	const body = document.body as any
	body.style.zoom = 1;
});

const baseUrl = window.location.pathname.split('/')[1] === 'square-gummy-bear' ? '/square-gummy-bear' : ''

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<ReactQueryDevtools />
				<BrowserRouter basename={baseUrl}>
					<App />
				</BrowserRouter>
			</QueryClientProvider>
		</Provider>
	</React.StrictMode>
);
