import * as PIXI from 'pixi.js';
import '../../../../styles/index.css';

export const valueStyles = new PIXI.TextStyle({
	fontFamily: 'Lilta One',
	fontSize: 140,
	letterSpacing: 10,
	fontWeight: '400',
	fill: ['#f9de48', '#f9de78', '#f9de48', '#b56516'],
	fillGradientStops: [0, 0.19, 0.48, 0.75],
	fillGradientType: 0,
	dropShadow: true,
	dropShadowAngle: 1,
	dropShadowBlur: 7,
	dropShadowDistance: 0,
	dropShadowColor: 'red',
	stroke: '#000000',
	strokeThickness: 9,
});
