import {Application} from "pixi.js";
import closeButtonDefault from "../assets/buttons/btn-close-default.png";
import closeButtonHover from "../assets/buttons/btn-close-hover.png";
import closeButtonDisabled from "../assets/buttons/btn-close-disabled.png";
import confirmButtonDefault from "../assets/buttons/btn-confirm-default.png";
import confirmButtonHover from "../assets/buttons/btn-confirm-hover.png";
import confirmButtonDisabled from "../assets/buttons/btn-confirm-disabled.png";
import betButtonDefault from "../assets/buttons/btnBetDefault.png";
import betButtonHover from "../assets/buttons/btnBetHover.png";
import betButtonDisabled from "../assets/buttons/btnBetDisabled.png";
import autoSpinButtonDefault from "../assets/buttons/autospinButtonDefault.svg";
import autoSpinButtonDisabled from "../assets/buttons/autospinButtonDisabled.svg";
import autoSpinButtonHover from "../assets/buttons/autospinButtonHover.svg";
import autoSpinButtonSelected from "../assets/buttons/autospinButtonSelected.svg";
import autoPlayButtonBackground from "../assets/buttons/auto-play-btn-background.png";
import autoPlayButtonBorder from "../assets/buttons/auto-play-btn-border.png";
import autoPlayButtonPlay from "../assets/buttons/auto-play-btn-play.png";
import autoPlayButtonFS from "../assets/buttons/auto-play-btn-fs.png";
import autoPlayButtonHover from "../assets/buttons/auto-play-btn-hover.png";
import autoPlayButtonActive from "../assets/buttons/auto-play-btn-active.png";
import playButtonBackground from "../assets/buttons/play-btn-background.png";
import playButtonBorder from "../assets/buttons/play-btn-border.png";
import playButtonBorderFS from "../assets/buttons/play-btn-fs-border.png";
import playButtonPlay from "../assets/buttons/play-btn-play.png";
import playButtonPlayHover from "../assets/buttons/play-btn-paly-hover.png";
import playButtonStop from "../assets/buttons/play-btn-stop.png";
import playButtonStopHover from "../assets/buttons/play-btn-stop-hover.png";
import infoButtonDefault from "../assets/bottom-bar/btn-info-default.png";
import infoButtonHover from "../assets/bottom-bar/btn-info-hover.png";
import infoButtonHidden from "../assets/bottom-bar/btn-info-hidden.png";
import plusButtonDefault from "../assets/bottom-bar/btn-plus-default.svg";
import plusButtonHover from "../assets/bottom-bar/btn-plus-hover.svg";
import plusButtonHiden from "../assets/bottom-bar/btn-plus-hidden.svg";
import minusButtonDefault from "../assets/bottom-bar/btn-minus-default.svg";
import minusButtonHover from "../assets/bottom-bar/btn-minus-hover.svg";
import minusButtonHiden from "../assets/bottom-bar/btn-minus-hidden.svg";
import settingsButtonDefault from "../assets/buttons/btn-burger-default.png";
import settingsButtonHover from "../assets/buttons/btn-burger-hover.png";
import settingsButtonDisabled from "../assets/buttons/btn-burger-hidden.png";
import buyBonusButton from "../assets/buttons/btn-bonus-default.png";
import buyBonusButtonDisabled from "../assets/buttons/btn-bonus-disabled.png";
import buyBonusButtonHover from "../assets/buttons/btn-bonus-hover.png";
import betModalButton from "../assets/modal/bet-modal-button.svg";
import betModalButtonHover from "../assets/modal/bet-modal-button-hover.svg";
import autoPlayModalButton from "../assets/modal/auto-play-modal-button.svg";
import autoPlayModalButtonHover from "../assets/modal/auto-play-modal-button-hover.svg";
import autoPlayCloseButton from "../assets/modal/auto-play-modal-arrow.svg";
import autoPlayAdvancedOpenButton from "../assets/modal/auto-play-modal-arrow-small.svg";
import autoPlayAdvancedCloseButton from "../assets/modal/auto-play-modal-arrow-small-hover.svg";

export const buttonsLoader = (app: Application) => {
    app.loader.add('closeButtonDefault', closeButtonDefault)
        .add('closeButtonHover', closeButtonHover)
        .add('closeButtonDisabled', closeButtonDisabled)
        .add('confirmButtonDefault', confirmButtonDefault)
        .add('confirmButtonHover', confirmButtonHover)
        .add('confirmButtonDisabled', confirmButtonDisabled)
        .add('betButtonDefault', betButtonDefault)
        .add('betButtonHover', betButtonHover)
        .add('betButtonDisabled', betButtonDisabled)
        .add('autoSpinButtonDefault', autoSpinButtonDefault)
        .add('autoSpinButtonDisabled', autoSpinButtonDisabled)
        .add('autoSpinButtonHover', autoSpinButtonHover)
        .add('autoSpinButtonSelected', autoSpinButtonSelected)
        .add('autoPlayButtonBackground', autoPlayButtonBackground)
        .add('autoPlayButtonBorder', autoPlayButtonBorder)
        .add('autoPlayButtonPlay', autoPlayButtonPlay)
        .add('autoPlayButtonFS', autoPlayButtonFS)
        .add('autoPlayButtonHover', autoPlayButtonHover)
        .add('autoPlayButtonActive', autoPlayButtonActive)
        .add('playButtonBackground', playButtonBackground)
        .add('playButtonBorder', playButtonBorder)
        .add('playButtonBorderFS', playButtonBorderFS)
        .add('playButtonPlay', playButtonPlay)
        .add('playButtonPlayHover', playButtonPlayHover)
        .add('playButtonStop', playButtonStop)
        .add('playButtonStopHover', playButtonStopHover)
        .add('infoButtonDefault', infoButtonDefault)
        .add('infoButtonHover', infoButtonHover)
        .add('infoButtonHidden', infoButtonHidden)
        .add('plusButtonDefault', plusButtonDefault)
        .add('plusButtonHover', plusButtonHover)
        .add('plusButtonHiden', plusButtonHiden)
        .add('minusButtonDefault', minusButtonDefault)
        .add('minusButtonHover', minusButtonHover)
        .add('minusButtonHiden', minusButtonHiden)
        .add('settingsButtonDefault', settingsButtonDefault)
        .add('settingsButtonHover', settingsButtonHover)
        .add('settingsButtonDisabled', settingsButtonDisabled)
        .add('buyBonusButton', buyBonusButton)
        .add('buyBonusButtonDisabled', buyBonusButtonDisabled)
        .add('buyBonusButtonHover', buyBonusButtonHover)
        .add('betModalButton', betModalButton)
        .add('betModalButtonHover', betModalButtonHover)
        .add('autoPlayModalButton', autoPlayModalButton)
        .add('autoPlayModalButtonHover', autoPlayModalButtonHover)
        .add('autoPlayCloseButton', autoPlayCloseButton)
        .add('autoPlayAdvancedOpenButton', autoPlayAdvancedOpenButton)
        .add('autoPlayAdvancedCloseButton', autoPlayAdvancedCloseButton)
}