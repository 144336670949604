import { SettingModalAction, SettingModalActionEnum, SettingModalState } from './types';

const initialState: SettingModalState = {
	settingModalIsOpened: false,
};

export default function settingModalReducer(
	state = initialState,
	action: SettingModalAction
): SettingModalState {
	switch (action.type) {
		case SettingModalActionEnum.SET_MODAL:
			return { ...state, settingModalIsOpened: action.payload};
		default:
			return state;
	}
}
