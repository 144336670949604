import {Application} from "pixi.js";
import x1Img from '../assets/multipliers/x1.png'
import x2Img from '../assets/multipliers/x2.png'
import x3Img from '../assets/multipliers/x3.png'
import x5Img from '../assets/multipliers/x5.png'
import x10Img from '../assets/multipliers/x10.png'
import x20Img from '../assets/multipliers/x20.png'
import x30Img from '../assets/multipliers/x30.png'
import x50Img from '../assets/multipliers/x50.png'
import x100Img from '../assets/multipliers/x100.png'

export const multipliersLoader = (app: Application) => {
    app.loader.add('x1', x1Img)
        .add('x2', x2Img)
        .add('x3', x3Img)
        .add('x5', x5Img)
        .add('x10', x10Img)
        .add('x20', x20Img)
        .add('x30', x30Img)
        .add('x50', x50Img)
        .add('x100', x100Img)

}