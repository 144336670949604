import { FC } from 'react';
import styled from 'styled-components';
import NavBar from '../NavBar/NavBar';
import { respondFrom, respondTo } from '../../styles/mixins';
import Clock from '../Clock/Clock';
import InfoBar from '../InfoBar/InfoBar';
import { PixiGame } from '../../features/Game';

import LogoIcon from '../../assets/img/header/logo-standart.svg';

export interface IGame {
	game?: PixiGame;
}

const Header: FC<IGame> = ({ game }) => {
	return (
		<HeaderContainer>
			<LogoContainer>
				<img src={LogoIcon} alt='Logo' draggable={false} />
				<span>|</span>
				<LogoTitleHiden>Square Gummy Bear</LogoTitleHiden>
			</LogoContainer>

			<LogoTitle>Square Gummy Bear</LogoTitle>

			{game === undefined ? (
				<></>
			) : (
				<>
					<InfoDisplay>
						<InfoBar game={game} />
					</InfoDisplay>

					<NavBar game={game} />
				</>
			)}

			<Clock />
		</HeaderContainer>
	);
};

export default Header;

const HeaderContainer = styled.header`
	height: 32px;
	padding: 0 10px;
	background: #151515;
	display: flex;
	align-items: center;
	z-index: 999;
	justify-content: space-between;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const LogoContainer = styled.div`
	align-items: center;
	display: flex;
	height: 32px;
	div {
		${respondFrom.lg`
			display: none;
		`}
	}
	span {
		padding: 0 15px;
		color: #e2f4f2;
		${respondFrom.lg`
			display: none;
		`}
	}
`;

const LogoTitle = styled.div`
	flex: auto;
	text-align: center;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #e2f4f2;
	display: none;
	${respondFrom.lg`
			display: block;
	`}
`;

const LogoTitleHiden = styled.div`
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #e2f4f2;
	display: none;
	${respondTo.lg`
			display: block;
	`}
`;

const InfoDisplay = styled.div`
	flex: auto;
	${respondFrom.lg`
		display: none;
	`}
`;
