import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';
import ConfirmBuyButton from '../BuyBonusButton/confirmBuyButton';
import CustomButton from '../CustomButton';
import ConfirmBuyButtonPortrait from './ConfirmBuyButtonPortrait';

class BuyBonusModalPortrait {
	app: Application;
	game: PixiGame;
	modalWrapper: PIXI.Container;
	windowBackground: PIXI.Graphics;
	modal: PIXI.Container;
	background: PIXI.Graphics;
	title: PIXI.Text;
	maxCost: PIXI.Text;
	minCost: PIXI.Text;
	maxBonusAmount: PIXI.Text;
	minBonusAmount: PIXI.Text;
	maxBonusSprite: PIXI.Sprite;
	minBonusSprite: PIXI.Sprite;

	closeButton?: CustomButton;
	confirmButtonMax?: ConfirmBuyButton;
	confirmButtonMin?: ConfirmBuyButton;
	isModalOpen: boolean;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		const betValue = Math.trunc((this.game.initialData?.getBetValue() || 1))
		this.isModalOpen = false;
		this.modal = new PIXI.Container();
		this.modalWrapper = new PIXI.Container();
		this.background = new PIXI.Graphics();
		this.windowBackground = new PIXI.Graphics();
		this.title = new PIXI.Text('BUY BONUS', {
			fontFamily: 'Rubik',
			fontSize: 28,
			fontWeight: '700',
			lineHeight: 33.6,
			fill: '#E2F4F2',
		});
		this.maxCost = new PIXI.Text(`€ ${Math.trunc((400 * Number(betValue)))}`, {
			fontFamily: 'Rubik',
			fontSize: 16,
			fontWeight: '700',
			lineHeight: 24,
			fill: '#F9AD29',
		});

		this.minCost = new PIXI.Text(`€ ${Math.trunc((100 * Number(betValue)))}`, {
			fontFamily: 'Rubik',
			fontSize: 16,
			fontWeight: '700',
			lineHeight: 24,
			fill: '#F9AD29',
		});

		this.maxBonusAmount = new PIXI.Text('10 FREE SPINS', {
			fontFamily: 'Rubik',
			fontSize: 16,
			fontWeight: '700',
			lineHeight: 24,
			fill: '#F9AD29',
		});

		this.minBonusAmount = new PIXI.Text('6-10 FREE SPINS', {
			fontFamily: 'Rubik',
			fontSize: 16,
			fontWeight: '700',
			lineHeight: 24,
			fill: '#F9AD29',
		});

		this.maxBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['leftBonus'].texture!
		);

		this.minBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['rightBonus'].texture!
		);
	}

	initialize = async () => {
		await this.setupButtons();
		this.modalWrapper.x = 0;
		this.modalWrapper.y = 0;
		this.modalWrapper.width = this.app.screen.width;
		this.modalWrapper.height = this.app.screen.height + 32;
		this.modalWrapper.visible = false;
		this.modalWrapper.zIndex = 10000;

		this.modal.width = 1618;
		this.modal.height = 879;

		this.windowBackground.beginFill(0x151515);
		this.windowBackground.drawRect(
			0,
			0,
			this.app.screen.width,
			this.app.screen.height + 32 + 50
		);
		this.windowBackground.endFill();
		this.windowBackground.alpha = 0.8;

		this.background.beginFill(0x151515);
		this.background.drawRoundedRect(0, 0, 304, 536, 10);
		this.background.endFill();

		this.windowBackground.zIndex = 1;
		this.modal.zIndex = 16;

		this.title.x = (this.background.width - this.title.width) / 2;
		this.title.y = 56;

		this.maxCost.x = this.maxBonusAmount.x + this.maxBonusAmount.width/2 ;
		this.maxCost.y = 485;

		this.minCost.x = this.minBonusAmount.x + this.minBonusAmount.width/2 ;
		this.minCost.y = 251;

		this.minBonusAmount.x = 30;
		this.minBonusAmount.y = 119;

		this.maxBonusAmount.x = 38;
		this.maxBonusAmount.y = 353;

		this.maxBonusSprite.width = 155;
		this.maxBonusSprite.height = 90;
		this.maxBonusSprite.x = 20;
		this.maxBonusSprite.y = 153;

		this.minBonusSprite.width = 155;
		this.minBonusSprite.height = 90;
		this.minBonusSprite.x = 20;
		this.minBonusSprite.y = 387;

		this.app.stage.addChild(this.modalWrapper);
		this.modalWrapper.addChild(this.windowBackground!);
		this.modalWrapper.addChild(this.modal!);
		this.modal.addChild(this.background!);
		this.background.addChild(this.title!);
		this.background.addChild(this.maxCost!);
		this.background.addChild(this.minCost!);
		this.background.addChild(this.minBonusAmount!);
		this.background.addChild(this.maxBonusAmount!);
		this.background.addChild(this.maxBonusSprite);
		this.background.addChild(this.minBonusSprite);
	};
	changeMinCost = (betValue:number) => {
		this.minCost.text = `€ ${Math.trunc((100 * betValue))}`
	}
	changeMaxCost = (betValue:number) => {
		this.maxCost.text = `€ ${Math.trunc((400 * betValue))}`
	}

	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
		}
	};

	showMaxModal = (isActive: boolean) => {
		this.game.assetsController?.buyMaxBonusModalPortrait?.showModal(true);

		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
		}
	};

	showMinModal = (isActive: boolean) => {
		this.game.assetsController?.buyMinBonusModalPortrait?.showModal(true);

		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
		}
	};

	onClickAndTouch = (element: any, func: Function, state: boolean) => {
		element.button.on('touchstart', () => func(state));
		element.button.on('click', (e:any) => this.leftClickAutoPlayButton(e, func, state));
	};

	leftClickAutoPlayButton = (e:any, func: Function, state: boolean) => {
		if(e.data.button === 0){
			func(state);
		};
	}

	setupButtons = async () => {
		this.closeButton = new CustomButton(
			this.game,
			this.app,
			'closeButtonDefault',
			'closeButtonHover',
			'closeButtonDisabled',
			40,
			40
		);
		this.confirmButtonMax = new ConfirmBuyButtonPortrait(
			this.game,
			this.app,
			'confirmBuyBonusDefaultPortrait',
			'confirmBuyBonusDefaultPortrait',
			'confirmBuyBonusDefaultPortrait',
			103,
			60,
			'BUY'
		);

		this.confirmButtonMin = new ConfirmBuyButtonPortrait(
			this.game,
			this.app,
			'confirmBuyBonusDefaultPortrait',
			'confirmBuyBonusDefaultPortrait',
			'confirmBuyBonusDefaultPortrait',
			103,
			60,
			'BUY'
		);

		this.onClickAndTouch(this.closeButton, this.showModal, false);
		this.onClickAndTouch(this.confirmButtonMax, this.showMaxModal, false);
		this.onClickAndTouch(this.confirmButtonMin, this.showMinModal, false);

		this.onClickAndTouch(
			this.closeButton,
			this.game.assetsController?.buyBonusModal!.showModal!,
			false
		);
		this.onClickAndTouch(
			this.confirmButtonMax,
			this.game.assetsController?.buyBonusModal!.showMaxModal!,
			false
		);
		this.onClickAndTouch(
			this.confirmButtonMin,
			this.game.assetsController?.buyBonusModal!.showMinModal!,
			false
		);

		await this.closeButton.initialize();
		await this.confirmButtonMax.initialize();
		await this.confirmButtonMin.initialize();

		this.closeButton.button.x = 276;
		this.closeButton.button.y = -12;

		this.confirmButtonMax.button.x = 183;
		this.confirmButtonMax.button.y = 402;

		this.confirmButtonMin.button.x = 183;
		this.confirmButtonMin.button.y = 168;

		this.background.addChild(this.closeButton.button!);
		this.background.addChild(this.confirmButtonMax.button!);
		this.background.addChild(this.confirmButtonMin.button!);
	};

	changeBuyBonusModalPortraitScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeBuyBonusModalPortraitPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}
}

export default BuyBonusModalPortrait;
