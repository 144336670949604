import * as PIXI from 'pixi.js';
import {Application} from 'pixi.js';
import {PixiGame} from '../../index';
import {valueStyles} from './style';
import gsap from 'gsap';
import {Timer} from "../../../../utils/Timer";

class BaseAmount {
    app: Application;
    game: PixiGame;
    value: PIXI.Text;
    multiplierText: PIXI.Text
    multiplier?: number
    amount?: number
    container: PIXI.Container
    currency: string

    constructor(game: PixiGame, app: Application) {
        this.game = game;
        this.app = app;
        this.value = new PIXI.Text('', valueStyles);
        this.multiplierText = new PIXI.Text('', valueStyles)
        this.container = new PIXI.Container()
        this.container.addChild(this.value)
        this.container.addChild(this.multiplierText)
        this.container.zIndex = 1000
        this.currency = "$"

    }

    initialize = async () => {
        this.value.x = 0;
        this.value.y = 0;
        this.value.zIndex = 100000;
        this.value.visible = false;
        // this.game.assetsController?.reelsContainer!.container.addChild(this.value);
    };

    setValue = (currency: string, value: number, multiplier?: string) => {
        this.value.text = `${currency} ${value.toFixed(2)}`;
        this.currency = currency
        this.amount = value
    };

    setMultiplier = (multiplier: number) => {
        this.container.alpha = 0
        this.multiplierText.text = `X${multiplier}`
        this.multiplier = multiplier
    }

    showValue = () => {
        // this.value.x = 300;
        // this.value.y = 500;
        return new Promise(async(resolve) => {
            this.value.visible = true;
            let startPosition = this.value.y;
            let wiggleTween = gsap.timeline();
            this.game.soundController.btn_ui?.play();

            this.multiplierText.y = this.value.y
            this.multiplierText.x = this.value.x

            const timer = new Timer(() => {
                return resolve(true)
            }, 4000)
            timer.initialize()

            if (this.multiplier && this.multiplier > 1) {
                const multAnim = gsap.timeline()
                this.container.alpha = 0
                this.multiplierText.alpha = 0
                let addPos = 0
                if(this.multiplier < 10) {
                    addPos = 30
                } else if (this.multiplier >= 100) {
                    addPos -= 30
                }
                this.multiplierText.x += (this.value.width / 2 + this.multiplierText.width / 2) + addPos

                wiggleTween.fromTo(
                    this.container,
                    {alpha: 0},
                    {duration: 0.3, alpha: 1}
                ).then(async() => {

                    wiggleTween.fromTo(
                        this.value,
                        {x: 0},
                        {duration: 0.5, x: this.value.x - (this.multiplierText.width + addPos) / 2}
                    )
                    await multAnim.fromTo(
                        this.multiplierText,
                        {alpha: 0},
                        {alpha: 1, duration: 0.5}
                    )

                    wiggleTween.fromTo(
                        this.value,
                        {x: this.value.x, alpha: 1},
                        {x: this.value.x + (this.multiplierText.width + addPos), duration: 0.5, delay: 0.3, alpha: 0}
                    )

                    await multAnim.fromTo(
                        this.multiplierText,
                        {x: this.multiplierText.x},
                        {duration: 0.5, x: this.multiplierText.x - (this.multiplierText.width + addPos) / 2, alpha: 0, delay: 0.3})
                         .then(() => {
                        this.multiplierText.visible = false
                        this.value.text = `${this.currency}${(this.amount! * this.multiplier!).toFixed(2)}`
                    })

                    await wiggleTween.fromTo(
                        this.value,
                        {alpha: 0},
                        {duration: 0.3, alpha: 1, delay: -0.1}
                    )

                    wiggleTween.fromTo(this.value, 0.5, {alpha: 1}, {alpha: 0, repeat: 0, delay: 0.2});

                })


            } else {
                this.multiplierText.destroy()
                await wiggleTween.fromTo(
                    this.container,
                    {alpha: 0},
                    {alpha: 1, duration: 0.3}
                )
                // wiggleTween.fromTo(
                //     this.value,
                //     {y: startPosition},
                //     {duration: 0.5, y: startPosition - 100,}
                // );
                wiggleTween.fromTo(this.value, 0.8, {alpha: 1}, {alpha: 0, repeat: 0, delay: 0.5});
                return resolve(true)
            }
        })


    };

}

export default BaseAmount;
