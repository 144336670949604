import { useEffect } from "react"
import { useActions } from "../../../hooks/useActions";

const useHandlerInfoBar = (betValue?: number, gameWin?: number, balance?: number,  totalBetValue?:number) => {
  const { setInfoBar } = useActions();

  useEffect(() => {
    setInfoBar({
      betValue: betValue!,
      spinResult: gameWin!,
      balanceValue: balance!,
      totalBetValue: totalBetValue!,
    });
  }, [betValue, gameWin, balance])
}

export default useHandlerInfoBar