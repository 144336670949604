import {FC, useEffect} from 'react';
import styled from 'styled-components';
import { PixiGame } from '../../features/Game';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { respondTo, respondFrom } from '../../styles/mixins';

interface IInfo {
	game: PixiGame;
}

const InfoBar: FC<IInfo> = ({ game }) => {
	const { info, bet } = useTypedSelector(state => state.auth);

	useEffect(() => {
		if(info.totalBetValue) {
			game.assetsController?.betInputPortret!.changeValue(info.totalBetValue);
			game.assetsController?.betInputLandscape!.changeValueLandscape(
				info.totalBetValue
			);
		}
	}, [info])



	return (
		<>
			{info &&
				<InfoContainer>
					<InfoItem>
						<InfoTitle>TOTAL BET:</InfoTitle>
						<InfoValue>{info.totalBetValue}</InfoValue>
					</InfoItem>
					<InfoItem>
						<InfoTitle>WIN:</InfoTitle>
						<InfoValue>
					<span>
						{info.spinResult > 0 ? `${info.spinResult.toFixed(2)}` : 0}
					</span>
						</InfoValue>
					</InfoItem>
					<InfoItem>
						<InfoTitle>BALANCE:</InfoTitle>
						<InfoValue>
							{info.balanceValue < 0 ? 0 : info?.balanceValue?.toFixed(2)}
						</InfoValue>
					</InfoItem>
				</InfoContainer>
			}
		</>

	);
};

export default InfoBar;

const InfoContainer = styled.div`
	margin: auto;
	max-width: 631px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const InfoItem = styled.div`
	display: flex;
	align-items: center;
	padding: 0 10px;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;

	${respondFrom.sm`
		flex-direction: column;
	`}
`;

const InfoTitle = styled.div`
	color: #7c8584;
	padding-right: 8px;
`;

const InfoValue = styled.div`
	color: #f9ad29;
	span {
		color: #e2f4f2;
		${respondFrom.xs`
		color: #f9ad29;
		`}
		${respondTo.sm`
		color: #f9ad29;
	`}
	}
`;
