import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const Clock: FC = () => {
	const [date, setDate] = useState(new Date());

	const updateDate = () => {
		setDate(new Date());
	};

	useEffect(() => {
		setTimeout(updateDate, 1000);
	});

	const formatClockNum = (value: number) => {
		return value.toString().padStart(2, '0');
	};

	return (
		<Time>
			{formatClockNum(date.getHours())}.{formatClockNum(date.getMinutes())}
		</Time>
	);
};

export default Clock;

const Time = styled.div`
	padding: 0 10px;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #7c8584;
`;
