import {ITrackEntry, Spine} from 'pixi-spine';
import * as PIXI from 'pixi.js'
import {Sprite} from "pixi.js";
import {Timer} from "../../../utils/Timer";
import {wildMultiplierImages, wildMultipliers} from "../constants/multipliers";
import '@pixi-spine/loader-3.8'; // Side effect install the loader
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports
export * from '@pixi-spine/runtime-3.8';
export * from '@pixi-spine/base';

export class SymbolItem {
    id: number;
    name: string;
    symbol: Spine;
    assetData: any;
    skipAnimation: boolean;
    container?: PIXI.Container;
    texture: PIXI.Sprite;
    mult: number;
    isDestroyed?: boolean
    winAmount?: string

    constructor(id: number, name: string, assetData: any) {
        this.id = id;
        this.name = name;
        this.assetData = assetData;
        this.symbol = new Spine(assetData);
        this.symbol.state.setAnimation(0, 'landing', false);
        // this.symbol.tint = 0xffffff;
        this.skipAnimation = false
        this.symbol.state.timeScale = 1000
        this.mult = 0
        this.container = new PIXI.Container()
        this.container.width = 233
        this.container.height = 233
        this.container.x = this.symbol.x
        this.container.y = this.symbol.y
        this.isDestroyed = false
        this.texture = new PIXI.Sprite();



        if (name === 'WILD') {
            this.symbol.width = 233;
            this.symbol.height = 233
            this.symbol.state.setAnimation(0, "landing", false)
            this.container = new PIXI.Container()
            this.container.sortableChildren = true
            this.container.width = 233;
            this.container.height = 233;
            // this.container.addChild(this.antisipator)

            this.container.addChild(this.symbol)
        }
        this.symbol.state.timeScale = 1
    }

    copyObject = () => {
        const clone = new SymbolItem(this.id, this.name, this.assetData);
        clone.symbol.width = 230;
        clone.symbol.height = 226;
        // clone.symbol.scale.set(1, 1);
        if (clone.name === 'SCATTER') {
            clone.symbol.width = 300;
        }
        if (clone.name === 'WILD') {
            clone.symbol.width = 233;
            clone.symbol.height = 233
        }
        return clone;
    };

    winDestructionAnimation = () => {
        return new Promise((resolve) => {
            this.symbol.state.timeScale = 1
            if (this.symbol.state.hasAnimation('win_and_distruction')) {
                this.symbol.state.setAnimation(0, 'win_and_distruction', false);
            } else if (this.symbol.state.hasAnimation('win_and_destruction')) {
                this.symbol.state.setAnimation(0, 'win_and_destruction', false);
            } else if (this.symbol.state.hasAnimation('win_and_destrucktion')) {
                this.symbol.state.setAnimation(0, 'win_and_destrucktion', false);
            } else if (this.symbol.state.hasAnimation('win_destruction')) {
                this.symbol.state.setAnimation(0, 'win_destruction', false);
            }
            //LISTENER FOR END ANIMATION
            const symbolName = this.name

            const interval = setInterval(() => {
                if (this.skipAnimation) {
                    this.symbol.state.timeScale = 2
                    const timer = new Timer(() => {
                        return resolve(true)
                    }, 250)
                    timer.initialize()
                    this.skipAnimation = false
                    clearInterval(interval)
                }
            }, 1)

            const timer = new Timer(() => {
                this.container?.children.find((item) => {
                    if(item instanceof Sprite) item.destroy()
                })
            }, 750)
            timer.initialize()
            this.isDestroyed = true
            this.symbol.state.addListener({
                end(entry: ITrackEntry) {
                    const scaleTime = symbolName === "WILD" ? 2000 : 1400
                    const timer = new Timer(() => {
                        return resolve(true);
                    }, 800);
                    timer.initialize()
                }
            });
        });

    };

    win = async (speedUp = false): Promise<boolean> => {
        return new Promise((resolve) => {
            this.symbol.state.setAnimation(0, 'win', false);
            if(speedUp) {
                this.symbol.state.timeScale = 1
            }

            this.symbol.state.addListener({
                end(entry: ITrackEntry) {
                    const timer = new Timer(() => {
                        return resolve(true);
                    }, 200);

                    timer.initialize()
                }
            });
        });

    };

    shake = async (): Promise<boolean> => {
        return new Promise((resolve) => {
            this.symbol.state.setAnimation(0, 'landing', false);

            this.symbol.state.addListener({
                end(entry: ITrackEntry) {
                    const timer = new Timer(() => {
                        return resolve(true);
                    }, 200);

                    timer.initialize()
                }
            });
        });

    };

    changeSpecialReelsMultiplier = (multiplier:number) =>{
        if (!this.container) {
            return
        }
        this.container.children.forEach((child) => {
            if(child instanceof Sprite) {
                child.destroy({texture:true})
            }
        })
        this.texture = new PIXI.Sprite(PIXI.Texture.from(`x${multiplier}`))
        this.container.addChild(this.texture)
        this.texture.anchor.set(0.5, 0.5)
        this.texture.y = -640
        this.texture.x = 30
        this.mult = multiplier
        this.texture.scale.x = 0;
        this.texture.scale.y = 0;
    };

    changeMultiplier = (multiplier: number, pos = 0, isChanged = false) => {
        try {
            if (multiplier <= 1) return
            if (!multiplier || this.mult > 1) return
            if (!this.container) {
                return
            }
            let texture = wildMultipliers[`x${multiplier}`]!
            if(!texture.baseTexture) {
                wildMultipliers[`x${multiplier}`] = PIXI.Texture.from(wildMultiplierImages[`x${multiplier}`])
                texture = wildMultipliers[`x${multiplier}`]!
            }

            if(!texture) return
            this.texture = new PIXI.Sprite(texture)
            this.container.addChild(this.texture)
            this.texture.anchor.set(0.5, 0.5)
            if(!isChanged) {
                this.texture.y = -640
                this.texture.x = 30
            } else {
                this.texture.y = pos === 0 ? - 50 : pos === 1 ? 182 : 420
                this.texture.x = 108
            }
            this.mult = multiplier
        } catch (e) {
            console.log(e)

        }

    }

}
