import {PixiGame} from "../index";
import * as PIXI from 'pixi.js'
import {Spine} from "pixi-spine";
export class WinLine {
    game: PixiGame
    container: PIXI.Container
    winLine: Spine[]

    constructor(game: PixiGame) {
        this.game = game
        this.container = new PIXI.Container()
        const spineData = game.app.loader.resources['win_lines'].spineData!
        this.winLine = []
        for(let i = 0; i < 10; i++) {
            this.winLine.push(new Spine(spineData))
        }

    }

    init = () => {
        if(!this.game.slotMachine) return

        this.winLine.forEach((winLine,index) => {
            this.container.addChild(winLine)
            winLine.y = 1110
            winLine.x = 585
            winLine.visible = false

            winLine.zIndex = 100
        })
        this.container.x = 0
        this.container.y = 0
        this.container.sortableChildren = true

        this.container.zIndex = 100
        this.game.slotMachine?.rootContainer?.addChild(this.container)

    }

    setWinLine = (id: number) => {
        this.winLine.forEach((winLine) => winLine.visible = false )
        if(!this.winLine[id - 1]) return
        this.winLine[id - 1].visible = true
        this.winLine[id - 1].state.setAnimation(0, `1.${id}`, false)
    }

    depictWinLines = (ids: number[]) => {
        return new Promise((resolve, reject) => {
            if(!ids.length || ids.length < 2) return resolve(true)
            this.winLine.forEach((winLine) => winLine.visible = false)
            ids.forEach((id) => {
                if([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(id - 1)){
                    this.winLine[id - 1].visible = true
                    this.winLine[id - 1].state.setAnimation(0, `1.${id}`, false)
                }

            })

            const timer = setTimeout(() => {
                clearTimeout(timer)
                resolve(true)
            }, 500)

        })

    }
}
