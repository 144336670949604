import {PixiGame} from "../../index";
import {Application, Texture} from "pixi.js";
import * as PIXI from 'pixi.js'

export class ReelsContainer {
    game: PixiGame;
    app: Application;
    background?: PIXI.Sprite;
    container: PIXI.Container
    border?: PIXI.Sprite;
    width: number;
    height: number;

    constructor(game: PixiGame, app: Application) {
        this.game = game;
        this.app = app
        this.width = 1200
        this.height = 964
        this.container = new PIXI.Container()
        this.container.sortableChildren = true

    }

    async initialize () {
        await this.setupReelsContainerBg()
        await this.setupReelsBorder()
    }

    setupReelsContainerBg () {

        this.game.gameContainer.addChild(this.container)
        this.container.width = this.width * (window.innerWidth / window.innerHeight);
        this.container.height = this.height * (window.innerWidth / window.innerHeight);
        this.container.x = 366;
        this.container.sortableChildren = true
        // this.background = new PIXI.Sprite(this.app.loader.resources['reelsContainer'].texture)
        //
        // this.container?.addChild(this.background)
    }

    changeContainerPosition(x: number, y: number) {
		if (!this.container) return;
		this.container.x = x;
		this.container.y = y;
	}

    setupReelsBorder () {
        this.border = new PIXI.Sprite(this.app.loader.resources['reelsBorder'].texture)
        this.border.width = 1234
        this.border.height = 756
        this.border.x = -16
        this.border.y = 220

        this.container.addChild(this.border)
    }
}
