import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { api } from '../../../../App';
import { PixiGame } from '../../index';
import ConfirmBuyButton from '../BuyBonusButton/confirmBuyButton';
import CustomButton from '../CustomButton';
import {EventType, getEvent} from "../../../../GameEventBus";
import { UI } from '../../../../ui';

class BuyMaxBonusModal {
	app: Application;
	game: PixiGame;
	modalWrapper: PIXI.Container;
	windowBackground: PIXI.Graphics;
	modal: PIXI.Container;
	background: PIXI.Graphics;
	title: PIXI.Text;
	maxBonusTitle: PIXI.Text;
	maxCost: PIXI.Text;
	maxBonusAmount: PIXI.Text;
	maxBonusSprite: PIXI.Sprite;
	betValue: number | string;
	closeButton?: CustomButton;
	confirmButtonMax?: ConfirmBuyButton;
	disclaimButtonMax?: ConfirmBuyButton;
	isModalOpen: boolean;
	balance: number;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.balance = 0;
		this.betValue = Math.trunc((this.game.initialData?.getBetValue() || 1))
		this.isModalOpen = false;
		this.modal = new PIXI.Container();
		this.modalWrapper = new PIXI.Container();
		this.background = new PIXI.Graphics();
		this.windowBackground = new PIXI.Graphics();
		this.title = new PIXI.Text('CONFIRMATION', {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 52.8,
			fill: '#E2F4F2',
		});
		this.maxCost = new PIXI.Text(`€ ${Math.trunc((400 * Number(this.game.balance.betValue)))}`, {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 52.8,
			fill: '#F9AD29',
		});

		this.maxBonusAmount = new PIXI.Text('10 FREE SPINS', {
			fontFamily: 'Rubik',
			fontSize: 36,
			fontWeight: '700',
			lineHeight: 43.2,
			fill: '#E2F4F2',
		});

		this.maxBonusTitle = new PIXI.Text('BUY BONUS', {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 52.8,
			fill: '#F9AD29',
		});

		this.maxBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['rightBonus'].texture!
		);
	}

	initialize = async () => {
		await this.setupButtons();
		this.modalWrapper.x = 0;
		this.modalWrapper.y = 0;
		this.modalWrapper.width = this.app.screen.width;
		this.modalWrapper.height = this.app.screen.height + 32;
		this.modalWrapper.visible = false;
		this.modalWrapper.zIndex = 10000;

		this.modal.width = 1618;
		this.modal.height = 879;

		this.windowBackground.beginFill(0x151515);
		this.windowBackground.drawRect(
			0,
			0,
			this.app.screen.width,
			this.app.screen.height + 32
		);
		this.windowBackground.endFill();
		this.windowBackground.alpha = 0.8;

		this.background.beginFill(0x151515);
		this.background.drawRoundedRect(0, 0, 1618, 879, 10);
		this.background.endFill();

		this.windowBackground.zIndex = 1;
		this.modal.zIndex = 15;

		this.title.x = 640;
		this.title.y = 47;

		this.maxCost.x = 958;
		this.maxCost.y = 447;

		this.maxBonusAmount.x = 958;
		this.maxBonusAmount.y = 343;

		this.maxBonusTitle.x = 958;
		this.maxBonusTitle.y = 228;

		this.maxBonusSprite.width = 533;
		this.maxBonusSprite.height = 321;
		this.maxBonusSprite.x = 366;
		this.maxBonusSprite.y = 203;

		this.app.stage.addChild(this.modalWrapper);
		this.modalWrapper.addChild(this.windowBackground!);
		this.modalWrapper.addChild(this.modal!);
		this.modal.addChild(this.background!);
		this.background.addChild(this.title!);
		this.background.addChild(this.maxCost!);
		this.background.addChild(this.maxBonusAmount!);
		this.background.addChild(this.maxBonusTitle!);
		this.background.addChild(this.maxBonusSprite);
	};

	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
			this.isModalOpen = true;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
			this.isModalOpen = false;
		}
	};

	confirmBuy = async (isActive: boolean) => {
		//this.betValue = (this.game.initialData?.getBetValue() || 1)
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
			this.isModalOpen = true;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
			this.isModalOpen = false;
		}
		
		if (this.game.balance.balanceValue < ((400 * Number(this.game.balance.betValue)) + Number(this.game.balance.betValue))) {
			getEvent(EventType.OUT_OF_MONEY).send(true)
		} else {
			// TODO: need wrapper update
			// UI.events.show("free-spins", {
			// 	offers: [
			// 		{
			// 		type: 'fixed',
			// 		amount: 10,
			// 		cost: (400 * Number(this.game.balance.betValue)),
			// 		options: [],
			// 		expired: 1000*60*60*24,
			// 		offerID: 'qwer125'
			// 		}
			// 	]
			// })
			this.game.assetsController?.autoPlayButton?.setFSButtonState(false)
			this.game.slotMachine?.changeButtonState(true)
			const buyBonus = await api.auth.buyBonus(1, (40000 * Number(this.game.balance.betValue)), Number(this.game.balance.betValue) * 100);
			getEvent(EventType.GET_BALANCE).send((buyBonus.account.value / 100) + Number(this.game.balance.betValue))
			await this.game.slotMachine?.onBonusSpin(buyBonus)
		}
	};

	disclaimBuy = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
			this.isModalOpen = true;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
			this.isModalOpen = false;
		}
	};

	onClickAndTouch = (element: any, func: Function, state: boolean) => {
		element.button.on('touchstart', () => func(state));
		element.button.on('click', (e:any) => this.leftClickAutoPlayButton(e, func, state));
	};

	leftClickAutoPlayButton = (e:any, func: Function, state: boolean) => {
		if(e.data.button === 0){
			func(state);
		};
	}

	setupButtons = async () => {
		this.closeButton = new CustomButton(
			this.game,
			this.app,
			'closeButtonDefault',
			'closeButtonHover',
			'closeButtonDisabled',
			52,
			52
		);

		this.confirmButtonMax = new ConfirmBuyButton(
			this.game,
			this.app,
			'confirmButtonDefault',
			'confirmButtonHover',
			'confirmButtonDisabled',
			256,
			90,
			'YES'
		);

		this.disclaimButtonMax = new ConfirmBuyButton(
			this.game,
			this.app,
			'confirmButtonDefault',
			'confirmButtonHover',
			'confirmButtonDisabled',
			256,
			90,
			'NO'
		);

		this.onClickAndTouch(this.closeButton, this.showModal, false);
		this.onClickAndTouch(this.confirmButtonMax, this.confirmBuy, false);
		this.onClickAndTouch(this.disclaimButtonMax, this.disclaimBuy, false);

		this.onClickAndTouch(
			this.closeButton,
			this.game.assetsController?.buyMaxBonusModalPortrait!.showModal!,
			false
		);
		this.onClickAndTouch(
			this.confirmButtonMax,
			this.game.assetsController?.buyMaxBonusModalPortrait!.confirmBuy!,
			false
		);
		this.onClickAndTouch(
			this.disclaimButtonMax,
			this.game.assetsController?.buyMaxBonusModalPortrait!.disclaimBuy!,
			false
		);

		await this.closeButton.initialize();
		await this.confirmButtonMax.initialize();
		await this.disclaimButtonMax.initialize();

		this.closeButton.button.x = 1587;
		this.closeButton.button.y = -12;

		this.disclaimButtonMax.button.x = 825;
		this.disclaimButtonMax.button.y = 626;

		this.confirmButtonMax.button.x = 505;
		this.confirmButtonMax.button.y = 626;

		this.background.addChild(this.closeButton.button!);
		this.background.addChild(this.confirmButtonMax.button!);
		this.background.addChild(this.disclaimButtonMax.button!);
	};

	changeBuyMaxBonusModalScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeBuyMaxBonusModalPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}

	getBalance = (value: number) => {
		this.balance = value;
	};

	changeMaxCost = (betValue ?: number) => {
		this.maxCost.text = `€ ${Math.trunc((400 * this.game.balance.betValue))}`
		console.log(this.maxCost.text);
		// this.betValue = betValue;
	}
}

export default BuyMaxBonusModal;
