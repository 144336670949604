import * as PIXI from 'pixi.js';

import Low1 from '../Game/assets/game-field/low1.png';
import Low2 from '../Game/assets/game-field/low2.png';
import Low3 from '../Game/assets/game-field/low3.png';
import Low4 from '../Game/assets/game-field/low4.png';
import High1 from '../Game/assets/game-field/H1.png';
import High2 from '../Game/assets/game-field/H2.png';
import High3 from '../Game/assets/game-field/H3.png';
import High4 from '../Game/assets/game-field/H4.png';
import Wild from '../Game/assets/game-field/wild.png';
import Scatter from '../Game/assets/game-field/scatter.png';
import Empty from '../Game/assets/game-field/empty.png';


export const testItem = [
    [ 8, 2, 3 ],
    [ 5, 3, 2 ],
    [ 3, 6, 4 ],
    [ 1, 4, 9 ],
    [ 1, 8, 10 ]
];

export const multipliers = [ 1, 2, 3, 5, 10, 20, 30, 50, 100 ];
export const multipliersWeights = [ 200, 200, 200, 100, 90, 60, 40, 20, 5 ];

const slotTextures = [
    PIXI.Texture.from(Low1),
    PIXI.Texture.from(Low2),
    PIXI.Texture.from(Low3),
    PIXI.Texture.from(Low4),
    PIXI.Texture.from(High1),
    PIXI.Texture.from(High2),
    PIXI.Texture.from(High3),
    PIXI.Texture.from(High4),
    PIXI.Texture.from(Wild),
    PIXI.Texture.from(Scatter)
];

export const itemsPack = [
    [
        2, 4, 9, 6, 7, 4, 6, 9, 7, 6, 3, 7, 1, 9, 3, 8, 7, 10, 3, 6, 10, 7, 3, 10,
        6, 7, 10, 3, 10, 7, 3, 10, 6, 7, 10, 9, 6, 10, 7, 4, 10, 3, 7, 10, 9, 8, 10,
        6, 4, 10, 3, 7, 10, 6, 9, 3, 7, 4, 6, 7, 6, 3, 1, 4, 6, 7, 3, 6, 3, 4, 6, 3,
        7, 4, 7, 3, 9, 4, 7, 6, 3, 7, 5, 6, 9, 4, 6, 7, 9, 2, 3, 6, 9, 7, 6, 3, 9,
        7, 4, 6, 9, 8, 6, 3, 9, 7, 4, 3, 9, 7, 4, 3, 9, 6, 7, 9, 4, 6, 7, 1, 9, 3,
        2, 9, 8, 6, 9, 4, 6, 3, 9, 4, 7, 6, 9, 7, 3, 4, 7, 9, 6, 7, 9, 6, 4, 7, 3,
        6, 4, 7, 3, 7, 6, 7, 6, 4, 9, 7, 3, 6, 7, 9, 6, 7, 9, 3, 4, 7, 6, 9, 7, 6,
        9, 3, 7, 4, 9, 5, 6, 7, 9, 2, 6, 3, 4, 6, 5, 3, 6, 7, 3, 6, 8, 9
    ],
    [
        3, 10, 8, 9, 5, 4, 9, 5, 8, 9, 3, 5, 9, 10, 8, 5, 9, 3, 9, 7, 5, 9, 3, 1, 9,
        4, 8, 3, 9, 5, 4, 9, 3, 8, 9, 1, 10, 5, 3, 4, 7, 3, 5, 8, 4, 7, 5, 3, 8, 3,
        5, 6, 8, 4, 3, 5, 10, 8, 5, 8, 4, 9, 5, 10, 9, 3, 8, 9, 5, 4, 9, 10, 3, 9,
        8, 5, 9, 3, 4, 9, 2, 8, 9, 3, 4, 9, 8, 5, 9, 3, 5, 9, 8, 4, 9, 5, 3, 8, 9,
        5, 3, 9, 8, 5, 9, 4, 3, 9, 8, 7, 5, 9, 4, 8, 5, 9, 8, 5, 9, 1, 8, 9, 3, 4,
        9, 8, 5, 9, 6, 4, 9, 8, 5, 9, 10, 4, 9, 3, 8, 9, 7, 4, 9, 5, 8, 9, 3, 4, 9,
        5, 8, 9, 4, 3, 9, 5, 4, 8, 5, 4, 3, 8, 5, 10, 3, 8, 2, 4, 8, 5, 4, 3, 8, 5,
        3, 4, 8, 5, 8, 5, 2, 3, 4, 8, 9, 5, 3, 8, 4, 10, 8, 9, 3, 4, 9, 1, 5, 7, 8,
        9, 3, 4, 5, 6, 10, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9
    ],
    [
        7, 5, 8, 9, 7, 6, 8, 5, 4, 6, 8, 3, 6, 8, 4, 10, 7, 3, 6, 5, 8, 7, 5, 3, 8,
        9, 6, 5, 9, 3, 8, 7, 6, 9, 10, 5, 8, 7, 1, 5, 4, 6, 8, 10, 6, 5, 8, 6, 5, 1,
        7, 3, 8, 10, 9, 6, 5, 3, 2, 6, 5, 10, 8, 1, 7, 6, 8, 5, 6, 10, 7, 1, 6, 5,
        8, 6, 5, 7, 6, 8, 5, 7, 8, 6, 5, 8, 6, 5, 7, 6, 8, 5, 6, 7, 8, 5, 7, 6, 8,
        5, 6, 7, 8, 6, 4, 5, 7, 8, 6, 5, 8, 7, 5, 8, 6, 7, 6, 8, 7, 4, 6, 5, 2, 8,
        6, 7, 4, 8, 6, 5, 8, 4, 6, 2, 8, 6, 5, 7, 8, 1, 6, 7, 8, 6, 5, 8, 7, 5, 6,
        7, 8, 6, 7, 2, 8, 5, 7, 8, 5, 6, 8, 7, 5, 8, 6, 7, 5, 8, 9, 7, 5, 6, 8, 5,
        7, 8, 5, 6, 7, 5, 8, 4, 7, 8, 5, 6, 8, 7, 6, 5, 8, 4, 6, 8, 9
    ],
    [
        1, 3, 4, 5, 6, 7, 8, 9, 6, 2, 7, 4, 5, 1, 6, 10, 8, 4, 5, 8, 6, 9, 10, 4, 7,
        8, 3, 3, 6, 5, 8, 7, 9, 6, 4, 5, 7, 8, 9, 6, 3, 4, 5, 7, 8, 9, 6, 3, 2, 10,
        8, 5, 6, 7, 10, 8, 5, 6, 7, 8, 9, 10, 2, 8, 7, 1, 4, 5, 6, 10, 7, 9, 4, 3,
        5, 6, 4, 10, 7, 8, 9, 5, 4, 6, 9, 8, 4, 5, 6, 7, 8, 9, 1, 4, 5, 8, 4, 9, 5,
        3, 4, 5, 6, 7, 8, 1, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5,
        6, 7, 8, 9
    ],
    [
        3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 10, 4, 5, 6, 7, 4, 8, 5, 10, 1,
        3, 4, 9, 8, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 6, 10, 4, 5, 6, 7,
        8, 4, 9, 5, 10, 6, 7, 8, 4, 9, 5, 10, 6, 4, 7, 8, 9, 10, 4, 5, 6, 7, 8, 9,
        6, 10, 4, 5, 6, 4, 3, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9
    ]
];

// export const itemsPack = {
//   [GameMode.MAIN]: [
//     [2, 4, 9, 6, 7, 4, 6, 9, 7, 6, 3, 7, 1, 9, 3, 8, 7, 10, 3, 6, 10, 7, 3, 10, 6, 7, 10, 3, 10, 7, 3, 10, 6, 7, 10, 9, 6, 10, 7, 4, 10, 3, 7, 10, 9, 8, 10, 6, 4, 10, 3, 7, 10, 6, 9, 3, 7, 4, 6, 7, 6, 3, 1, 4, 6, 7, 3, 6, 3, 4, 6, 3, 7, 4, 7, 3, 9, 4, 7, 6, 3, 7, 5, 6, 9, 4, 6, 7, 9, 2, 3, 6, 9, 7, 6, 3, 9, 7, 4, 6, 9, 8, 6, 3, 9, 7, 4, 3, 9, 7, 4, 3, 9, 6, 7, 9, 4, 6, 7, 1, 9, 3, 2, 9, 8, 6, 9, 4, 6, 3, 9, 4, 7, 6, 9, 7, 3, 4, 7, 9, 6, 7, 9, 6, 4, 7, 3, 6, 4, 7, 3, 7, 6, 7, 6, 4, 9, 7, 3, 6, 7, 9, 6, 7, 9, 3, 4, 7, 6, 9, 7, 6, 9, 3, 7, 4, 9, 5, 6, 7, 9, 2, 6, 3, 4, 6, 5, 3, 6, 7, 3, 6, 8, 9],
//     [3, 10, 8, 9, 5, 4, 9, 5, 8, 9, 3, 5, 9, 10, 8, 5, 9, 3, 9, 7, 5, 9, 3, 1, 9, 4, 8, 3, 9, 5, 4, 9, 3, 8, 9, 1, 10, 5, 3, 4, 7, 3, 5, 8, 4, 7, 5, 3, 8, 3, 5, 6, 8, 4, 3, 5, 10, 8, 5, 8, 4, 9, 5, 10, 9, 3, 8, 9, 5, 4, 9, 10, 3, 9, 8, 5, 9, 3, 4, 9, 2, 8, 9, 3, 4, 9, 8, 5, 9, 3, 5, 9, 8, 4, 9, 5, 3, 8, 9, 5, 3, 9, 8, 5, 9, 4, 3, 9, 8, 7, 5, 9, 4, 8, 5, 9, 8, 5, 9, 1, 8, 9, 3, 4, 9, 8, 5, 9, 6, 4, 9, 8, 5, 9, 10, 4, 9, 3, 8, 9, 7, 4, 9, 5, 8, 9, 3, 4, 9, 5, 8, 9, 4, 3, 9, 5, 4, 8, 5, 4, 3, 8, 5, 10, 3, 8, 2, 4, 8, 5, 4, 3, 8, 5, 3, 4, 8, 5, 8, 5, 2, 3, 4, 8, 9, 5, 3, 8, 4, 10, 8, 9, 3, 4, 9, 1, 5, 7, 8, 9, 3, 4, 5, 6, 10, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9],
//     [7, 5, 8, 9, 7, 6, 8, 5, 4, 6, 8, 3, 6, 8, 4, 10, 7, 3, 6, 5, 8, 7, 5, 3, 8, 9, 6, 5, 9, 3, 8, 7, 6, 9, 10, 5, 8, 7, 1, 5, 4, 6, 8, 10, 6, 5, 8, 6, 5, 1, 7, 3, 8, 10, 9, 6, 5, 3, 2, 6, 5, 10, 8, 1, 7, 6, 8, 5, 6, 10, 7, 1, 6, 5, 8, 6, 5, 7, 6, 8, 5, 7, 8, 6, 5, 8, 6, 5, 7, 6, 8, 5, 6, 7, 8, 5, 7, 6, 8, 5, 6, 7, 8, 6, 4, 5, 7, 8, 6, 5, 8, 7, 5, 8, 6, 7, 6, 8, 7, 4, 6, 5, 2, 8, 6, 7, 4, 8, 6, 5, 8, 4, 6, 2, 8, 6, 5, 7, 8, 1, 6, 7, 8, 6, 5, 8, 7, 5, 6, 7, 8, 6, 7, 2, 8, 5, 7, 8, 5, 6, 8, 7, 5, 8, 6, 7, 5, 8, 9, 7, 5, 6, 8, 5, 7, 8, 5, 6, 7, 5, 8, 4, 7, 8, 5, 6, 8, 7, 6, 5, 8, 4, 6, 8, 9],
//     [1, 3, 4, 5, 6, 7, 8, 9, 6, 2, 7, 4, 5, 1, 6, 10, 8, 4, 5, 8, 6, 9, 10, 4, 7, 8, 3, 3, 6, 5, 8, 7, 9, 6, 4, 5, 7, 8, 9, 6, 3, 4, 5, 7, 8, 9, 6, 3, 2, 10, 8, 5, 6, 7, 10, 8, 5, 6, 7, 8, 9, 10, 2, 8, 7, 1, 4, 5, 6, 10, 7, 9, 4, 3, 5, 6, 4, 10, 7, 8, 9, 5, 4, 6, 9, 8, 4, 5, 6, 7, 8, 9, 1, 4, 5, 8, 4, 9, 5, 3, 4, 5, 6, 7, 8, 1, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9],
//     [3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 10, 4, 5, 6, 7, 4, 8, 5, 10, 1, 3, 4, 9, 8, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9, 6, 10, 4, 5, 6, 7, 8, 4, 9, 5, 10, 6, 7, 8, 4, 9, 5, 10, 6, 4, 7, 8, 9, 10, 4, 5, 6, 7, 8, 9, 6, 10, 4, 5, 6, 4, 3, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9]
//   ],
// [GameMode.FREE]: [
//   [4, 9, 6, 7, 4, 6, 1, 9, 7, 6, 3, 7, 1, 9, 3, 8, 7, 10, 1, 3, 6, 10, 7, 3, 1, 6, 7, 10, 3, 7, 1, 3, 6, 7, 10, 9, 6, 1, 7, 4, 10, 3, 7, 10, 1, 8, 3, 6, 4, 10, 3, 7, 1, 10, 6, 9, 3, 1, 4, 6, 7, 6, 3, 1, 4, 6, 7, 3, 6, 3, 1, 4, 6, 3, 7, 4, 7, 3, 1, 9, 4, 7, 6, 3, 7, 5, 6, 9, 4, 6, 7, 9, 3, 6, 9, 7, 6, 3, 9, 7, 4, 6, 9, 8, 6, 3, 9, 1, 7, 4, 3, 9, 7, 4, 3, 9, 6, 1, 9, 4, 6, 7, 9, 3, 9, 8, 6, 9, 4, 6, 3],
//   [3, 10, 8, 9, 5, 4, 1, 9, 5, 8, 9, 3, 5, 9, 3, 8, 5, 1, 3, 9, 7, 5, 9, 3, 1, 9, 4, 8, 3, 1, 5, 4, 9, 3, 8, 9, 1, 10, 5, 3, 4, 7, 3, 5, 1, 4, 7, 5, 3, 8, 1, 3, 5, 6, 8, 4, 3, 5, 10, 8, 3, 5, 8, 4, 1, 9, 5, 10, 9, 3, 8, 9, 5, 4, 9, 10, 1, 3, 9, 8, 5, 9, 3, 4, 9, 8, 9, 3, 4, 9, 8, 5, 9, 3, 5, 9, 8, 4, 1, 5, 3, 8, 9, 5, 3, 9, 8, 5, 9, 4, 3, 9, 8, 7, 5, 9, 4, 8, 5, 9, 8, 5, 9, 8, 9, 3, 4, 9, 8, 5, 9, 6],
//   [7, 5, 8, 9, 7, 6, 8, 5, 4, 6, 8, 3, 6, 8, 1, 4, 3, 7, 1, 3, 6, 5, 8, 7, 5, 3, 1, 8, 9, 6, 5, 9, 3, 7, 1, 3, 9, 10, 5, 8, 7, 1, 5, 4, 6, 8, 10, 6, 5, 8, 6, 5, 1, 7, 3, 8, 10, 9, 6, 5, 3, 6, 5, 10, 8, 1, 7, 6, 8, 5, 6, 10, 3, 1, 6, 5, 3, 6, 5, 7, 3, 8, 5, 7, 8, 6, 5, 8, 6, 5, 7, 6, 8, 5, 6, 7, 8, 5, 7, 6, 8, 5, 6, 7, 8, 6, 4, 5, 7, 8, 6, 5, 8, 7, 5, 8, 6, 7, 6, 8, 7, 4, 6, 5, 8, 6, 7, 4, 8, 6, 5, 8],
//   [1, 3, 4, 5, 6, 7, 8, 9, 6, 7, 4, 5, 1, 6, 3, 8, 4, 5, 8, 6, 9, 1, 10, 4, 7, 8, 3, 3, 6, 5, 8, 7, 9, 6, 4, 1, 5, 7, 8, 9, 6, 3, 4, 5, 7, 3, 9, 6, 3, 10, 8, 5, 6, 7, 10, 8, 5, 3, 7, 8, 9, 10, 8, 7, 1, 4, 5, 6, 10, 7, 9, 4, 3, 5, 6, 4, 10, 7, 8, 9, 5, 4, 6, 9, 8, 4, 5, 6, 7, 8, 9, 1, 4, 5, 8, 4, 9, 5, 3, 4, 5, 6, 7, 8, 1, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9],
//   [3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 1, 6, 7, 8, 9, 3, 4, 5, 6, 7, 4, 1, 8, 5, 10, 1, 3, 4, 9, 8, 3, 4, 5, 6, 7, 8, 1, 9, 3, 4, 5, 6, 7, 8, 9, 6, 1, 4, 5, 6, 7, 8, 4, 9, 5, 10, 6, 7, 3, 4, 9, 5, 10, 6, 4, 7, 8, 9, 10, 4, 5, 6, 7, 8, 9, 6, 10, 4, 5, 6, 4, 3, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9, 3, 4, 5, 6, 7, 8, 9]
// ],
// }

export const ReelSymbols = [
    null,
    {
        id: 1,
        name: 'WILD',
        texture: PIXI.Texture.from(Wild)
    },
    {
        id: 2,
        name: 'SCATTER',
        texture: PIXI.Texture.from(Scatter)
    },
    {
        id: 3,
        name: 'HIGH_ONE',
        texture: PIXI.Texture.from(High1)
    },
    {
        id: 4,
        name: 'HIGH_TWO',
        texture: PIXI.Texture.from(High2)
    },
    {
        id: 5,
        name: 'HIGH_THREE',
        texture: PIXI.Texture.from(High3)
    },
    {
        id: 6,
        name: 'HIGH_FOUR',
        texture: PIXI.Texture.from(High4)
    },
    {
        id: 7,
        name: 'LOW_ONE',
        texture: PIXI.Texture.from(Low1)
    },
    {
        id: 8,
        name: 'LOW_TWO',
        texture: PIXI.Texture.from(Low2)
    },
    {
        id: 9,
        name: 'LOW_THREE',
        texture: PIXI.Texture.from(Low3)
    },
    {
        id: 10,
        name: 'LOW_FOUR',
        texture: PIXI.Texture.from(Low4)
    }
];

export const secondaryReelSymbols = [
    {
        id: 11,
        name: 'WILD',
        texture: PIXI.Texture.from(Wild)
    },
    {
        id: 12,
        name: 'EMPTY',
        texture: PIXI.Texture.from(Empty)
    }
];


export const SYMBOLS_CONFIG = [
    {
        id: 1,
        name: 'WILD',
        assetName: 'wild'
    },
    {
        id: 2,
        name: 'SCATTER',
        assetName: 'scatter'
    },
    {
        id: 3,
        name: 'HIGH_ONE',
        assetName: 'h1'
    },
    {
        id: 4,
        name: 'HIGH_TWO',
        assetName: 'h2'
    },
    {
        id: 5,
        name: 'HIGH_THREE',
        assetName: 'h3'
    },
    {
        id: 6,
        name: 'HIGH_FOUR',
        assetName: 'h4'
    },
    {
        id: 7,
        name: 'LOW_ONE',
        assetName: 'l1'
    },
    {
        id: 8,
        name: 'LOW_TWO',
        assetName: 'l2'
    },
    {
        id: 9,
        name: 'LOW_THREE',
        assetName: 'l3'
    },
    {
        id: 10,
        name: 'LOW_FOUR',
        assetName: 'l4'
    }
];
