import { NewGame } from '../../../api/types';
import { UI } from '../../../ui';

export default function featureConfig(gameInfo: NewGame) {
	const config = gameInfo.gameSettings;
	const features = {
		BUY_BONUS: "true", //getValueByName('BUY_BONUS_FEATURE_ENABLED'), // TODO: fix buy bonus
		ANTE_BET: "true", //getValueByName('ANTE_BET_FEATURE_ENABLED'), // TODO: fix ante
		SPIN_COOL_DOWN: '0',
		ELAPSED_PROGRESS: 'true',
		AUTO_PLAY: 'true', //getValueByName('AUTO_PLAY_FEATURE_ENABLED'), // TODO: fix auto play
		FORCE_STOP_SPIN: 'true',
		CURRENCY_CODE: gameInfo.response.account.precision ? gameInfo.response.account.precision : 'USD',
		TURBO_SPIN: 'true',
		REALITY_CHECK: 'true',
		JURISDICTION_CODE: 'CU',
		RTP: getValueByName('RTP'),
	};

	function getValueByName(name: string) {
		for (const key in config) {
			if (typeof config[key] === 'object' && config[key].name === name) {
				return config[key].value.toString();
			}
		}
		return undefined;
	}

	UI.config.setFeatures(features);
}
