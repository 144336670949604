import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { autoPlayItemFooterTitle } from '../../../../styles/pixiStyles';
import { PixiGame } from '../../index';
import CommonButton from './CommonButton';

class NumberOfSpins {
	app: Application;
	game: PixiGame;
	container: PIXI.Container;
	background: PIXI.Sprite;
	title: PIXI.Text;
	buttons?: any[];
	numberOfSpinsOffBtn?: CommonButton;
	numberOfSpinsInfinityBtn?: CommonButton;
	numberOfSpins20Btn?: CommonButton;
	numberOfSpins50Btn?: CommonButton;
	numberOfSpins75Btn?: CommonButton;
	numberOfSpins100Btn?: CommonButton;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.buttons = [];
		this.container = new PIXI.Container();
		this.background = new PIXI.Sprite(
			this.app.loader.resources['autoPlayModalSecondaryBackground'].texture!
		);
		this.title = new PIXI.Text('NUMBER OF SPINS', autoPlayItemFooterTitle);
	}

	initialize = async () => {
		await this.setupButtons();
		this.container.width = 288;
		this.container.height = 207;

		this.title.x = 288 / 2 - this.title.width / 2;
		this.title.y = 9.5;

		this.background.width = 288;
		this.background.height = 30;

		this.container.addChild(this.background!);
		this.background.addChild(this.title!);
	};

	setupButtons = async () => {
		this.numberOfSpinsOffBtn = new CommonButton(this.game, this.app, 'OFF');
		this.numberOfSpinsInfinityBtn = new CommonButton(this.game, this.app, 'ထ');
		this.numberOfSpins20Btn = new CommonButton(this.game, this.app, '20');
		this.numberOfSpins50Btn = new CommonButton(this.game, this.app, '50');
		this.numberOfSpins75Btn = new CommonButton(this.game, this.app, '75');
		this.numberOfSpins100Btn = new CommonButton(this.game, this.app, '100');

		this.numberOfSpinsOffBtn.button.on('touchstart', () => this.game.assetsController?.autoPlayModal?.setSpinCounter('OFF'));
		this.numberOfSpinsInfinityBtn.button.on('touchstart', () => this.game.assetsController?.autoPlayModal?.setSpinCounter('ထ'));
		this.numberOfSpins20Btn.button.on('touchstart', () => this.game.assetsController?.autoPlayModal?.setSpinCounter(20));
		this.numberOfSpins50Btn.button.on('touchstart', () => this.game.assetsController?.autoPlayModal?.setSpinCounter(50));
		this.numberOfSpins75Btn.button.on('touchstart', () => this.game.assetsController?.autoPlayModal?.setSpinCounter(75));
		this.numberOfSpins100Btn.button.on('touchstart', () => this.game.assetsController?.autoPlayModal?.setSpinCounter(100));

		this.numberOfSpinsOffBtn.button.on('click', (e) => this.leftClick(e, 'OFF'));
		this.numberOfSpinsInfinityBtn.button.on('click', (e) => this.leftClick(e, 'ထ'));
		this.numberOfSpins20Btn.button.on('click', (e) => this.leftClick(e, 20));
		this.numberOfSpins50Btn.button.on('click', (e) => this.leftClick(e, 50));
		this.numberOfSpins75Btn.button.on('click', (e) => this.leftClick(e, 75));
		this.numberOfSpins100Btn.button.on('click', (e) => this.leftClick(e, 100));

		await this.numberOfSpinsOffBtn.initialize();
		await this.numberOfSpinsInfinityBtn.initialize();
		await this.numberOfSpins20Btn.initialize();
		await this.numberOfSpins50Btn.initialize();
		await this.numberOfSpins75Btn.initialize();
		await this.numberOfSpins100Btn.initialize();

		this.numberOfSpinsOffBtn.button.x = 0;
		this.numberOfSpinsOffBtn.button.y = 38;
		this.numberOfSpinsInfinityBtn.button.x = 49;
		this.numberOfSpinsInfinityBtn.button.y = 38;
		this.numberOfSpins20Btn.button.x = 98;
		this.numberOfSpins20Btn.button.y = 38;
		this.numberOfSpins50Btn.button.x = 147;
		this.numberOfSpins50Btn.button.y = 38;
		this.numberOfSpins75Btn.button.x = 196;
		this.numberOfSpins75Btn.button.y = 38;
		this.numberOfSpins100Btn.button.x = 245;
		this.numberOfSpins100Btn.button.y = 38;

		this.background.addChild(this.numberOfSpinsOffBtn.button);
		this.background.addChild(this.numberOfSpinsInfinityBtn.button);
		this.background.addChild(this.numberOfSpins20Btn.button);
		this.background.addChild(this.numberOfSpins50Btn.button);
		this.background.addChild(this.numberOfSpins75Btn.button);
		this.background.addChild(this.numberOfSpins100Btn.button);
	};

	leftClick = (e:any, val:any) => {
		if(e.data.button === 0){
			this.game.assetsController?.autoPlayModal?.setSpinCounter(val)
		};
	}

}

export default NumberOfSpins;
