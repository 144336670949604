import { useEffect, useState, FC } from 'react';
import '@pixi-spine/loader-3.8';
import styled from 'styled-components';
import DebugPlayModal from '../../../../components/Modals/DebugPlayModal/DebugPlayModal';
import { PixiGame } from '../..';

interface IProps {
	game: PixiGame;
}

const DebulReels: FC<IProps> = ({ game }) => {
	const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);

	useEffect(() => {
		const callback = (e: KeyboardEvent) => {
			if (e.code === 'KeyD' && game.type === 'debug') {
				game.type = 'default';
				console.log('Default mode');
			} else if (e.code === 'KeyD' && game.type === 'default') {
				game.type = 'debug';
				console.log('Debug mode');
			}

			if (e.code === 'KeyE') {
				setIsDebugModalOpen(!isDebugModalOpen);
			}
		};

		document.addEventListener('keydown', callback);
		return () => document.removeEventListener('keydown', callback);
	}, [game, isDebugModalOpen, setIsDebugModalOpen]);

	return (
		<Wrapper
			style={isDebugModalOpen ? { display: 'block' } : { display: 'none' }}
		>
			<DebugPlayModal closeModal={() => setIsDebugModalOpen(false)} />
		</Wrapper>
	);
};

export default DebulReels;

const Wrapper = styled.div`
	position: absolute;
	background: #151515;
	border-radius: 15px;
	left: calc(50% - 300px);
	top: calc(50% - 125px);
`;
