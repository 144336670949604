import { Spine } from 'pixi-spine';
import { Application } from 'pixi.js';

export class MultiplierItemMobile {
	id: number;
	name: number;
	app: Application;
	multiplier: Spine;

	constructor(name: number, app: Application) {
		this.id = name;
		this.name = name;
		this.app = app;
		const assetData =
			this.app.loader.resources['multiplierAnimatedMobile'].spineData!;
		this.multiplier = new Spine(assetData);
		this.multiplier.state.setAnimation(0, `x${this.name}`, false);
		this.multiplier.state.timeScale = 0;
	}
	animateItem = () => {
		this.multiplier.state.timeScale = 1;
	};
	resetItem = () => {
		this.multiplier.state.setAnimation(0, `x${this.name}`, false);
		this.multiplier.state.timeScale = 0;
	};

	disableInactive = (minMul: number) => {
		if (this.name < minMul || this.name === 1) {
			this.animateItem();
		} else {
			this.resetItem();
		}
	};
}
