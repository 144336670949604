import { SoundController } from '../classes/SoundController';
type CallbackParameters = {
	sound: boolean;
	music: boolean;
};

export default function soundControl(
	params: CallbackParameters,
	soundController: SoundController
) {
	const { sound, music } = params;

	if (sound) {
		soundController.spinSound?.mute(false);
    	soundController.reelFallSound?.mute(false);
    	soundController.reelStopSound?.mute(false);
    	soundController.btn_ui?.mute(false);
    	soundController.popup_fs_start?.mute(false);
    	soundController.popup_fs_out?.mute(false);
    	soundController.scatterLand1?.mute(false);
    	soundController.scatterLand2?.mute(false);
    	soundController.scatterLand3?.mute(false);
    	soundController.scatterLand4?.mute(false);
    	soundController.scatterLand5?.mute(false);
    	soundController.scatterWin?.mute(false);
    	soundController.win_counter_end?.mute(false);
    	soundController.wildLand?.mute(false);
    	soundController.winSound?.mute(false);
    	soundController.sfx_hw_start?.mute(false);
    	soundController.win_counter?.mute(false);
    	soundController.sfx_hw_end?.mute(false);
    	soundController.bonus_hover?.mute(false);
    	soundController.btn_hover_on?.mute(false);
    	soundController.btn_hover_off?.mute(false);
    	soundController.bg_bigWin?.mute(false);
	} else {
		soundController.spinSound?.mute(true);
    	soundController.reelFallSound?.mute(true);
    	soundController.reelStopSound?.mute(true);
    	soundController.btn_ui?.mute(true);
    	soundController.popup_fs_start?.mute(true);
    	soundController.popup_fs_out?.mute(true);
    	soundController.scatterLand1?.mute(true);
    	soundController.scatterLand2?.mute(true);
    	soundController.scatterLand3?.mute(true);
    	soundController.scatterLand4?.mute(true);
    	soundController.scatterLand5?.mute(true);
    	soundController.scatterWin?.mute(true);
    	soundController.win_counter_end?.mute(true);
    	soundController.wildLand?.mute(true);
    	soundController.winSound?.mute(true);
    	soundController.sfx_hw_start?.mute(true);
    	soundController.win_counter?.mute(true);
    	soundController.sfx_hw_end?.mute(true);
    	soundController.bonus_hover?.mute(true);
    	soundController.btn_hover_on?.mute(true);
    	soundController.btn_hover_off?.mute(true);
    	soundController.bg_bigWin?.mute(true);
	}

	if (music) {
		soundController.backgroundSound?.mute(false);
		soundController.backgroundFSSound?.mute(false);
	} else {
		soundController.backgroundSound?.mute(true);
		soundController.backgroundFSSound?.mute(true);
	}
}
