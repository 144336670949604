import * as PIXI from 'pixi.js';
// import './index.css'

let infoLabelFontSize;
if (window.innerWidth >= 1440) {
	infoLabelFontSize = 14;
} else if (window.innerWidth >= 1200) {
	infoLabelFontSize = 12;
} else {
	infoLabelFontSize = 8;
}

export const infoLabelStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 14,
	lineHeight: 21,
	fontWeight: '400',
	fill: 0xe2f4f2,
	align: 'center',
});

export const infoValueStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 24,
	lineHeight: 26.4,
	fontWeight: '600',
	fill: 0xebb035,
	align: 'center',
});

export const infoLabelStylesLandscape = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 28,
	// lineHeight: 17,
	fontWeight: '400',
	fill: 0x7C8584,
	align: 'center',
	strokeThickness: 2,
});

export const infoValueStylesLandscape = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 28,
	// lineHeight: 26.4,
	fontWeight: '600',
	fill: 0xebb035,
	align: 'center',
});

export const infoLabelStylesPortret = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 66,
	// lineHeight: 12,
	fontWeight: '400',
	fill: 0x7C8584,
	align: 'center',
});

export const infoValueStylesPortret = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 36,
	// lineHeight: 18,
	fontWeight: '500',
	fill: 0xebb035,
	align: 'center',
});

export const bonusLabelStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 44,
	lineHeight: 53,
	fontWeight: '700',
	fill: 0xe2f4f2,
	align: 'center',
});

export const bonusLabelHoverStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 44,
	lineHeight: 53,
	fontWeight: '700',
	fill: 0x151515,
	align: 'center',
});

export const bonusLabelDisabledStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 44,
	lineHeight: 53,
	fontWeight: '700',
	fill: '#2A2C2B',
	align: 'center',
});

export const doubleBonusLabelStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 36,
	lineHeight: 43,
	fontWeight: '700',
	fill: 0xe2f4f2,
	align: 'center',
});

export const doubleBonusLabelHoverStyles = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 36,
	lineHeight: 43,
	fontWeight: '700',
	fill: 0x151515,
	align: 'center',
});

export const settingsModalTitle = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 16,
	fontWeight: '700',
	lineHeight: 24,
	fill: '#E2F4F2',
});

export const settingsItemTitle = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 10,
	fontWeight: '700',
	lineHeight: 12,
	fill: '#fff',
});

export const autoPlayModalTitle = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 16,
	fontWeight: '700',
	lineHeight: 24,
	fill: '#fff',
});

export const playButtonLabel = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 10,
	fontWeight: '700',
	lineHeight: 12,
	fill: '#151515',
});

export const autoPlayItemFooterTitle = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 10,
	fontWeight: '700',
	lineHeight: 12,
	fill: '#7C8584',
});

export const switchButtonLabel = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 10,
	fontWeight: '400',
	lineHeight: 15,
	fill: '#7C8584',
});

export const commonButtonLabel = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 10,
	fontWeight: '700',
	lineHeight: 12,
	fill: '#535858',
	align: 'center',
});

export const commonButtonLabelHover = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 10,
	fontWeight: '700',
	lineHeight: 12,
	fill: '#E2F4F2',
	align: 'center',
});

export const totalBetValueLabel = new PIXI.TextStyle({
	fontFamily: 'Rubik',
	fontSize: 10,
	fontWeight: '700',
	lineHeight: 12,
	fill: '#151515',
	align: 'center',
});
