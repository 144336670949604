import {PixiGame} from "../index";
import {Howl, Howler} from 'howler'
import {EventType, getEvent} from "../../../GameEventBus";
import {CDN_BASE_URL} from "../Game";

export class SoundController {
    backgroundSound?: Howl;
    spinSound?: Howl
    controller: HowlerGlobal
    reelFallSound?: Howl
    reelStopSound?: Howl
    btn_ui?: Howl
    popup_fs_start?: Howl
    popup_fs_out?: Howl

    scatterLand1?: Howl
    scatterLand2?: Howl
    scatterLand3?: Howl
    scatterLand4?: Howl
    scatterLand5?: Howl
    scatterWin?: Howl
    win_counter_end?: Howl
    wildLand?: Howl
    winSound?: Howl
    game: PixiGame
    backgroundFSSound?: Howl
    sfx_hw_start?: Howl
    win_counter?: Howl
    sfx_hw_end?: Howl
    bonus_hover?: Howl
    btn_hover_on?: Howl
    btn_hover_off?: Howl
    bg_bigWin?: Howl

    constructor(game: PixiGame) {
        this.game = game
        this.controller = Howler
    }

    initialize = () => {
        this.backgroundSound = new Howl({
            src: [`${CDN_BASE_URL}/mp3/bg_main.mp3`],
            volume: 0.5,
            loop: true,
        })

        this.backgroundFSSound = new Howl({
            src: [`${CDN_BASE_URL}/mp3/bg_freespin.mp3`],
            volume: 0.5,
            loop: true,
        })

        this.spinSound = new Howl({
            src: [`${CDN_BASE_URL}/mp3/btn_spin.mp3`],
            loop: false,
        })

        this.reelFallSound = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_reel_fall.mp3`],
            loop: false
        })

        this.reelStopSound = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_reel_stop.mp3`],
            loop: false
        })

        this.btn_ui = new Howl({
            src: [`${CDN_BASE_URL}/mp3/btn_ui.mp3`],
            loop: false
        })

        this.popup_fs_start = new Howl({
            src: [`${CDN_BASE_URL}/mp3/popup_fs_start.mp3`],
            loop: false
        })

        this.popup_fs_out = new Howl({
            src: [`${CDN_BASE_URL}/mp3/popup_fs_out.mp3`],
            loop: false
        })

        this.scatterLand1 = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_scatter_land_0.mp3`],
            loop: false
        })
        this.scatterLand2 = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_scatter_land_1.mp3`],
            loop: false
        })
        this.scatterLand3 = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_scatter_land_2.mp3`],
            loop: false
        })
        this.scatterLand4 = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_scatter_land_3.mp3`],
            loop: false
        })
        this.scatterLand5 = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_scatter_land_4.mp3`],
            loop: false
        })

        this.scatterWin = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_scatter_win.mp3`],
            loop: false
        })

        this.winSound = new Howl({
            src: [`${CDN_BASE_URL}/mp3/win.mp3`],
            loop: false
        })

        this.win_counter_end = new Howl({
            src: [`${CDN_BASE_URL}/mp3/win_counter_end.mp3`],
            loop: false
        })

        this.wildLand = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_wild_land.mp3`],
            loop: false
        })

        this.sfx_hw_start = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_hw_start.mp3`],
            loop: false
        })

        this.bg_bigWin = new Howl({
            src: [`${CDN_BASE_URL}/mp3/bg_bigWin.mp3`],
            loop: false
        })

        this.win_counter = new Howl({
            src: [`${CDN_BASE_URL}/mp3/win_counter.mp3`],
            loop: false
        })


        this.sfx_hw_end = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_hw_end.mp3`],
            loop: false
        })

        this.btn_hover_on = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_hover_on.mp3`],
            loop: false
        })

        this.btn_hover_off = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_hover_off.mp3`],
            loop: false
        })

        this.bonus_hover = new Howl({
            src: [`${CDN_BASE_URL}/mp3/sfx_hover.mp3`],
            loop: false
        })

        getEvent(EventType.GAME_BTN_UI_SOUND).send(this.btn_ui)
        getEvent(EventType.BG_SOUND).send(this.backgroundSound)


    }
}
