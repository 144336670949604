import LogoVertical from "../assets/logo_vertical_anim.png";
import LogoHorisontal from "../assets/logo_horisontal_anim.png";
import blank from '../assets/game-field/blank.png'
import {Application} from "pixi.js";

import '@pixi-spine/loader-3.8';
import {CDN_BASE_URL} from "../Game"; // Side effect install the loader
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports
export * from '@pixi-spine/runtime-3.8';
export * from '@pixi-spine/base';


export const spineLoaders = (app: Application) => {
    app.loader.add('LogoVertical', LogoVertical)
        .add('LogoHorisontal', LogoHorisontal)
        .add('backgroundAnimated', `${CDN_BASE_URL}/spine/source_Main_BG/Main_BG_anim.json`)
        .add('backgroundAnimatedFS', `${CDN_BASE_URL}/spine/source_FS_BG/FS_BG_anim.json`)
        .add('multiplierAnimated', `${CDN_BASE_URL}/spine/desktop_Multiplier_anim.json`)
        .add('multiplierAnimatedMobile', `${CDN_BASE_URL}/spine/Multiplier_portrait_anim.json`)
        .add('h1', `${CDN_BASE_URL}/spine/source_H1/H1_233x233.json`)
        .add('h2', `${CDN_BASE_URL}/spine/source_H2/H2_233x233.json`)
        .add('h3', `${CDN_BASE_URL}/spine/source_H3/H3_233x233.json`)
        .add('h4', `${CDN_BASE_URL}/spine/source_H4/H4_233x233.json`)
        .add('l1', `${CDN_BASE_URL}/spine/source_L1/L1_233x233.json`)
        .add('l2', `${CDN_BASE_URL}/spine/source_L2/L2_233x233.json`)
        .add('l3', `${CDN_BASE_URL}/spine/source_L3/L3_233x233.json`)
        .add('l4', `${CDN_BASE_URL}/spine/source_L4/L4_233x233.json`)
        .add('scatter', `${CDN_BASE_URL}/spine/skatter_win/scatter_300x233(170323).json`)
        .add('wild', `${CDN_BASE_URL}/spine/wild/gummy_bear_265x265.json`)
        .add('bigWin', `${CDN_BASE_URL}/spine/big_win/big_win_desktop.json`)
        .add('megaWin', `${CDN_BASE_URL}/spine/mega_win/mega_win_desktop.json`)
        .add('superWin', `${CDN_BASE_URL}/spine/super_win/super_win_desktop.json`)
        .add('bigWinCoins', `${CDN_BASE_URL}/spine/big_win_coins/coin_for_wins.json`)
        .add('superWinCoins', `${CDN_BASE_URL}/spine/super_win_coins/coin_for_wins.json`)
        .add('megaWinCoins', `${CDN_BASE_URL}/spine/mega_win_coins/coin_for_wins.json`)
        .add('antisipator', `${CDN_BASE_URL}/spine/antisipator.json`)
        .add('win_lines', `${CDN_BASE_URL}/spine/win_lines/light1.json`)
        .add('blank', blank)
}
