import { FC } from 'react'
import Game from '../Game/Game'

const HomePage: FC = () => {
  return (
    <Game/>
  )
}

export default HomePage;
