import {FC} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {privateRoutes, RouteNames} from '../../router';


const AppRouter: FC = () => {

    return (
        <Routes>
            {privateRoutes.map(route => (
                <Route path={route.path} element={<route.element/>} key={route.path}/>
            ))}
            <Route
                path="*"
                element={<Navigate to={RouteNames.HOME_PAGE} replace/>}
            />
        </Routes>
    )
};

export default AppRouter;
