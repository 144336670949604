import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { autoPlayItemFooterTitle } from '../../../../styles/pixiStyles';
import { PixiGame } from '../../index';
import SwitchButton from './SwitchButton';

class StopAutoPlay {
	app: Application;
	game: PixiGame;
	container: PIXI.Container;
	background: PIXI.Container;
	title: PIXI.Text;
	anyWin?: SwitchButton;
	freeSpinWin?: SwitchButton;
	buttons?: any[];

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.container = new PIXI.Container();
		this.buttons = [];
		this.background = new PIXI.Sprite(
			this.app.loader.resources['autoPlayModalSecondaryBackground'].texture!
		);
		this.title = new PIXI.Text('STOP AUTOPLAY', autoPlayItemFooterTitle);
	}

	initialize = async () => {
		await this.setupButtons();
		this.container.width = 288;
		this.container.height = 207;

		this.title.x = 288 / 2 - this.title.width / 2;
		this.title.y = 9.5;

		this.background.width = 288;
		this.background.height = 30;

		this.container.addChild(this.background!);
		this.background.addChild(this.title!);
	};

	setupButtons = async () => {
		this.anyWin = new SwitchButton(this.game, this.app, 'On any win');
		this.freeSpinWin = new SwitchButton(
			this.game,
			this.app,
			'If free spins are won'
		);

		await this.anyWin.initialize();
		await this.freeSpinWin.initialize();

		this.buttons?.push(this.freeSpinWin.container);
		this.buttons?.push(this.anyWin.container);
		this.anyWin.container.x = 0;
		this.anyWin.container.y = 38;
		this.freeSpinWin.container.x = 0;
		this.freeSpinWin.container.y = 75;

		this.buttons?.forEach(btn => {
			this.background.addChild(btn);
		});
	};
}

export default StopAutoPlay;
